<template>
    <span v-if="profile.role != 'User'">
        <div>Correction :</div>
        <v-radio-group row v-model="question.correctAnswer" class="pt-0 mt-2">
            <v-radio label="Vrai" :value="true"></v-radio>
            <v-radio label="Faux" :value="false"></v-radio>
        </v-radio-group>
    </span>

    <span v-else>
        <h4 v-text="question.text" class="mb-3"></h4>
        <v-radio-group row v-model="question.response" class="pt-0 mt-2" style="margin-bottom:-15px">
            <v-radio label="Vrai" :value="true" @click="$emit('edited')"></v-radio>
            <v-radio label="Faux" :value="false" @click="$emit('edited')"></v-radio>
        </v-radio-group>
    </span>
</template>

<script>
export default {
    props: ["profile", "question"],
    data: () => ({
        response: {
            valide: false,
            errorMessage: ''
        }
    }),
    methods: {
        testIsValid(){
            // titre
            if(!this.question.text || this.question.text.trim() == ''){
                this.response.errorMessage = 'Veuillez renseigner un titre à toutes les questions.'
                return this.response
            }
            // correction
            if(this.question.correctAnswer != true && this.question.correctAnswer != false){
                this.response.errorMessage = 'Veuillez renseigner une correction à toutes les questions.'
                return this.response
            }
            // validation
            this.response.valide = true
            return this.response
        }
    }
}
</script>