<template>
  <div class="explain" v-if="adminCheckResponses && formResponse && formResponse.responses">
    <v-tooltip left>
      <template #activator="tooltip">
        <v-btn icon outlined color="warning" v-on="{ ...tooltip.on }" @click="menuIsOpen = true">
          <v-icon>mdi-book-check-outline</v-icon>
        </v-btn>
      </template>
      <span>Ajouter une explication/correction personnalisée</span>
    </v-tooltip>
    <v-alert border="right" colored-border type="warning" elevation="2" class="alert" icon="mdi-book-check-outline" v-if="menuIsOpen">
      <div class="d-flex align-center justify-space-between">
        <v-textarea rows="1" auto-grow hide-details class="pt-0 ma-1" :placeholder="slide.explain ? slide.explain : 'Optionnelle'" v-model="formResponse.responses[slideIndex].explain" @blur="save" />
        <v-btn icon color="primary" @click="menuIsOpen = false">
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </div>
    </v-alert>
  </div>



  <div class="explain" v-else-if="(!preview || slide.explain) && (!profile || profile.role !== 'User' || showCorrection)">
    <v-tooltip left>
      <template #activator="tooltip">
        <v-btn icon outlined color="warning" v-on="{ ...tooltip.on }" @click="menuIsOpen = true">
          <v-icon>mdi-book-check-outline</v-icon>
        </v-btn>
      </template>
      <span v-if="!preview">Expliquer la correction ?</span>
      <span v-else>Explication</span>
    </v-tooltip>
    <v-alert border="right" colored-border type="warning" elevation="2" class="alert" icon="mdi-book-check-outline" v-if="menuIsOpen">
      <div class="d-flex align-center justify-space-between">
        <v-textarea v-if="!preview" rows="1" auto-grow hide-details class="pt-0 ma-1" placeholder="Optionnelle" v-model="slide.explain" />
        <pre v-else class="preview">{{ formResponse && formResponse.responses[slideIndex] && formResponse.responses[slideIndex].explain ? formResponse.responses[slideIndex].explain : slide.explain }}</pre>
        <v-btn icon color="primary" @click="menuIsOpen = false">
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </div>
    </v-alert>
  </div>
</template>

<script>
export default {
  props: ['slide', 'preview', 'showCorrection', 'profile', 'adminCheckResponses', 'formResponse', 'slideIndex'],
  data() {
    return {
      menuIsOpen: false
    }
  },
  created() {
    if (this.showCorrection && !this.adminCheckResponses) {
      this.menuIsOpen = true
    } else if (this.adminCheckResponses && this.formResponse && this.formResponse.responses[this.slideIndex] && this.formResponse.responses[this.slideIndex].explain) {
      this.menuIsOpen = true
    }
  },
  watch: {
    showCorrection() { this.menuIsOpen = true }
  },
  methods: {
    save() {
      this.menuIsOpen = false
      this.formResponse.save()
    }
  }
}
</script>

<style scoped lang="scss">
.preview {
  padding: 8px;
}
.explain {
  margin: 8px;
}
.alert {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--v-lightBg-base);
  width: 100%;
  border-radius: 0;
  pre {
    font-family: inherit;
  }
}
</style>