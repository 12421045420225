const getVarList = (goodResponse) => {
  let tmp_array = []
  for (const array of goodResponse.trim().split('[')) {
    tmp_array = tmp_array.concat(array.split(']'))
  }
  return tmp_array.filter((string, index) => index % 2 === 1)
}

const getCalculation = (calculation, varList, slideQuestions, slideUserResponse, withFormResponse) => {
  while (calculation.includes(']  ')) {
    calculation = calculation.replaceAll(']  ', '] ')
  }
  while (calculation.includes('  [')) {
    calculation = calculation.replaceAll('  [', ' [')
  }
  if (calculation.includes('][')) calculation = calculation.replaceAll('][', ']*[')
  if (calculation.includes('] [')) calculation = calculation.replaceAll('] [', ']*[')

  if (withFormResponse) {
    for (const variable of varList) {
      const questionIndex = slideQuestions.findIndex(x=>x.var===variable)

      let cleanResponse = ''
      if (slideUserResponse[questionIndex]) {
        const tmp_response = slideUserResponse[questionIndex]
        cleanResponse = parseFloat(tmp_response.replace(/(?!^-)[^0-9.,]+/g,'').replace(',', '.'))
      }

      calculation = calculation.replaceAll(`[${variable}]`, cleanResponse)
    }
  } else {
    for (const variable of varList) {
      calculation = calculation.replaceAll(`[${variable}]`, slideQuestions.find(x=>x.var===variable).goodResponse)
    }
  }

  if (calculation.includes('[')) calculation = this.getCalculation(calculation) // repete if dependent depend of dependent
  return calculation
}

export default (tmp_userResponse, goodResponse, slideQuestions, slideUserResponse) => {
  const varList = getVarList(goodResponse)
  const trueCorrection = eval(getCalculation(goodResponse.trim(), varList, slideQuestions))
  const relativeCorrection = eval(getCalculation(goodResponse.trim(), varList, slideQuestions, slideUserResponse, true))
  const userResponse = parseFloat(
    tmp_userResponse
      .replace(/(?!^-)[^0-9.,]+/g,'')
      .replace(',', '.')
  )

  if (parseFloat(userResponse) === relativeCorrection) {
    if (trueCorrection === relativeCorrection)
      return true // true
    else
      return true // relative true
  } else {
    if (parseFloat(userResponse) === trueCorrection)
      return true // cheat
    else
      return false // false
  }
}