export default (userResponse, goodResponse) => {
  const isCorrect = []

  for (const index in goodResponse) {
    if (goodResponse[index] === userResponse[index]) {
      isCorrect[index] = true
    } else {
      isCorrect[index] = false
    }
  }

  return isCorrect
}