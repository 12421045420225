<template>
    <div>
        <div v-if="$vuetify.breakpoint.smAndDown && compact" class="d-flex align-center" @click.prevent.stop="">
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                        <v-badge bordered overlap color="notif" v-if="notif" :content="notif === true ? 1 : notif">
                            <UserAvatar :profile="profile"></UserAvatar>
                        </v-badge>
                        <UserAvatar v-else :profile="profile"></UserAvatar>
                    </div>
                </template>
                <b v-text="profile.lastnamefirstname + ' ' + profile.firstname"></b>
                <div v-if="profile.domain" style="font-size: 0.85em">
                    <p class="mb-0 mt-1" v-text="profile.lastname.toLowerCase() + '.' + profile.firstname.toLowerCase() + '@' + profile.domain.toLowerCase()"></p>
                    <p class="mb-0" v-text="profile.description"></p>
                </div>
            </v-tooltip>
        </div>
        <div v-else class="d-flex align-center">
            <v-badge bordered overlap color="notif" v-if="notif" :content="notif === true ? 1 : notif">
                <UserAvatar :profile="profile"></UserAvatar>
            </v-badge>
            <UserAvatar v-else :profile="profile"></UserAvatar>
            <div class="ml-3">
                <b v-text="profile.lastname + ' ' + profile.firstname"></b>
                <div v-if="mailAndJob && profile.domain" style="font-size: 0.85em">
                    <p class="mb-0 mt-1" v-text="profile.lastname.toLowerCase() + '.' + profile.firstname.toLowerCase() + '@' + profile.domain.toLowerCase()"></p>
                    <p class="mb-0" v-text="profile.description"></p>
                </div>
                <p class="mb-0 mt-1" v-if="subject" v-text="(isResponse ? 'RE : ' : 'Sujet : ') + subject"></p>
            </div>
        </div>
    </div>
</template>

<script>
// components
import UserAvatar from "@/components/UserAvatar"

export default {
    props: ['profile', 'subject', 'mailAndJob', 'notif', 'isResponse', 'compact'],
    components: {
        UserAvatar
    }
}
</script>