<template>
  <div v-if="showCorrection">
    <div :class="correctionColor()">
      {{ checkIfEmpty() ? 'Non répondu.' : formResponse.responses[slideIndex][questionIndex] }}
      <p class="text-caption" v-if="profile && profile.role !== 'User'">Interprété comme "{{ interpretation }}"</p>
    </div>
    <div v-if="['false', 'relative', 'cheat'].includes(isCorrect)" class="mt-2">
      <div>
        Correction relative :
        <span class="info--text">
          {{ textCorrection[0] }}
        </span>
      </div>
      <div>
        Correction global :
        <span class="info--text">
          {{ textCorrection[1] }}
        </span>
      </div>
    </div>
  </div>

  <v-text-field placeholder="Réponse" hide-details class="ma-0 pa-0" v-else-if="profile && profile.role === 'User'" v-model="formResponse.responses[slideIndex][questionIndex]" :disabled="disabled" @keydown="formResponse.lastUpdateDate = new Date().getTime()" />
  <v-text-field placeholder="Réponse" hide-details class="ma-0 pa-0" v-else-if="preview" />
  <div @mouseover="addToRightClick" @mouseleave="delToRightClick" v-else>
    <p class="mb-0">Correction :</p>
    <v-text-field placeholder="Réponse" hide-details class="ma-0 pa-0 mb-2" v-model="question.goodResponse"
      @keyup="updateResult" />
    <b>{{ result }}</b>

    <v-dialog width="fit-content" v-model="varModal">
      <v-card width="fit-content">
        <v-card-title>Sélection d'une variable</v-card-title>
        <v-card-text class="d-flex flex-column gap-m">
          <v-btn elevation="0" v-for="(aQuestion, index) of slide.content.questions" :key="index" v-if="aQuestion.var && aQuestion.var !== question.var"
            @click="writeVar(aQuestion.var)">
            {{ aQuestion.var }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Correction from '@/assets/functions/corrections'

export default {
  props: ['slide', 'question', 'rightClickItems', 'preview', 'profile', 'formResponse', 'slideIndex', 'questionIndex', 'disabled', 'showCorrection'],
  data() {
    return {
      varModal: false,
      result: '= 0',
      varList: [],
      isCorrect: 'false', // 'true' 'false' 'relative' 'cheat',
      textCorrection: [],

      interpretation: null
    }
  },
  watch: {
    slide: {
      handler() {
        if (!this.profile || this.profile.role !== 'User') {
          this.updateResult()
        }
      },
      deep: true
    },
    showCorrection() {
      this.runCorrection()
    }
  },
  created() {
    if (!this.profile || this.profile.role !== 'User') {
      this.updateResult()
    }
  },
  mounted() {
    if (this.showCorrection) {
      this.runCorrection()
    }
  },
  methods: {
    addToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[10] = 'divider'
        this.rightClickItems[11] = {
          text: 'Utiliser une variable',
          method: () => {
            this.varModal = true
          }
        }
      }
    },
    delToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[10] = null
        this.rightClickItems[11] = null
      }
    },

    writeVar(variable) {
      this.question.goodResponse += `[${variable}]`
      this.updateResult()
      this.varModal = false
    },


    updateResult() {
      if (!this.question.goodResponse || this.question.goodResponse.trim() == '') {
        this.result = ''
      } else {
        this.result = this.getResult()
      }
    },
    getResult() {
      const varList = this.getVarList()

      let error
      for (const variable of varList) {
        if (!this.slide.content.questions.filter(x => x.var && x.var !== this.question.var).map(x => x.var).includes(variable)) {
          error = 'Une variable est incorrecte.'
        }
      }
      if (error) return error

      const calculation = this.getCalculation(this.question.goodResponse.trim(), varList)
      try {
        return '= ' + Math.round(eval(calculation) * 10000) / 10000
      } catch {
        return 'Calcul impossible.'
      }
    },
    getVarList() {
      // list of variable name
      let tmp_array = []
      for (const array of this.question.goodResponse.trim().split('[')) {
        tmp_array = tmp_array.concat(array.split(']'))
      }
      return tmp_array.filter((string, index) => index % 2 === 1)
    },
    getCalculation(calculation, varList, withFormResponse) {
      while (calculation.includes(']  ')) {
        calculation = calculation.replaceAll(']  ', '] ')
      }
      while (calculation.includes('  [')) {
        calculation = calculation.replaceAll('  [', ' [')
      }
      if (calculation.includes('][')) calculation = calculation.replaceAll('][', ']*[')
      if (calculation.includes('] [')) calculation = calculation.replaceAll('] [', ']*[')

      if (withFormResponse) {
        for (const variable of varList) {
          const questionIndex = this.slide.content.questions.findIndex(x=>x.var===variable)

          let cleanPreviousResponse = ''
          if (this.formResponse.responses[this.slideIndex][questionIndex]) {
            const tmp_response = this.formResponse.responses[this.slideIndex][questionIndex]
            cleanPreviousResponse = tmp_response.replace(/(?!^-)[^0-9.,]+/g,'').replace(',', '.')
          }

          calculation = calculation.replaceAll(`[${variable}]`, cleanPreviousResponse)
        }
      } else {
        for (const variable of varList) {
          calculation = calculation.replaceAll(`[${variable}]`, this.slide.content.questions.find(x=>x.var===variable).goodResponse)
        }
      }

      if (calculation.includes('[')) calculation = this.getCalculation(calculation)
      return calculation
    },

    
    runCorrection() {
      const varList = this.getVarList()
      const trueCorrection = eval(this.getCalculation(this.question.goodResponse.trim(), varList))
      const relativeCorrection = eval(this.getCalculation(this.question.goodResponse.trim(), varList, true))
      let userResponse = this.formResponse.responses[this.slideIndex][this.questionIndex]
      
      if (userResponse) {
        userResponse = parseFloat(userResponse.replace(/(?!^-)[^0-9.,]+/g,'').replace(',', '.'))
        if (isNaN(userResponse)) {
          this.interpretation = 'Non répondu'
        } else {
          this.interpretation = userResponse
        }
      } else {
        this.interpretation = 'Non répondu'
      }

      if (userResponse === relativeCorrection) {
        // true
        if (trueCorrection === relativeCorrection)
          this.isCorrect = 'true'
        // relative
        else
          this.isCorrect = 'relative'
      } else {
        // cheat
        if (userResponse === trueCorrection)
          this.isCorrect = 'cheat'
        // false
        else
          this.isCorrect = 'false'
      }

      this.textCorrection = [relativeCorrection, trueCorrection]
    },
    correctionColor() {
      if (this.isCorrect === 'true') return 'success--text'
      else if (this.isCorrect === 'false') return 'error--text'
      else {
        if (this.profile && this.profile.role === 'User') {
          return 'success--text'
        } else {
          return 'warning--text'
        }
      }
    },
    checkIfEmpty() {
      return [null, undefined].includes(this.formResponse.responses[this.slideIndex][this.questionIndex]) || this.formResponse.responses[this.slideIndex][this.questionIndex].trim() === ''
    }
  }
}
</script>