<template>
  <div class="tips" v-if="!preview || slide.tips">
    <v-tooltip left>
      <template #activator="tooltip">
        <v-btn icon color="info" outlined v-on="{ ...tooltip.on }" @click="menuIsOpen = true">
          <v-icon style="transform: scale(1.2)">mdi-information-variant</v-icon>
        </v-btn>
      </template>
      <span v-if="!preview">Donner un indice ou une information en plus ?</span>
      <span v-else>indice/explication</span>
    </v-tooltip>
    <v-alert border="right" colored-border type="info" elevation="2" class="alert" icon="mdi-information-variant" v-if="menuIsOpen">
      <div class="d-flex align-center justify-space-between">
        <pre v-if="preview" class="preview">{{ slide.tips }}</pre>
        <v-textarea v-else rows="1" auto-grow hide-details class="pt-0 ma-1" placeholder="Optionnelle" v-model="slide.tips" />
        <v-btn icon color="primary" @click="menuIsOpen = false">
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </div>
    </v-alert>
  </div>
</template>

<script>
export default {
  props: ['slide', 'preview'],
  data() {
    return {
      menuIsOpen: false
    }
  }
}
</script>

<style scoped lang="scss">
.preview {
  padding: 8px;
}
.tips {
  margin: 8px;
}
.alert {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--v-lightBg-base);
  width: 100%;
  pre {
    font-family: inherit;
  }
}
</style>