<template>
	<span>
        <v-expansion-panels class="mt-3">
            <v-expansion-panel v-for="mail in mails.all" :key="mail.id" v-if="(new Array('Corrector', 'Animator', 'Admin', 'SuperAdmin').includes(profile.role) && mail.type == 'mail') || (profile.role == 'User' && new Date(mail.date) < new Date())">
                <v-expansion-panel-header class="px-0 py-2" @click="deleteNotif(mail.id)">
                    <span class="d-flex flex-column my-2">
                        <div class="d-flex align-center">
                            <!-- left -->
                            <UserCard :profile="mail.sender" :subject="mail.subject" :notif="checkNotif(mail.id)"></UserCard>
                            <!-- mail icons -->
                            <div class="ml-3">
                                <v-tooltip bottom v-if="mail.urgent">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="warning" v-on="on" v-bind="attrs">mdi-exclamation-thick</v-icon>
                                    </template>
                                    <span>Mail urgent</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-on="on" v-bind="attrs" v-if="mail.receiver.uid">mdi-account-outline</v-icon>
                                        <v-icon v-on="on" v-bind="attrs" v-else-if="mail.receiver.members">mdi-account-group-outline</v-icon>
                                    </template>
                                    <span v-if="mail.receiver.uid" v-text="`Mail envoyé à une personne ${profile.role != 'User' ? '(' + mail.receiver.lastname + ' ' + mail.receiver.firstname + ')' : ''}`"></span>
                                    <span v-else-if="mail.receiver.members" v-text="`Mail envoyé à un groupe (${mail.receiver.name})`"></span>
                                </v-tooltip>
                            </div>
                            
                            <!-- right -->
                            <v-spacer></v-spacer>
                            <div class="text-right d-flex flex-column justify-content-between">
                                <!-- date + hours -->
                                <b class="ma-0" :class="mail.date > new Date().getTime() ? 'warning--text' : ''" v-text="new Date(mail.date).toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris' }) + ' - ' + new Date(mail.date).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' })"></b>
                                <!-- btn -->
                                <div v-if="profile.role != 'User'">
                                    <v-tooltip bottom v-if="mail.responseType == 'form' && mail.form">
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon color="primary" @click.prevent.stop="toggleCorrectionVisibility(mail)" v-on="on">
                                                <v-icon v-if="mail.showCorrection">mdi-book-check-outline</v-icon>
                                                <v-icon v-else>mdi-cancel</v-icon>
                                            </v-btn>
                                        </template>
                                        <span v-if="mail.showCorrection">Cacher la corection</span>
                                        <span v-else>Rendre la corection visible</span>
                                    </v-tooltip>

                                    <v-btn v-if="new Array('Animator', 'Admin', 'SuperAdmin').includes(profile.role)" icon color="secondary" @click.prevent.stop="openSendMailModal(mail)">
                                        <v-icon>mdi-pencil-outline</v-icon>
                                    </v-btn>
                                    <v-btn v-if="new Array('Animator', 'Admin', 'SuperAdmin').includes(profile.role)" icon color="error" @click.prevent.stop="deleteMail(mail)">
                                        <v-icon>mdi-delete-outline</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                        <div>
                            <!-- attachment -->
                            <Attachment :attachments="mail.attachments"/>

                            <!-- btn start form disabled + reason -->
                            <v-tooltip right v-if="mail.responseType == 'form' && mail.form && profile.role == 'User' && ((mail.uniqueAnswer == true && mail.response) || (mail.uniqueAnswer != true && mail.uniqueAnswer < actualDate))">
                                <template v-slot:activator="{ on }">
                                    <v-chip class="ma-2" :color="mail.response ? 'success' : 'error'" outlined style="cursor: pointer" @click.prevent.stop v-on="on">
                                        <v-icon small left>mdi-chat-question-outline</v-icon>
                                        <span>{{mail.form.title}}</span>
                                        <v-icon small right v-if="mail.response">mdi-check-circle-outline</v-icon>
                                        <v-icon small right v-else>mdi-cancel</v-icon>
                                    </v-chip>
                                </template>
                                <span v-if="mail.response && mail.uniqueAnswer">Formulaire déjà rempli</span>
                                <span v-else-if="mail.response">Temps écoulé</span>
                                <span v-else>Formulaire non rendu dans les temps</span>
                            </v-tooltip>
                            <!-- btn re-start form -->
                            <v-chip v-else-if="mail.responseType == 'form' && mail.form && profile.role == 'User' && mail.response" @click.prevent.stop="openForm(mail, mail.form)" class="ma-2" color="info" outlined style="cursor: pointer" :disabled="profile.role != 'User'">
                                <v-icon small left>mdi-chat-question-outline</v-icon>
                                <span>{{mail.form.title}}</span>
                                <v-icon small right>mdi-pencil-outline</v-icon>
                            </v-chip>
                            <!-- btn start form -->
                            <v-chip v-else-if="mail.responseType == 'form' && mail.form" @click.prevent.stop="openForm(mail, mail.form)" class="ma-2" color="secondary" outlined style="cursor: pointer" :disabled="profile.role != 'User'">
                                <v-icon small left>mdi-chat-question-outline</v-icon>
                                <span>{{mail.form.title}}</span>
                            </v-chip>

                            <!--
                                slideform
                            -->
                            <div v-if="mail.responseType === 'slideform'">
                                <v-chip v-if="mail.form === null" class="ma-2" outlined style="pointer-events: none;" color="error">
                                    <v-icon small left>mdi-play-box-outline</v-icon>
                                    <span>Formulaire supprimé</span>
                                </v-chip>
                                <v-chip v-else-if="profile.role !== 'User'" class="ma-2" outlined style="pointer-events: none;">
                                    <v-icon small left>mdi-play-box-outline</v-icon>
                                    <span>{{ mail.form.name }}</span>
                                </v-chip>
                                <!-- user -->
                                <div v-else>
                                    <UserForm ref="formModal" :profile="profile" :form="mail.form" :mail="mail" />
                                    <!-- already done -->
                                    <v-tooltip right v-if="mail.slideFormResponse.isCompleted || mail.form.editableResponses && mail.form.endDate < actualDate">
                                        <template #activator="tooltip">
                                            <v-chip @click.prevent.stop="openSlideForm(mail.id)" class="ma-2" color="success" outlined v-on="{ ...tooltip.on }">
                                                <v-icon small left>mdi-play-box-outline</v-icon>
                                                <span>{{ mail.form.name }}</span>
                                                <v-icon small right>mdi-check-circle-outline</v-icon>
                                            </v-chip>
                                        </template>
                                        <span>Formulaire déjà rempli</span>
                                    </v-tooltip>
                                    <div v-else>
                                        <v-chip v-if="!mail.response" @click.prevent.stop="openSlideForm(mail.id)" class="ma-2" color="secondary" outlined>
                                            <v-icon small left>mdi-play-box-outline</v-icon>
                                            <span>{{ mail.form.name }}</span>
                                        </v-chip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <span class="d-flex flex-column justify-end">
                        <v-sheet elevation="1" class="pa-3 my-5">
                            <p class="mailTextForceFormating" v-if="mail.type == 'mail'" v-html="mail.message" style="white-space: pre-wrap;"></p>
                            <span v-else>
                                <p>Cet e-mail est une réponse au message vocal : <strong v-text="mail.message.subject"></strong> <br>du : <i v-text="new Date(mail.message.date).toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris' })"></i> à : <i v-text="new Date(mail.message.date).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' })"></i></p>
                                <v-divider></v-divider>
                                <p class="mt-5 mailTextForceFormating" v-html="mail.text" style="white-space: pre-wrap;"></p>
                            </span>
                            <v-divider></v-divider>
                            <div class="mt-8" v-if="mail.type == 'mail'">
                                <b class="pa-0 ma-0" v-text="mail.sender.lastname.toUpperCase() + ' ' + mail.sender.firstname"></b>
                                <p class="pa-0 ma-0" v-text="mail.sender.description"></p>
                                <p class="pa-0 ma-0" v-text="mail.sender.lastname.toLowerCase() + '.' + mail.sender.firstname.toLowerCase() + '@' + mail.sender.domain.toLowerCase()"></p>
                            </div>
                            <div class="mt-8 mb-3" v-else>
                                <b class="pa-0 ma-0" v-text="profile.lastname.toUpperCase() + ' ' + profile.firstname"></b>
                            </div>
                            <p class="mt-6 light--text caption" v-if="mail.type == 'mail'">Attention : cette adresse e-mail (fictive) ne doit pas être utilisée en dehors de Valy.</p>
                        </v-sheet>

                        <span v-if="mail.type != 'mail'">

                        </span>

                        <span class="d-flex flex-row justify-end" v-else-if="!new Array('none','form','slideform').includes(mail.responseType)">
                            <v-btn outlined small color="primary" class="px-8" v-if="profile.role == 'User'" :disabled="mail.response != undefined && mail.response != null" @click="openResponseModal(mail)">
                                <span class="caption">Répondre</span>
                            </v-btn>
                            <p class="info--text" v-else>Ce mail attend une réponse de type : <b v-text="responseTypes[mail.responseType]"></b></p>
                        </span>
                        
                        <p v-else-if="mail.responseType == 'form'" class="info--text text-right">Ce mail attend une réponse de type : <b v-text="responseTypes[mail.responseType]"></b></p>
                        <p v-else-if="mail.responseType == 'slideform'" class="info--text text-right">Ce mail attend une réponse au <b>formulaire</b>.</p>
                        <p v-else class="light--text text-right">La réponse a été desactivée sur ce mail !</p>
                        
                        <v-sheet elevation="1" class="pa-3 my-5 ml-10" v-if="mail.response">
                            <div class="mb-10">
                                <p class="pa-0 ma-0"><b>RE : </b><i v-text="mail.subject"></i></p>
                                <p class="ma-0 pa-0" v-text="new Date(mail.response.date).toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris' }) + ' ' + new Date(mail.response.date).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' })"></p>
                                
                                
                                <!-- attachment -->
                                <Attachment :attachments="mail.response.attachments" />
                            </div>
                            <span v-if="mail.responseType == 'form'">
                                <p v-for="question in mail.response.form.questions" class="d-flex flex-column">
                                    <b v-text="question.text + ' : '"></b>
                                    <!-- correction orderTheList -->
                                    <span v-if="mail.showCorrection && question.type == 'orderTheList'" class="d-flex flex-column ml-3 mt-2">
                                        <div v-for="(element, i) in question.response" class="d-flex">
                                            <b v-text="element" :class="question.correct ? 'success--text' : 'error--text'" class="mr-3"></b>
                                            <b v-if="!question.correct" v-text="question.correctAnswer[i]" :class="'info--text'"></b>
                                        </div>
                                    </span>
                                    <!-- correction fillTheBlank -->
                                    <span v-else-if="mail.showCorrection && question.type == 'fillTheBlank'" class="d-flex flex-wrap" :class="question.correct ? 'success--text' : 'error--text'">
                                        <div v-for="(part, i) in question.choices" class="d-flex flex-wrap my-1">
                                            <div v-if="!part.answer" v-text="part.text" class="mx-1"></div>
                                            <div v-else class="d-flex justify-center mx-1 px-1" style="min-width:50px;outline:none;border:solid 1px;border-radius:5px">
                                                <div v-text="question.response[i]"></div>
                                                <div v-if="!question.correct && question.response[i] != question.correctAnswer[i]" v-text="' (' + question.correctAnswer[i] + ')'" class="info--text"></div>
                                            </div>
                                        </div>
                                    </span>
                                    <!-- correction classification -->
                                    <span v-else-if="mail.showCorrection && question.type == 'classification'" class="d-flex pl-3">
                                        <div :class="question.correct ? 'success--text' : 'error--text'">
                                            <div v-for="category in question.response" class="d-flex flex-column">
                                                <b v-text="category.title"></b>
                                                <p style="text-indent:25px">
                                                    <span v-for="(res, i) in category.list" v-text="i == 0 ? res : ' - ' + res"></span>
                                                </p>
                                            </div>
                                        </div>
                                        <v-divider vertical class="mx-3" v-if="!question.correct"></v-divider>
                                        <div class="info--text" v-if="!question.correct">
                                            <div v-for="category in question.correctAnswer" class="d-flex flex-column">
                                                <b v-text="category.title"></b>
                                                <p style="text-indent:25px">
                                                    <span v-for="(res, i) in category.list" v-text="i == 0 ? res : ' - ' + res"></span>
                                                </p>
                                            </div>
                                        </div>
                                    </span>
                                    <!-- answer -->
                                    <span v-else-if="mail.showCorrection" :class="question.correct ? 'success--text' : question.correct == null ? 'secondary--text' : 'error--text'">
                                        <b class="pl-3" v-if="question.type == 'bool'" v-text="question.response == null ? 'Non répondu' : question.response ? 'Vrai' : 'Faux'"></b>
                                        <b class="pl-3" v-else-if="question.type == 'number'" v-text="question.response != null ? question.response : 'Non répondu'"></b>
                                        <b class="pl-3" v-else-if="question.type == 'dependency'" v-text="question.response != null ? question.response : 'Non répondu'"></b>
                                        <b class="pl-3" v-else-if="question.type == 'text'" v-text="(question.response != null && question.response.trim() != '') ? question.response : 'Non répondu'" :class="question.response != null && question.response.trim() != '' ? '' : 'secondary--text font-weight-regular'"></b>
                                        <b class="pl-3" v-else-if="question.type == 'singleChoice'" v-text="question.response != null ? question.response : 'Non répondu'"></b>
                                        <b class="pl-3" v-else-if="question.type == 'multipleChoice'" v-text="parseMultipleResponses(question.response)"></b>
                                        <b class="pl-3" v-else-if="question.type == 'list'" v-text="question.response != null ? question.response : 'Non répondu'"></b>
                                        <textarea class="pl-3" v-else-if="question.type == 'free'" disabled style="width:100%" rows="5" :class="question.response != null ? '' : 'error--text'" v-text="question.response != null ? question.response : 'Non répondu'"></textarea>
                                        <!-- display correction -->
                                        <b class="pl-3 info--text" v-if="!question.correct && question.type == 'bool'" v-text="question.correctAnswer ? '(Vrai)' : '(Faux)'"></b>
                                        <b class="pl-3 info--text" v-else-if="!question.correct && question.type == 'number'" v-text="'(' + question.correctAnswer + ')'"></b>
                                        <b class="pl-3 info--text" v-else-if="!question.correct && question.type == 'dependency'" v-text="'(' + getEvalValue(mail.response.form.questions, question, 'correctAnswer') + ')'"></b>
                                        <b class="pl-3 info--text" v-else-if="!question.correct && question.type == 'text'" v-text="'(' + question.correctAnswer + ')'"></b>
                                        <b class="pl-3 info--text" v-else-if="!question.correct && question.type == 'singleChoice'" v-text="'(' + question.correctAnswer + ')'"></b>
                                        <b class="pl-3 info--text" v-else-if="!question.correct && question.type == 'multipleChoice'" v-text="'(' + parseMultipleResponses(question.correctAnswer) + ')'"></b>
                                        <b class="pl-3 info--text" v-else-if="!question.correct && question.type == 'list'" v-text="'(' + question.correctAnswer + ')'"></b>
                                    </span>
                                    <!-- showCorrection == false -->
                                    <span v-else>
                                        <!-- response orderTheList-->
                                        <span v-if="question.type == 'orderTheList'" class="pl-3 d-flex flex-column">
                                            <b v-for="element in question.response" v-text="element"></b>
                                        </span>
                                        <!-- response fillTheBlank-->
                                        <span v-else-if="question.type == 'fillTheBlank'" class="d-flex flex-wrap pl-3">
                                            <div v-for="(part, i) in question.choices" class="d-flex flex-wrap">
                                                <span v-if="!part.answer" v-text="part.text"></span>
                                                <div v-else v-text="question.response[i]" class="mx-2 px-1" style="min-width:50px;outline:none;border:solid 1px;border-radius:5px;text-align:center"></div>
                                            </div>
                                        </span>
                                        <!-- response classification-->
                                        <span v-else-if="question.type == 'classification'" class="d-flex flex-column pl-3">
                                            <div v-for="category in question.response" class="d-flex flex-column">
                                                <b v-text="category.title"></b>
                                                <p style="text-indent:25px">
                                                    <span v-for="(res, i) in category.list" v-text="i == 0 ? res : ' - ' + res"></span>
                                                </p>
                                            </div>
                                        </span>
                                        <b class="pl-3" v-else-if="question.type == 'bool'" v-text="question.response == null ? 'Non répondu' : question.response ? 'Vrai' : 'Faux'"></b>
                                        <b class="pl-3" v-else-if="question.type == 'number'" v-text="question.response != null ? question.response : 'Non répondu'"></b>
                                        <b class="pl-3" v-else-if="question.type == 'dependency'" v-text="question.response != null ? question.response : 'Non répondu'"></b>
                                        <b class="pl-3" v-else-if="question.type == 'text'" v-text="(question.response != null && question.response.trim() != '') ? question.response : 'Non répondu'"></b>
                                        <b class="pl-3" v-else-if="question.type == 'multipleChoice'" v-text="question.response ? parseMultipleResponses(question.response) : 'Non répondu'"></b>
                                        <b class="pl-3" v-else-if="question.type == 'singleChoice'" v-text="question.response != null ? question.response : 'Non répondu'"></b>
                                        <b class="pl-3" v-else-if="question.type == 'list'" v-text="question.response != null ? question.response : 'Non répondu'"></b>
                                        <textarea disabled style="width:100%" rows="5" class="pl-3" v-else-if="question.type == 'free'" v-text="question.response != null ? question.response : 'Non répondu'"></textarea>
                                    </span>
                                </p>
                            </span>
                            <p v-else v-html="mail.response.message" style="white-space: pre-wrap"></p>
                            <v-divider></v-divider>
                            <div class="mt-8 mb-3">
                                <b class="pa-0 ma-0" v-text="profile.lastname.toUpperCase() + ' ' + profile.firstname"></b>
                            </div>
                        </v-sheet>
                    </span>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

		<v-col cols="12" class="pa-3 d-flex justify-end" v-if="profile.role == 'User'">
			<v-btn color="primary" fab @click="openSendMailModal()">
				<v-icon>mdi-send</v-icon>
			</v-btn>
		</v-col>

		<v-col cols="12" class="pa-3 d-flex justify-end" v-if="new Array('Animator', 'Admin', 'SuperAdmin').includes(profile.role)">
			<v-btn color="primary" fab @click="openSendMailModal()">
				<v-icon>mdi-plus</v-icon>
			</v-btn>
		</v-col>

        <SendMailModal ref="sendMailModal" :profile="profile" :contactsTable="contactsTable" />

        <ResponseModal ref="responseModal" title="Répondre" :data="responseData" @save="handleSave" />

        <v-dialog v-model="formModal" max-width="1000px" persistent>
            <v-card>
                <v-card-title>
					<h1 v-text="currentForm.title"></h1>
                    <v-spacer></v-spacer>
                    <!-- <div v-if="currentMail.uniqueAnswer != true && currentMail.timeLeft != 0" v-text="currentMail.timeLeft"></div> -->
					<v-btn color="secondary darken-1" icon x-large @click="askForSave(currentMail,currentForm)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
				</v-card-title>
				<v-card-text>
                    <v-card v-for="(question, index) in currentForm.questions" :key="index" outlined class="pa-3 my-3">
                        <Bool           v-if="question.type == 'bool'"                :profile="profile" :question="question" @edited="formLastUpdate = new Date().getTime()" />
                        <Number         v-else-if="question.type == 'number'"         :profile="profile" :question="question" @edited="formLastUpdate = new Date().getTime()" />
                        <Dependency     v-else-if="question.type == 'dependency'"     :profile="profile" :question="question" @edited="formLastUpdate = new Date().getTime()" />
                        <Expression     v-else-if="question.type == 'text'"           :profile="profile" :question="question" @edited="formLastUpdate = new Date().getTime()" />
                        <FillTheBlank   v-else-if="question.type == 'fillTheBlank'"   :profile="profile" :question="question" @edited="formLastUpdate = new Date().getTime()" />
                        <SingleChoice   v-else-if="question.type == 'singleChoice'"   :profile="profile" :question="question" @edited="formLastUpdate = new Date().getTime()" />
                        <MultipleChoice v-else-if="question.type == 'multipleChoice'" :profile="profile" :question="question" @edited="formLastUpdate = new Date().getTime()" />
                        <List           v-else-if="question.type == 'list'"           :profile="profile" :question="question" @edited="formLastUpdate = new Date().getTime()" />
                        <OrderTheList   v-else-if="question.type == 'orderTheList'"   :profile="profile" :question="question" @edited="formLastUpdate = new Date().getTime()" />
                        <Classification v-else-if="question.type == 'classification'" :profile="profile" :question="question" @edited="formLastUpdate = new Date().getTime()" />
                        <Free           v-else-if="question.type == 'free'"           :profile="profile" :question="question" @edited="formLastUpdate = new Date().getTime()" />
                    </v-card>
                </v-card-text>
				<v-card-actions>
					<v-btn color="blue darken-1" text @click="timerDisplay=!timerDisplay" v-if="currentMail.uniqueAnswer != true">Timer</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="close">Annuler</v-btn>
					<v-btn color="blue darken-1" text @click="saveForm(currentMail,currentForm)">sauvegarder</v-btn>
				</v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="timerDisplay" :timeout="-1" v-if="profile.role == 'User'">
            <div class="d-flex align-center">
                <v-spacer />
                <div class="d-flex align-center justify-center">
                    <div style="min-width:30px" class="d-flex justify-center"><h3 v-text="(((this.timeLeft.getDate()-1)*24)+this.timeLeft.getHours()-1 < 10 ? '0' : '') + (((this.timeLeft.getDate()-1)*24)+this.timeLeft.getHours()-1)"></h3></div>
                    <h3 class="mx-1">:</h3>
                    <div style="min-width:30px" class="d-flex justify-center"><h3 v-text="(this.timeLeft.getMinutes() < 10 ? '0' : '') + (this.timeLeft.getMinutes())"></h3></div>
                    <h3 class="mx-1">:</h3>
                    <div style="min-width:30px" class="d-flex justify-center"><h3 v-text="(this.timeLeft.getSeconds() < 10 ? '0' : '') + (this.timeLeft.getSeconds())"></h3></div>
                </div>
                <v-spacer></v-spacer>
                <v-btn icon outlined @click="timerDisplay=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>
        </v-snackbar>
    </span>
</template>

<style>
    .mailTextForceFormating, .mailTextForceFormating * {
        background-color: transparent !important;
        color: inherit !important;
    }
</style>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import logger from "@/assets/functions/logger"
import { getStorage, ref, deleteObject, getDownloadURL, uploadBytes } from "firebase/storage"

// components
import UserForm from '@/components/SlideForm/UserForm.vue'

import ResponseModal from "@/components/Email/ResponseModal.vue"
import SendMailModal from "@/components/Email/SendMailModal.vue"
import UserCard from "@/components/UserCard.vue"
import Attachment from "@/components/Attachment.vue"

import Bool from "@/components/Form/Bool.vue"
import Number from "@/components/Form/Number.vue"
import Dependency from "@/components/Form/Dependency.vue"
import Expression from "@/components/Form/Expression.vue"
import FillTheBlank from "@/components/Form/fillTheBlank.vue"
import SingleChoice from "@/components/Form/SingleChoice.vue"
import MultipleChoice from "@/components/Form/MultipleChoice.vue"
import List from "@/components/Form/List.vue"
import OrderTheList from "@/components/Form/OrderTheList.vue"
import Classification from '@/components/Form/Classification'
import Free from "@/components/Form/Free.vue"

// classes
import SlideForm from '@/classes/SlideForm'
import FormResponse from '@/classes/FormResponse'
import Form from "@/classes/Form"
import Mail from "@/classes/Mail"
import MailResponse from "@/classes/MailResponse"
import Contact from "@/classes/Contact"
import Group from "@/classes/Group"
import Notification from "@/classes/Notification"

export default {
    props: ['profile', 'notifications', 'config', 'currentRoom', 'contactsTable'],
    components: {
        ResponseModal,
        SendMailModal,
        UserCard,
        Attachment,
        Bool,
        Number,
        Dependency,
        Expression,
        FillTheBlank,
        SingleChoice,
        MultipleChoice,
        List,
        OrderTheList,
        Classification,
        Free,
        UserForm
    },
    setup() {
        return {
            storage: getStorage()
        }
    },
    data() {
        return {
            mails: {
                all: []
            },
            groupsTable: [],
            unsub: [],
            responseData: {},
            responseTypes: {
                none:"Sans réponse",
                text:"Mail (text)",
                file:"Piece jointe (fichier)",
                form:"Formulaire",
            },
            formModal:false,
            currentForm:{
                id: null,
                title: null,
                questions: [],
            },
            currentMail:{},
            actualDate: new Date().getTime(),
            timeLeft: new Date(),
            timerDisplay: false,
            formLastUpdate: null
        }
    },

    async created(){
        // form with dead line timer
        let interval
        clearInterval(interval)
        interval = setInterval(() => {
            this.actualDate = new Date().getTime()
            if(this.currentMail.uniqueAnswer && this.currentMail.uniqueAnswer != true){
                this.timeLeft = new Date(this.currentMail.uniqueAnswer - this.actualDate)
                if(this.currentMail.uniqueAnswer < this.actualDate){
                    this.saveForm(this.currentMail, this.currentForm)
                }
            }
        },250)

        // for each contact
        if (this.profile.role != 'User') {
            Contact.getByRoom(this.profile.currentRoom).then(data => {
                for (const contact of data) {
                    this.mails[contact.id] = []
                    this.unsub.push(Mail.listenByRoomAndBySender(this.profile.currentRoom, contact.id, async mailsData => {
                        this.mails[contact.id] = []
                        for (const mail of mailsData)
                            this.mails[contact.id].push(await this.getFullMail(mail))

                        this.mails.all = []
                        for (const key in this.mails)
                            if (key != 'all') this.mails.all = this.mails.all.concat(this.mails[key])

                        this.mails.all.sort((a,b)=>{
                            return b.date - a.date
                        })
                    }))
                }
            })
        }
        
        if (this.profile.role === 'User') {
            // for all
            this.unsub.push(Mail.listenByRoomAndByReceiver(this.profile.currentRoom, 'all', async data => {
                this.mails.forall = []
                for (const mail of data)
                    this.mails.forall.push(await this.getFullMail(mail))

                this.mails.all = []
                for (const key in this.mails)
                    if (key != 'all') this.mails.all = this.mails.all.concat(this.mails[key])

                this.mails.all.sort((a,b)=>{
                    return b.date - a.date
                })
            }))

            // for user connected
            this.unsub.push(Mail.listenByRoomAndByReceiver(this.profile.currentRoom, this.profile.id, async data => {
                this.mails.user = []
                for (const mail of data)
                    this.mails.user.push(await this.getFullMail(mail))

                this.mails.all = []
                for (const key in this.mails)
                    if (key != 'all') this.mails.all = this.mails.all.concat(this.mails[key])

                this.mails.all.sort((a,b)=>{
                    return b.date - a.date
                })
            }))

            // for each group of user connected
            Group.getByRoomAndByOneMember(this.profile.currentRoom, this.profile.id).then(data => {
                for (let group of data) {
                    this.unsub.push(Mail.listenByRoomAndByReceiver(this.profile.currentRoom, group.id, async data => {
                        this.mails[group.id] = []
                        for (const mail of data)
                            this.mails[group.id].push(await this.getFullMail(mail))

                        this.mails.all = []
                        for (const key in this.mails)
                            if (key != 'all') this.mails.all = this.mails.all.concat(this.mails[key])

                        this.mails.all.sort((a,b)=>{
                            return b.date - a.date
                        })
                    }))
                }
            })
        }
    },

    methods: {
        /* NOTIFICATIONS */
        checkNotif(mailId){
            if (this.notifications && this.notifications.mail) {
                for (let notif of this.notifications.mail) {
                    if (notif.content === null) notif.delete()
                    // else if (!this.mails.all.includes(notif.content)) notif.delete() // delete all notif cause load before the mail spawn
                    if (mailId === notif.content) return true
                }
                return false
            }
            return false
        },
        deleteNotif(mailId){
            if (this.notifications && this.notifications.mail) {
                for (let notif of this.notifications.mail) {
                    if (mailId === notif.content) {
                        notif.delete()
                        return
                    }
                }
            }
        },

        /* MAILS */
        async getContact(id) {
            if (this.contactsTable[id]) {
                return this.contactsTable[id]
            } else {
                let tmp_contact = await Contact.getById(id)
                if (!tmp_contact) tmp_contact = await Profile.getById(id)
                return tmp_contact
            }
        },
        async getFullMail(mail){
            // sender
            mail.sender = await this.getContact(mail.sender)
            if (mail.receiver != 'all') mail.receiver = await this.getContact(mail.receiver)

            // message
            if(this.profile.role === 'User'){
                mail.message = mail.message.replaceAll('{{nom}}', this.profile.lastname)
                mail.message = mail.message.replaceAll('{{prenom}}', this.profile.firstname)
            }

            // attachments
            for(let attachment of mail.attachments){
                let fileRef = ref(this.storage, attachment.ref) 
                getDownloadURL(fileRef)
                .then((url) => {
                    attachment.url = url
                })
            }

            // form
            if(mail.responseType == 'form'){
                if(mail.form == undefined || mail.form == null){
                  console.error("a mail is missing a form id : " + mail.id)
                }else{
                  mail.form = await Form.getById(mail.form)
                  mail.form.decode()

                  // need uid for find the right question when questions are mixed
                  mail.form.questions.forEach((question, index) => {
                      question.uid = `Q${index}`
                  })

                  if(mail.form.rdmOrder.question) mail.form.questions.sort((a, b) => 0.5 - Math.random())
                  if(mail.form.rdmOrder.answer){
                      mail.form.questions.forEach(question => {
                          if(new Array('singleChoice', 'multipleChoice', 'list').includes(question.type)) question.choices.sort((a, b) => 0.5 - Math.random())
                      })
                  }
                  for(let question of mail.form.questions){
                      if(question.type == 'orderTheList'){
                          question.choices.sort((a, b) => 0.5 - Math.random())
                      }
                  }
                  if(mail.form && mail.form.questions && mail.form.questions.length > 0){
                      mail.form.questions.forEach((question) => {
                          question.type == 'fillTheBlank' ? question.response = [] : question.response = null
                      })
                  }
                }
            }

            // slideform
            else if (mail.responseType == 'slideform') {
                const formId = mail.form
                mail.form = await SlideForm.getById(formId)
                if (this.profile.role === 'User' && mail.form !== null) {
                    const response = await FormResponse.getBySenderAndByForm(this.profile.id, formId)

                    const isCompleted = mail.form.correctionType === 'standard' && mail.form.editableResponses === false && response
                    mail.slideFormResponse = {
                        responseId: response ? response.id : null,
                        isCompleted: isCompleted
                    }
                }
            }

            if(!new Array('none').includes(mail.responseTypes) && this.profile.role === 'User') {
                let mailResponse = await MailResponse.getByMailAndSender(mail.id, this.profile.id)
                if(mailResponse){
                    mail.response = mailResponse
                }
            }
            
            mail.type = 'mail'
            
            return mail
        },
        deleteMail(mail) {
            Swal.fire({
                title: "Etes-vous sûr ?",
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Oui",
                cancelButtonText: "Annuler",
            }).then(async (result) => {
                if(result.isConfirmed){
                    const refs = mail.attachments.map(attach => attach.ref)

                    // only if no used elsewhere
                    Mail.checkIfAttachIsUsed(refs, mail.room).then(refsUsed => {
                        for (const i in refsUsed) {
                            if (!refsUsed[i]) {
                                const fileRef = ref(this.storage, refs[i])
                                deleteObject(fileRef)
                            }
                        }
                    })
                    let notifications = await Notification.getByContent(mail.id)
                    for(let notif of notifications) notif.delete()

                    mail.delete()
                    logger.log(this.profile.id, "MAIL DELETED", this.profile.email + " a supprimé le mail " + mail.id)
                }
            })
        },
        toggleCorrectionVisibility(mail){
            if(mail.uniqueAnswer != true && mail.uniqueAnswer >= new Date().getTime() && mail.showCorrection == false){
                Swal.fire({
                    title: "Erreur",
                    text: "Les utilisateurs peuvent encore modifier leurs réponses",
                    icon: "error",
                    confirmButtonText: "Ok",
                })
            }else{
                Mail.updateById(mail.id, {showCorrection: !mail.showCorrection})
            }
        },


        openSendMailModal(mail = null) {
            this.$refs.sendMailModal.open(mail)
        },
        parseMultipleResponses(response){
            if(response && response.length > 0){
                let tmp_response = ""
                for(let i = 0; i < response.length; i++){
                    tmp_response += response[i]
                    if(i < response.length - 2) tmp_response += ", "
                    else if(i < response.length - 1) tmp_response += " et "
                }
                return tmp_response
            }else{
                return ""
            }
        },
        openResponseModal(mail) {
            if(this.profile.role == "User"){
                if(!this.currentRoom.lockLevel.editing){
                    Swal.fire({
                        title: "Erreur",
                        text: "Cette Room est actuellement verouillée",
                        icon: "error",
                        confirmButtonText: "Ok",
                    })
                }else{
                    let tmp_data = {}
                    this.responseData = {}
                    
                    tmp_data.mail = mail
                    tmp_data.attachments = []
                    tmp_data.message = ""
                    
                    console.log(tmp_data.message)

                    this.responseData = tmp_data

                    this.$refs.responseModal.open()
                }
            }
        },
        handleSave(data){
            this.addResponse(data)
        },
        async addResponse(data) {
            let date = new Date()
            let uploadName = date.getFullYear() + "-" + date.getMonth() + "-" + date.getDay() + "_" + date.getHours() + "h" + date.getMinutes() + "m" + date.getSeconds() + "s_" + date.getTime()
            let counter = 0
            
            let tmp_attachments = []

            for (let file of data.attachments) {
                let name = uploadName + "_" + counter.toString()
                let path = "mailResponse/attachments/" + this.profile.id + "/" + name + "." + file.name.split(".").pop()
                let fileRef = ref(this.storage, path)

                await uploadBytes(fileRef, file)
                tmp_attachments.push({
                    name: file.name,
                    ref: path,
                })
                counter++
            }
            
            if(!data.form){
                data.form = null
                data.formLastUpdate = null
            }else{
                data.form = this.applyCorrection(data.form)
                data.form = JSON.parse(JSON.stringify(data.form))
            }
            let mailResponse
            if(data.mail.response) mailResponse = new MailResponse(data.mail.response.id, data.mail.id, new Date().getTime(), this.profile.id, data.message, tmp_attachments, data.form, data.formLastUpdate)
            else mailResponse = new MailResponse(null, data.mail.id, new Date().getTime(), this.profile.id, data.message, tmp_attachments, data.form, data.formLastUpdate)
            mailResponse.save()
            .then(() => {
                for(let currentMail of this.mails.all) {
                    if(currentMail.id == mailResponse.mail) {
                        currentMail.response = mailResponse
                        for(let attachment of mailResponse.attachments) {
                            let fileRef = ref(this.storage, attachment.ref) 
                            getDownloadURL(fileRef)
                            .then((url) => {
                                attachment.url = url
                            })
                        }
                        this.$forceUpdate()
                        break
                    }
                }
                logger.log(this.profile.id, "RESPONSE ADD", this.profile.email + " a ajouté une réponse a " + data.mail.id)
                Swal.fire({
                    title: "Message envoyé",
                    text: "Le message a bien été envoyé",
                    icon: "success",
                    confirmButtonText: "Ok",
                })
            })
        },
        openForm(mail, form){
            if(this.profile.role == "User"){
                if(!this.currentRoom.lockLevel.editing){
                    Swal.fire({
                        title: "Erreur",
                        text: "Cette Room est actuellement verouillée",
                        icon: "error",
                        confirmButtonText: "Ok",
                    })
                }else{
                    if(mail.response) this.currentForm = mail.response.form
                    else this.currentForm = form
                    if(mail.uniqueAnswer != true) this.timerDisplay = true
                    this.currentMail = mail
                    this.formModal = true
                }
            }
        },
        close() {
            this.currentForm = {}
            this.currentMail = {}
            this.timerDisplay = false
            this.formModal = false
        },
        /**
         * if a user have two window with the form, the form will be save only when the page is focus
         * So, we can compare the two FormLastUpdate timestamp to know which one is the latest
         */
        async saveForm(mail,form) {
            if (document.hasFocus()) {
                this.addResponse({
                    attachments: [],
                    mail: mail,
                    message: "",
                    form: form,
                    formLastUpdate: this.formLastUpdate
                })
            } else {
                window.addEventListener('focus', () => {
                    this.saveForm(mail,form)
                }, {once: true})
            }
            this.close()
        },
        askForSave(mail,form) {
            Swal.fire({
                title: 'Voulez vous sauvegarder',
                icon: 'question',
                confirmButtonText: 'Sauvegarder',
                showCancelButton: true,
                cancelButtonText: 'Quitter sans sauvegarder', 
            }).then(result => {
                if (result.isConfirmed) {
                    this.saveForm(mail,form)
                } else {
                    this.close()
                }
            })
        },



        /* FORMS */
        applyCorrection(form){
            for(let question of form.questions){
                if (question.response === null) question.correct = false
                else {
                    question.correct = null
                    if(new Array('bool', 'number', 'singleChoice', 'list').includes(question.type)) question.correct = question.response == question.correctAnswer
                    else switch(question.type){
                        case 'dependency':
                            let results = [
                                this.getEvalValue(form.questions, question, 'correctAnswer'),
                                this.getEvalValue(form.questions, question, 'response')
                            ]
                            question.correct = results.includes(parseFloat(question.response))
                            break
                        case 'text':
                            if(question.response == null || question.response.trim() == '') question.correct = false
                            else question.correct = question.response.toUpperCase().replaceAll(' ', '').replaceAll(/[^\w\s]/g, '') == question.correctAnswer.toUpperCase().replaceAll(' ', '').replaceAll(/[^\w\s]/g, '')
                            break
                        case 'fillTheBlank':
                            question.correct = true
                            if(question.response.length == 0){
                                question.correct = false
                                break
                            }
                            let i =0
                            for(let res of question.response){
                                if(
                                    res != undefined
                                    && res != question.correctAnswer[i]
                                    && res.replaceAll(',', '.') != question.correctAnswer[i].replaceAll(',', '.')
                                ){
                                    question.correct = false
                                    break
                                }
                                i++
                            }
                            break
                        case 'multipleChoice':
                            if(question.response) question.response.sort()
                            question.correctAnswer.sort()
                            question.correct = JSON.stringify(question.response) == JSON.stringify(question.correctAnswer)
                            break
                        case 'orderTheList':
                            question.correct = true
                            question.response.forEach((element, i) => {
                                if(element != question.correctAnswer[i]) question.correct = false
                            })
                            break
                        case 'classification':
                            question.correct = true
                            question.response.forEach((category, i) => {
                                category.list.sort()
                                question.correctAnswer[i].list.sort()
                                if(JSON.stringify(category.list) != JSON.stringify(question.correctAnswer[i].list)) question.correct = false
                            })
                            break
                        default: question.correct = null
                    }
                }
            }
            return form
        },
        // calculs for dependency question
        getEvalValue(questions, question, returnType){
            const varList = []
            const charList = question.correctAnswer.split('')
            let isAQuestion = false
            let i = 0
            for(let char of charList){
                if(char == 'Q'){
                    isAQuestion = true
                }else if(isAQuestion == true && char == char.match(/[0-9]/g)){
                    if(charList[i-1] == 'Q') varList.push(char)
                    else varList[varList.length-1] += char
                }else{
                    isAQuestion = false
                }
                i++
            }
            let result = question.correctAnswer
            for(let questionIndex of varList.reverse()){
                let varName = 'Q' + questionIndex
                switch(returnType){
                    case 'correctAnswer':
                        // need uid for find the right question when questions are mixed
                        result = result.replaceAll(varName, (parseFloat(questions.find(question => question.uid = varName).correctAnswer.replaceAll(',', '.')) || 0))
                        break
                    case 'response':
                        if (questions[parseInt(questionIndex)-1].response) result = result.replace(varName, (parseFloat(questions[parseInt(questionIndex)-1].response.replaceAll(',', '.')) || 0))
                        else result = null
                        break
                    default: break
                }
            }
            return result === null ? null : eval(result)
        },


        // slide Form
        openSlideForm(mailId) {
            const mailIndex = this.$refs.formModal.findIndex(modal => modal.mail.id === mailId)
            this.$refs.formModal[mailIndex].open()
        },
    },
    destroyed() {
        this.unsub.forEach((unsub) => unsub())
    },
}
</script>