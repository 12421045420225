<template>
    <span v-if="profile.role != 'User'">
        <v-col cols="12" sm="4" class="pt-0 pb-0">
            <v-text-field v-model="question.choices.title" label="Nom des éléments" />
        </v-col>

        <draggable v-model="question.choices.list" @start="dragging = true" @end="dragging = false" group="item" class="d-flex flex-wrap mb-3 pb-3" style="border-bottom:solid 1px var(--v-light-base);min-height:52px;">
            <div class="d-flex align-center ma-2 pa-1" v-for="(item, i) in question.choices.list" style="border-radius:5px;border:solid 1px;">
                <div @blur="asignValue($event, i)" v-text="question.choices.list[i]" contenteditable="true" class="px-1" style="min-width:50px;outline:none;border-radius:5px;text-align:center;border-bottom:solid 1px;"></div>
                <v-icon color="secondary" class="mx-1 pointer">mdi-arrow-all</v-icon>
                <v-icon @click="question.choices.list.splice(i, 1)" small color="error">mdi-delete-outline</v-icon>
            </div>
            <v-btn @click="question.choices.list.push('')" icon color="primary" class="ma-2">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </draggable>
        
        <div class="d-flex flex-wrap align-center justify-center">
            <v-col v-for="(category, catIndex) in question.correctAnswer" cols="12" sm="6" md="4" class="d-flex flex-column">
                <div class="d-flex align-center" style="border-top:solid 1px;border-left:solid 1px;border-right:solid 1px;border-top-left-radius:10px;border-top-right-radius:10px">
                    <v-text-field label="Titre de la liste" v-model="category.title" class="ml-2" />
                    <v-icon @click="removeCategory(question, catIndex)" color="error" class="mx-2">mdi-delete-outline</v-icon>
                </div>
                <draggable v-model="category.list" @start="dragging = true" @end="dragging = false" group="item" class="d-flex flex-wrap" style="border:solid 1px;border-bottom-left-radius:10px;border-bottom-right-radius:10px;min-height:52px;">
                    <div class="d-flex align-center ma-2 pa-1" v-for="(item, itemIndex) in category.list" style="border-radius:5px;border:solid 1px;">
                        <div @blur="asignValue($event, itemIndex, catIndex)" v-text="item" contenteditable="true" class="px-1" style="min-width:50px;outline:none;border-radius:5px;text-align:center;border-bottom:solid 1px;"></div>
                        <v-icon color="secondary" class="mx-1 pointer">mdi-arrow-all</v-icon>
                        <v-icon @click="category.list.splice(itemIndex, 1)" small color="error">mdi-delete-outline</v-icon>
                    </div>
                    <v-btn @click="category.list.push('')" icon color="primary"  class="ma-2">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </draggable>
            </v-col>

            <v-btn @click="question.correctAnswer.push({title:'',list:['']})" icon large color="primary">
                <v-icon large>mdi-plus</v-icon>
            </v-btn>
        </div>
    </span>

    <span v-else>
        <h4 v-text="question.text" class="mb-2"></h4>
        <p v-text="question.choices.title + ' :'" class="mb-0"></p>

        <draggable v-model="question.choices.list" @start="dragging = true" @end="dragging = false; $emit('edited')" group="item" class="d-flex flex-wrap mb-3 pb-3" style="border-bottom:solid 1px var(--v-light-base);min-height:52px;">
            <div class="d-flex align-center ma-2 pa-1" v-for="(item, i) in question.choices.list" style="border-radius:5px;border:solid 1px;">
                <div v-text="item" class="px-1 pointer"></div>
            </div>
        </draggable>

        <div class="d-flex flex-wrap align-center justify-center">
            <v-col v-for="category in question.response" cols="12" sm="6" md="4" class="d-flex flex-column">
                <div style="border-top:solid 1px;border-left:solid 1px;border-right:solid 1px;border-top-left-radius:10px;border-top-right-radius:10px">
                    <h4 v-text="category.title" style="text-align:center"></h4>
                </div>
                <draggable v-model="category.list" @start="dragging = true" @end="dragging = false; $emit('edited')" group="item" class="d-flex flex-wrap" style="border:solid 1px;border-bottom-left-radius:10px;border-bottom-right-radius:10px;min-height:52px;">
                    <div class="d-flex align-center ma-2 pa-1" v-for="(item, i) in category.list" style="border-radius:5px;border:solid 1px;">
                        <div v-text="item" class="px-1 pointer"></div>
                    </div>
                </draggable>
            </v-col>
        </div>
    </span>
</template>
<script>
import draggable from 'vuedraggable'

export default {
    props: ['profile', 'question'],
    components: {
        draggable
    },
    data: () => ({
        response: {
            valide: false,
            errorMessage: ''
        }
    }),
    created() {
        if(this.profile.role == 'User'){
            if(this.question.response == null){
                this.question.response = []
                this.question.correctAnswer.forEach(category => {
                    this.question.response.push({title: category.title,list:[]})
                    category.list.forEach(item => {
                        this.question.choices.list.push(item)
                    })
                })
                this.question.choices.list.sort((a, b) => 0.5 - Math.random())
            }
        }
    },
    methods: {
        asignValue(event, index, categoryIndex) {
            if (![undefined, null].includes(categoryIndex))
                this.question.correctAnswer[categoryIndex].list[index] = event.target.innerText
            else
                this.question.choices.list[index] = event.target.innerText
        },
        testIsValid(){
            // titre
            if(!this.question.text || this.question.text.trim() === ''){
                this.response.errorMessage = 'Veuillez renseigner un titre à toutes les questions.'
                return this.response
            }
            // titre des choix
            if(this.question.choices.title === ''){
                this.response.errorMessage = 'Une des questions comporte un choix vide.'
                return this.response
            }
            // choix (sans categorie)
            for(let choice of this.question.choices.list){
                if(!choice || choice.trim() === ''){
                    this.response.errorMessage = 'Une des questions comporte un choix vide.'
                    return this.response
                }
            }
            // categories
            for(let category of this.question.correctAnswer){
                // titre de la categorie
                if(!category.title || category.title.trim() === ''){
                    this.response.errorMessage = 'Une des questions comporte un choix vide.'
                    return this.response
                }
                // choix de la categorie
                for(let item of category.list){
                    if(!item || item.trim() === ''){
                        this.response.errorMessage = 'Une des questions comporte un choix vide.'
                        return this.response
                    }
                }
            }
            // validation
            this.response.valide = true
            return this.response
        }
    }
}
</script>