<template>
  <div class="questionSlide-container" :class="slide.content.layout === 'left' ? 'flex-row-reverse' : ''" @mouseover="addToRightClick" @mouseleave="delToRightClick">
    <div v-if="(!this.slide.content.questions || this.slide.content.questions.length === 0) && !this.slide.content.question && !this.preview" class="d-flex flex-column gap-xl">
      <v-select v-model="selectedQuestionType" :items="questionTypes" hide-details class="ma-0 pa-0"></v-select>
      <v-select v-model="selectedQuestion" :items="selectedQuestionType" @change="addQuestion" hide-details class="ma-0 pa-0"
        :placeholder="'Choisir une question ' + questionTypes.find(x=>x.value===selectedQuestionType).type"
      ></v-select>
    </div>

    <div v-else-if="slide.content.type === 'simple'" class="question-container" :style="slide.content.media && preview ? '' : 'width:100%'">
      <SimpleQuestionLayout v-for="(question, index) of slide.content.questions" :key="index" :slide="slide" :slideIndex="slideIndex" :questionIndex="index" :question="question" :preview="preview" :rightClickItems="rightClickItems" :profile="profile" :formResponse="formResponse" :disabled="isDisabled(slideIndex)" :showCorrection="showCorrection" :editData="editData" />

      <div class="more-question" v-if="this.slide.content.questions && this.slide.content.questions.length < 3 && !this.preview">
        <v-select v-model="selectedQuestion" :items="selectedQuestionType" @change="addQuestion" hide-details class="ma-0 pa-0"
          :placeholder="'Ajouter une autre question ?'"
        ></v-select>
      </div>
    </div>

    <div v-else-if="slide.content.type === 'advanced'" style="width: 100%; height: 100%; position: absolute; bottom: 0;">
      <SelectImage         v-if="slide.content.question.type === 'selectImage'"                     :form="form" :profile="profile" :formResponse="formResponse" :slideIndex="slideIndex" :question="slide.content.question" :preview="preview" :rightClickItems="rightClickItems" :disabled="isDisabled(slideIndex)" :showCorrection="showCorrection" />
      <Classification v-else-if="slide.content.question.type === 'classification'"                               :profile="profile" :formResponse="formResponse" :slideIndex="slideIndex" :question="slide.content.question" :preview="preview" :rightClickItems="rightClickItems" :disabled="isDisabled(slideIndex)" :showCorrection="showCorrection" />
      <OrderTheList   v-else-if="slide.content.question.type === 'orderTheList'"                                 :profile="profile" :formResponse="formResponse" :slideIndex="slideIndex" :question="slide.content.question" :preview="preview" :rightClickItems="rightClickItems" :disabled="isDisabled(slideIndex)" :showCorrection="showCorrection" />
      <LinkingLists   v-else-if="slide.content.question.type === 'linkingLists' && selectedSlide === slideIndex" :profile="profile" :formResponse="formResponse" :slideIndex="slideIndex" :question="slide.content.question" :preview="preview" :rightClickItems="rightClickItems" :disabled="isDisabled(slideIndex)" :showCorrection="showCorrection" />
    </div>
    
    <div v-if="true">
      <pre>{{ landscapeScreen }}</pre>
      <!-- text -->
      <v-textarea v-if="this.slide.content.text !== undefined && !this.preview" v-model="slide.content.text" rows="1" auto-grow hide-details class="mt-0 pt-0" placeholder="texte" />
      <pre v-else-if="this.slide.content.text !== undefined && this.preview">{{ slide.content.text }}</pre>
      <!-- video -->
      <video v-else-if="this.slide.content.media && this.slide.content.media.type === 'video'" controls preload="auto" :src="slide.content.media.ref"></video>
      <!-- audio -->
      <audio v-else-if="this.slide.content.media && this.slide.content.media.type === 'audio'" controls preload="auto" :src="slide.content.media.ref"></audio>
      <!-- picture -->
      <img v-else-if="this.slide.content.media && this.slide.content.media.type === 'picture'" :src="slide.content.media.ref">

      <!-- media input -->
      <input type="file" :accept="acceptedExt" class="d-none" ref="fileInput" @change="addMedia">
    </div>
    
    <div class="more-info-buttons">
      <TipsButton :slide="slide" :preview="preview" />
      <ExplainButton :slide="slide" :preview="preview" :showCorrection="showCorrection" :profile="profile" :adminCheckResponses="profile.role !== 'User'" :formResponse="formResponse" :slideIndex="slideIndex" />
    </div>

    <div class="realtime-correction-btn" v-if="profile.role === 'User' && form.correctionType === 'realtime'">
      <v-tooltip left>
        <template #activator="tooltip">
          <v-btn color="success" @click="validateSlide" v-on="{ ...tooltip.on }" :disabled="isDisabled(slideIndex)">
            <div class="d-flex align-center gap-m">
              <p class="white--text mb-0 mt-1">Valider</p>
              <v-icon color="white" >mdi-check</v-icon>
            </div>
          </v-btn>
        </template>
        <span>Valider la slide</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { audioExt, videoExt, pictureExt } from '@/assets/config/ExtWhiteList'

import SimpleQuestionLayout from '@/components/SlideForm/SimpleQuestionLayout.vue'
import SelectImage from '@/components/SlideForm/Questions/SelectImage.vue'
import Classification from '@/components/SlideForm/Questions/Classification.vue'
import OrderTheList from '@/components/SlideForm/Questions/OrderTheList.vue'
import LinkingLists from '@/components/SlideForm/Questions/LinkingLists.vue'

import ExplainButton from '@/components/SlideForm/ExplainButton.vue'
import TipsButton from '@/components/SlideForm/TipsButton.vue'

export default {
  props: ['profile', 'form', 'slide', 'slideIndex', 'selectedSlide', 'preview', 'rightClickItems', 'landscapeScreen', 'formResponse', 'showCorrection', 'editData'],
  components: { SimpleQuestionLayout, SelectImage, Classification, OrderTheList, LinkingLists, ExplainButton, TipsButton },
  data() {
    return {
      simpleQuestions: [
        {
          text: 'Libre (sans correction)',
          value: 'free'
        },
        {
          text: 'Nombre',
          value: 'number'
        },
        {
          text: 'Expression',
          value: 'expression'
        },
        {
          text: 'Booléen',
          value: 'boolean'
        },
        {
          text: 'Texte à trous',
          value: 'fillTheBlank'
        },
        {
          text: 'Choix unique',
          value: 'uniqueChoice'
        },
        {
          text: 'Choix multiple',
          value: 'multipleChoice'
        },
        {
          text: 'Liste déroulante',
          value: 'list'
        },
        {
          text: 'Slider',
          value: 'slider'
        },
        {
          text: 'Slider numérique',
          value: 'numericSlider'
        },
        {
          text: 'Notation',
          value: 'rating'
        },
        {
          text: 'Dépendante',
          value: 'dependent'
        }
      ],
      advancedQuestions: [
        {
          text: 'Selection d\'images',
          value: 'selectImage'
        },
        {
          text: 'Classification',
          value: 'classification'
        },
        {
          text: 'Trié la liste',
          value: 'orderTheList'
        },
        {
          text: 'Relier les listes',
          value: 'linkingLists'
        }
      ],
      selectedQuestion: null,
      selectedQuestionType: null,
      questionTypes: null,
      side: null,
      acceptedExt: ''
    }
  },
  created() {
    this.selectedQuestionType = this.simpleQuestions
    this.questionTypes = [
      {
        text: 'Questions simples',
        value: this.simpleQuestions,
        type: 'simple'
      },
      {
        text: 'Questions avancées',
        value: this.advancedQuestions,
        type: 'advanced'
      }
    ]
  },
  mounted() {
    this.acceptedExt = audioExt.concat(videoExt.concat(pictureExt)).map(x=>`.${x}`).join(', ')
  },
  methods: {
    addToRightClick() {
      if (this.rightClickItems && this.slide.content.type === 'simple') {
        if (this.slide.content.text === undefined && this.slide.content.media === undefined) {
          this.rightClickItems[3] = {
            text: 'Ajouter un texte à gauche',
            method: () => {
              this.addText('left')
              this.addToRightClick()
              this.$forceUpdate()
            }
          }
          this.rightClickItems[4] = {
            text: 'Ajouter un texte à droite',
            method: () => {
              this.addText('right')
              this.addToRightClick()
              this.$forceUpdate()
            }
          }
          this.rightClickItems[5] = {
            text: 'Ajouter un média à gauche',
            method: () => {
              this.side = 'left'
              this.$refs.fileInput.click()
              this.addToRightClick()
              this.$forceUpdate()
            }
          }
          this.rightClickItems[6] = {
            text: 'Ajouter un média à droite',
            method: () => {
              this.side = 'right'
              this.$refs.fileInput.click()
              this.addToRightClick()
              this.$forceUpdate()
            }
          }
        } else if (this.slide.content.text !== undefined) {
          this.delToRightClick()
          this.rightClickItems[3] = {
            text: 'Supprimé le texte',
            method: () => {
              this.delText()
              this.addToRightClick()
              this.$forceUpdate()
            }
          }
        } else if (this.slide.content.media !== undefined) {
          this.delToRightClick()
          this.rightClickItems[3] = {
            text: 'Supprimé le média',
            method: () => {
              this.delMedia()
              this.addToRightClick()
              this.$forceUpdate()
            }
          }
        }
      }
    },
    delToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[3] = null
        this.rightClickItems[4] = null
        this.rightClickItems[5] = null
        this.rightClickItems[6] = null
      }
    },

    addText(side) {
      this.slide.content.layout = side

      this.slide.content.text = ''
      this.$forceUpdate()
    },
    delText() {
      delete this.slide.content.layout
      delete this.slide.content.text
      this.$forceUpdate()
    },
    addMedia(event) {
      // get file
      const file = event.target.files[0]
      if (!file) return

      // check ext
      const ext = file.name.toLowerCase().split('.').pop()
      let type = null
      if (audioExt.includes(ext)) type = 'audio'
      else if (videoExt.includes(ext)) type = 'video'
      else if (pictureExt.includes(ext)) type = 'picture'
      if (type === null) {
        Swal.fire({
          title: 'Erreur',
          text: 'Type de fichier invalide.',
          icon: 'error',
          confirmButtonText: 'Ok',
        })
        return
      }

      // change content layout
      this.slide.content.layout = this.side

      // create object
      this.slide.content.media = {
        name: file.name,
        ref: URL.createObjectURL(file),
        file: file,
        type: type,
        isUpdated: true
      }
      this.$forceUpdate()
    },
    delMedia() {
      if (this.slide.content.media.path !== undefined) {
        if (this.form.deletedFiles === undefined) this.form.deletedFiles = []
        this.form.deletedFiles.push(this.slide.content.media.path)
      }

      delete this.slide.content.layout
      delete this.slide.content.media
      this.$forceUpdate()
    },
    addQuestion(questionType) {
      this.editData.isEdited = true
      this.editData.isCriticEdited = true

      // simple / advanced
      this.slide.content.type = this.questionTypes.find(x=>x.value===this.selectedQuestionType).type
      setTimeout(() => { this.selectedQuestion = null }, 0)

      const new_question = {
        type: questionType,
        text: null
      }

      if (!['free', 'rating'].includes(questionType))
        new_question.points = 1

      if (questionType === 'fillTheBlank') {
        new_question.goodResponse = [
          { isBlank: false, value: '' },
          { isBlank: true, value: '' },
          { isBlank: false, value: '' }
        ]
      } else if (questionType === 'uniqueChoice') {
        new_question.choices = ['', '']
      } else if (questionType === 'multipleChoice') {
        new_question.choices = ['', '']
        new_question.goodResponse = [false, false]
      } else if (questionType === 'list') {
        new_question.choices = ['', '']
      } else if (questionType === 'slider') {
        new_question.choices = ['', '', '']
      } else if (questionType === 'numericSlider') {
        new_question.goodResponse = 0
        new_question.min = 0
        new_question.step = 1
        new_question.max = 10
      } else if (questionType === 'dependent') {
        new_question.goodResponse = ''
      } else if (questionType === 'selectImage') {
        new_question.choices = [null, null]
      } else if (questionType === 'classification') {
        new_question.choices = ['', '']
        new_question.goodResponse = [
          { title: '', choices: [] },
          { title: '', choices: [] }
        ]
      } else if (questionType === 'orderTheList') {
        new_question.goodResponse = ['', '', '', '']
      } else if (questionType === 'linkingLists') {
        new_question.choices = {
          left: ['', ''],
          right: ['', '']
        }
        new_question.goodResponse = []
      }

      if (this.slide.content.type === 'simple') {
        if (!this.slide.content.questions) this.slide.content.questions = []
        this.slide.content.questions.push(new_question)
      }
      else this.slide.content.question = new_question
      this.$forceUpdate()
    },

    async validateSlide() {
      const result = await Swal.fire({
        text: 'Voulez-vous sauvegarder vos réponses ?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non'
      })
      
      if (result.isConfirmed) {
        if (this.formResponse.slidesValidate === null) this.formResponse.slidesValidate = []
        this.formResponse.slidesValidate.push(this.slideIndex)
        await this.formResponse.save()
        this.$forceUpdate()
      }
    },

    isDisabled(slideIndex) {
      if (this.profile && this.profile.role === 'User') {
        if (this.form.correctionType === 'realtime') {
          return (this.formResponse.slidesValidate && this.formResponse.slidesValidate.includes(slideIndex))
        } else {
          if (this.form.endDate === null && this.formResponse.isSave === true) {
            return true
          } else if (this.form.endDate !== null && new Date().getTime() > this.form.endDate) {
            return true
          }
        }
      }
      return false
    }
  }
}
</script>

<style scoped lang=scss>
.questionSlide-container {
  position: relative;
  height: 100%;
  margin: 0 auto;
  padding: 16px 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  .buttons {
    position: absolute;
    bottom: 64px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
  }
}

// question
.question-container {
  position: relative;
  max-width: 500px;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 12px 8px;

  .more-question {
    width: 95%;
    margin: auto;
  }
}

// media
div:has(> :is(video, audio, img)) {
  width: max-content;
  height: max-content;
  max-width: 50%;
  max-height: 332px;
}
video, audio, img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.more-info-buttons {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  pointer-events: none;
  & * {
    pointer-events: all;
  }
}

.realtime-correction-btn {
  position: absolute;
  bottom: 8px;
  right: 8px;
}
</style>