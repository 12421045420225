<template>
  <div>
    <p class="mb-1 text-caption success--text" v-if="profile.role !== 'User'">type : {{ question.type }}</p>
    <v-card :outlined="!preview" elevation="0" class="card" :class="preview ? '' : 'pt-0 px-3'"
      @mouseover="addToRightClick" @mouseleave="delToRightClick" @click="" v-ripple="preview && !['slider', 'numericSlider'].includes(question.type)"
    >
      <h4 v-text="question.text" v-if="preview" class="mb-1"></h4>
      <v-text-field v-else placeholder="Énoncé" v-model="question.text" hide-details class="mx-0 pa-0" :class="['free', 'rating'].includes(question.type) ? '' : 'mb-3'" />

      <Free           v-if="question.type === 'free'"                :showCorrection="showCorrection" :disabled="disabled" :slideIndex="slideIndex" :questionIndex="questionIndex" :profile="profile" :formResponse="formResponse" :preview="preview" />
      <Number         v-else-if="question.type === 'number'"         :showCorrection="showCorrection" :disabled="disabled" :slideIndex="slideIndex" :questionIndex="questionIndex" :profile="profile" :formResponse="formResponse" :preview="preview" :question="question" />
      <Expression     v-else-if="question.type === 'expression'"     :showCorrection="showCorrection" :disabled="disabled" :slideIndex="slideIndex" :questionIndex="questionIndex" :profile="profile" :formResponse="formResponse" :preview="preview" :question="question" />
      <Boolean        v-else-if="question.type === 'boolean'"        :showCorrection="showCorrection" :disabled="disabled" :slideIndex="slideIndex" :questionIndex="questionIndex" :profile="profile" :formResponse="formResponse" :preview="preview" :question="question" />
      <FillTheBlank   v-else-if="question.type === 'fillTheBlank'"   :showCorrection="showCorrection" :disabled="disabled" :slideIndex="slideIndex" :questionIndex="questionIndex" :profile="profile" :formResponse="formResponse" :preview="preview" :question="question" :rightClickItems="rightClickItems" />
      <UniqueChoice   v-else-if="question.type === 'uniqueChoice'"   :showCorrection="showCorrection" :disabled="disabled" :slideIndex="slideIndex" :questionIndex="questionIndex" :profile="profile" :formResponse="formResponse" :preview="preview" :question="question" :rightClickItems="rightClickItems" />
      <MultipleChoice v-else-if="question.type === 'multipleChoice'" :showCorrection="showCorrection" :disabled="disabled" :slideIndex="slideIndex" :questionIndex="questionIndex" :profile="profile" :formResponse="formResponse" :preview="preview" :question="question" :rightClickItems="rightClickItems" />
      <List           v-else-if="question.type === 'list'"           :showCorrection="showCorrection" :disabled="disabled" :slideIndex="slideIndex" :questionIndex="questionIndex" :profile="profile" :formResponse="formResponse" :preview="preview" :question="question" :rightClickItems="rightClickItems" />
      <Slider         v-else-if="question.type === 'slider'"         :showCorrection="showCorrection" :disabled="disabled" :slideIndex="slideIndex" :questionIndex="questionIndex" :profile="profile" :formResponse="formResponse" :preview="preview" :question="question" :rightClickItems="rightClickItems" />
      <NumerciSlider  v-else-if="question.type === 'numericSlider'"  :showCorrection="showCorrection" :disabled="disabled" :slideIndex="slideIndex" :questionIndex="questionIndex" :profile="profile" :formResponse="formResponse" :preview="preview" :question="question" :rightClickItems="rightClickItems" />
      <Rating         v-else-if="question.type === 'rating'"         :showCorrection="showCorrection" :disabled="disabled" :slideIndex="slideIndex" :questionIndex="questionIndex" :profile="profile" :formResponse="formResponse" :preview="preview" :question="question" />
      <Dependent      v-else-if="question.type === 'dependent'"      :showCorrection="showCorrection" :disabled="disabled" :slideIndex="slideIndex" :questionIndex="questionIndex" :profile="profile" :formResponse="formResponse" :preview="preview" :question="question" :rightClickItems="rightClickItems" :slide="slide" />
    </v-card>

    <div class="note" v-if="!preview && !['free', 'rating'].includes(question.type)">
      /<span><v-text-field dense type="number" hide-details class="ma-0" @blur="testNote" v-model="question.points" /></span>
    </div>

    <v-dialog width="fit-content" v-model="newVarModal">
      <v-card width="fit-content">
        <v-card-title class="justify-center">Nouvelle variable</v-card-title>
        <v-card-text class="justify-center">
          <v-text-field outlined hide-details v-model="newVarValue" />
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="error" @click="closeNewVarModal">Annuler</v-btn>
          <v-btn color="primary" @click="addNewVar">Valider</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Free from '@/components/SlideForm/Questions/Free.vue'
import Number from '@/components/SlideForm/Questions/Number.vue'
import Expression from '@/components/SlideForm/Questions/Expression.vue'
import Boolean from '@/components/SlideForm/Questions/Boolean.vue'
import FillTheBlank from '@/components/SlideForm/Questions/FillTheBlank.vue'
import UniqueChoice from '@/components/SlideForm/Questions/UniqueChoice.vue'
import MultipleChoice from '@/components/SlideForm/Questions/MultipleChoice.vue'
import List from '@/components/SlideForm/Questions/List.vue'
import Slider from '@/components/SlideForm/Questions/Slider.vue'
import NumerciSlider from '@/components/SlideForm/Questions/NumerciSlider.vue'
import Rating from '@/components/SlideForm/Questions/Rating.vue'
import Dependent from '@/components/SlideForm/Questions/Dependent.vue'

import testNote from '@/assets/functions/corrections/testNote'

import Swal from 'sweetalert2/dist/sweetalert2.js'

export default {
  props: ['slide', 'slideIndex', 'questionIndex', 'question', 'preview', 'rightClickItems', 'profile', 'formResponse', 'disabled', 'showCorrection', 'editData'],
  components: { Free, Number, Expression, Boolean, FillTheBlank, UniqueChoice, MultipleChoice, List, Slider, NumerciSlider, Rating, Dependent },
  data() {
    return {
      newVarModal: false,
      newVarValue: null,
      isCorrect: false
    }
  },
  created() {
    if (this.formResponse && !this.formResponse.responses[this.slideIndex]) {
      this.formResponse.responses[this.slideIndex] = {}
    }
  },
  methods: {
    addToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[7] = 'divider'
        this.rightClickItems[8] = {
          text: 'Supprimer la question',
          method: () => {
            this.editData.isEdited = true
            this.editData.isCriticEdited = true

            let tmp_questions = JSON.parse(JSON.stringify(this.slide.content.questions))
            tmp_questions.splice(this.questionIndex, 1)
            if (tmp_questions.length === 0){
              this.slide.content = {}
            }else{
              this.slide.content.questions = tmp_questions
            }

            this.$forceUpdate()
          }
        }

        const nb_dependent = this.slide.content.questions.filter(x => x.type === 'dependent').length
        if (nb_dependent > 0) {
          this.rightClickItems[9] = {
            text: 'Créer une variable',
            method: this.openVarModal
          }
          if ([null, undefined].includes(this.question.goodResponse) || (this.question.type !== 'dependent' && !parseFloat(this.question.goodResponse))) {
            this.rightClickItems[9].disabled = true
          }
        }
      }
    },
    delToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[7] = null
        this.rightClickItems[8] = null
        this.rightClickItems[9] = null
      }
    },

    openVarModal() {
      if (this.question.var) {
        Swal.fire({
          text: 'Cette question posède déjà une variable.',
          icon: 'info',
          confirmButtonText: 'Ok',
        })
      } else {
        this.newVarModal = true
      }
    },
    closeNewVarModal() {
        this.newVarModal = false
        this.newVarValue = null
    },
    addNewVar() {
      this.newVarValue = this.newVarValue.replaceAll('[', '').replaceAll(']', '')

      if (this.slide.content.questions.filter(x => x.var).map(x => x.var).includes(this.newVarValue)) {
        Swal.fire({
          title: 'Nom de variable déjà utilisé',
          icon: 'error',
          confirmButtonText: 'Ok'
        }).then(this.openVarModal)
      } else {
        this.question.var = this.newVarValue
        Swal.fire({
          title: 'Variable ajoutée',
          icon: 'success',
          confirmButtonText: 'Ok'
        })
        this.closeNewVarModal()
      }
    },

    testNote() {
      this.question.points = testNote(this.question.points)
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  transition: 250ms;
  padding: 8px 8px 12px 8px;
  cursor: default;
  &:not(:has(.card:hover)):hover {
    border-color: var(--v-primary-base);
  }
  &:focus, &:has(:not(.card):focus) {
    border-color: var(--v-primary-base);
  }
  &::before {
    opacity: 0;
  }
}

div:has(>.note) {
  position: relative;
}
.note {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  > span {
    margin: 0;
    padding-left: 6px;
    width: 60px;
  }
}
</style>