<template>
  <v-card-text class="container" @mouseover="addToRightClick" @mouseleave="delToRightClick">
    <!-- title -->
    <h1 class="slide-title" :style="!preview ? 'width:100%' : ''">
      <v-textarea v-model="slide.title" v-if="slide.title !== undefined && !preview" placeholder="Titre" rows="1" auto-grow hide-details class="mt-0 pt-0 textarea" />
      <pre v-else-if="slide.title">{{ slide.title }}</pre>
    </h1>

    <!-- content -->
    <div v-if="slide.content.layout !== 'empty'" class="content-container" :class="slide.content.layout === 'text-right' ? 'flex-row-reverse' : ''">
      <!-- text -->
      <v-textarea style="width: 50%;" v-if="slide.content.text !== undefined && !preview" v-model="slide.content.text" rows="1" auto-grow hide-details class="mt-0 pt-0" placeholder="texte"></v-textarea>
      <pre style="width: 50%; white-space: normal; word-break: break-word;" class="text-h6 font-weight-regular" v-else-if="slide.content.text && preview">{{ slide.content.text }}</pre>

      <!-- video -->
      <video style="width: 50%;" v-if="slide.content.media && slide.content.media.type === 'video'" controls preload="auto" :src="slide.content.media.ref"></video>
      <!-- audio -->
      <audio style="width: 50%;" v-else-if="slide.content.media && slide.content.media.type === 'audio'" controls preload="auto" :src="slide.content.media.ref"></audio>
      <!-- picture -->
      <img style="width: 50%;" v-else-if="slide.content.media && slide.content.media.type === 'picture'" :src="slide.content.media.ref">
    </div>

    <input type="file" :accept="acceptedExt" class="d-none" ref="fileInput" @change="addMedia">
  </v-card-text>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { audioExt, videoExt, pictureExt } from '@/assets/config/ExtWhiteList'

export default {
  props: ['form', 'slide', 'preview', 'rightClickItems'],
  data() {
    return {
      textPosition: null,
      acceptedExt: ''
    }
  },
  mounted() {
    this.acceptedExt = audioExt.concat(videoExt.concat(pictureExt)).map(x=>`.${x}`).join(', ')
  },
  methods: {
    addToRightClick() {
      if (this.rightClickItems) {
        // title
        if (this.slide.title === undefined) {
          this.rightClickItems[3] = {
            text: 'Ajouter un titre',
            method: () => {
              this.slide.title = ''
              this.addToRightClick()
              this.$forceUpdate()
            }
          }
        } else {
          this.rightClickItems[3] = {
            text: 'Supprimer le titre',
            method: () => {
              delete this.slide.title
              this.addToRightClick()
              this.$forceUpdate()
            }
          }
        }
        // text
        if (this.slide.content.text === undefined) {
          if (this.slide.content.media === undefined) {
            this.rightClickItems[4] = {
              text: 'Ajouter un texte',
              method: () => {
                this.addText()
                this.addToRightClick()
                this.$forceUpdate()
              }
            }
            this.rightClickItems[5] = null
          } else {
            this.rightClickItems[4] = {
              text: 'Ajouter un texte à gauche',
              method: () => {
                this.addText('left')
                this.addToRightClick()
                this.$forceUpdate()
              }
            }
            this.rightClickItems[5] = {
              text: 'Ajouter un texte à droite',
              method: () => {
                this.addText('right')
                this.addToRightClick()
                this.$forceUpdate()
              }
            }
          }
        } else {
          this.rightClickItems[4] = {
            text: 'Supprimer le texte',
            method: () => {
              this.delText()
              this.addToRightClick()
              this.$forceUpdate()
            }
          }
          this.rightClickItems[5] = null
        }
        // media
        if (this.slide.content.media === undefined) {
          if (this.slide.content.text === undefined) {
            this.rightClickItems[6] = {
              text: 'Ajouter un média',
              method: () => {
                this.$refs.fileInput.click()
                this.textPosition = null
                this.addToRightClick()
                this.$forceUpdate()
              }
            }
            this.rightClickItems[7] = null
          } else {
            this.rightClickItems[6] = {
              text: 'Ajouter un média à gauche',
              method: () => {
                this.$refs.fileInput.click()
                this.textPosition = 'right'
                this.addToRightClick()
                this.$forceUpdate()
              }
            }
            this.rightClickItems[7] = {
              text: 'Ajouter un média à droite',
              method: () => {
                this.$refs.fileInput.click()
                this.textPosition = 'left'
                this.addToRightClick()
                this.$forceUpdate()
              }
            }
          }
        } else {
          this.rightClickItems[6] = {
            text: 'Supprimer le média',
            method: () => {
              this.delMedia()
              this.addToRightClick()
              this.$forceUpdate()
            }
          }
          this.rightClickItems[7] = null
        }
      }
    },
    delToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[3] = null
        this.rightClickItems[4] = null
        this.rightClickItems[5] = null
        this.rightClickItems[6] = null
        this.rightClickItems[7] = null
      }
    },

    addText(textPosition = null) {
      if (this.slide.content.layout === 'empty')
        this.slide.content.layout = 'text'
      else if (this.slide.content.layout === 'media')
        this.slide.content.layout = 'text-' + textPosition
      
      this.slide.content.text = ''
      this.$forceUpdate()
    },
    delText() {
      if (this.slide.content.layout === 'text')
        this.slide.content.layout = 'empty'
      else
        this.slide.content.layout = 'media'
      
      delete this.slide.content.text
      this.$forceUpdate()
    },
    addMedia(event) {
      // get file
      const file = event.target.files[0]
      if (!file) return

      // check ext
      const ext = file.name.toLowerCase().split('.').pop()
      let type = null
      if (audioExt.includes(ext)) type = 'audio'
      else if (videoExt.includes(ext)) type = 'video'
      else if (pictureExt.includes(ext)) type = 'picture'
      if (type === null) {
        Swal.fire({
          title: 'Erreur',
          text: 'Type de fichier invalide.',
          icon: 'error',
          confirmButtonText: 'Ok',
        })
        return
      }

      // change content layout
      if (this.slide.content.layout === 'empty')
        this.slide.content.layout = 'media'
      else {
        this.slide.content.layout = 'text-' + this.textPosition
        this.textPosition = null
      }
      
      // create object
      this.slide.content.media = {
        name: file.name,
        ref: URL.createObjectURL(file),
        file: file,
        type: type,
        isUpdated: true
      }
      this.$forceUpdate()
    },
    delMedia() {
      if (this.slide.content.layout === 'media')
        this.slide.content.layout = 'empty'
      else
        this.slide.content.layout = 'text'
      
      if (this.slide.content.media.path !== undefined) {
        if (this.form.deletedFiles === undefined) this.form.deletedFiles = []
        this.form.deletedFiles.push(this.slide.content.media.path)
      }

      delete this.slide.content.media
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  height: 100%;
  margin: 0 auto;
  padding: 16px 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

// title
.slide-title {
  // width: 100%;
  pre {
    font-family: inherit;
  }
}

// content
div:has(>.content-container) {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .content-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
}

video, audio, img {
  object-fit: cover;
  max-width: 100%;
  max-height: 364px;
}
</style>