<template>
  <!-- correction -->
  <div class="classification-container correction" v-if="showCorrection">

    <div class="d-flex gap-xl">
      <div>
        <h3 class="ma-0 pa-0">{{ question.text }}</h3>
        <div class="d-flex flex-wrap gap-s my-8">
          <v-card v-for="(category, y) of formResponse.responses[slideIndex]" :key="y" outlined class="category" :class="catCorrect[y] ? 'card-success' : 'card-error'">
            <h2>{{ category.title }}</h2>
            <v-divider class="ma-0" />
            <div class="draggable">
              <v-card v-for="(item, i) in category.choices" :key="i" outlined class="item card" :class="itemsCorrect[y] && itemsCorrect[y][i] ? 'card-success' : 'card-error'">
                {{ item }}
              </v-card>
            </div>
          </v-card>
        </div>
      </div>

      <v-divider vertical v-if="!allCorrect" />

      <div v-if="!allCorrect">
        correction :
        <div class="d-flex flex-wrap gap-s my-8">
          <v-card v-for="(category, y) of question.goodResponse" :key="y" outlined class="category card-info">
            <h2>{{ category.title }}</h2>
            <v-divider class="ma-0" />
            <div class="draggable">
              <v-card v-for="(item, i) in category.choices" :key="i" outlined class="item card card-info">
                {{ item }}
              </v-card>
            </div>
          </v-card>
        </div>
      </div>
    </div>
  </div>

  <!-- user -->
  <div class="classification-container" v-else-if="profile && profile.role === 'User'" @click="formResponse.lastUpdateDate = new Date().getTime()">
    <h3 class="ma-0 pa-0">{{ question.text }}</h3>

    <v-card outlined class="mb-3">
      <draggable v-model="choices" group="previewItem" class="draggable" :disabled="disabled">
        <v-card v-for="(item, i) in choices" :key="i" outlined class="item card" :class="disabled ? 'disabled' : ''"
          v-text="item" @click=""
        />
      </draggable>
    </v-card>
    <div class="d-flex flex-wrap gap-s">
      <v-card v-for="(category, y) of formResponse.responses[slideIndex]" :key="y" outlined class="category">
        <h2>{{ category.title }}</h2>
        <v-divider class="ma-0" />
        <draggable v-model="category.choices" group="previewItem" class="draggable" :disabled="disabled">
          <v-card v-for="(item, i) in category.choices" :key="i" outlined class="item card" :class="disabled ? 'disabled' : ''"
            v-text="item" @click=""
          />
        </draggable>
      </v-card>
    </div>
  </div>

  <!-- admin -->
  <div class="classification-container" v-else
    @mouseover="addToRightClick" @mouseleave="delToRightClick"
  >
    <!-- title -->
    <h3 v-if="preview" class="ma-0 pa-0">{{ question.text }}</h3>
    <span v-else class="noteContainer">
      <v-text-field v-model="question.text" hide-details class="ma-0 pa-0" placeholder="Énoncé" />
      <div class="note">
        /<span><v-text-field dense type="number" hide-details class="ma-0" @blur="testNote" v-model="question.points" /></span>
      </div>
    </span>

    <!-- if (preview) -->
    <v-card outlined v-if="preview" class="mb-3">
      <draggable v-model="previewChoice" group="previewItem" class="draggable">
        <v-card v-for="(item, i) in previewChoice" :key="i" outlined class="item card"
          v-text="item" @click=""
        />
      </draggable>
    </v-card>
    <div class="d-flex flex-wrap gap-s" v-if="preview">
      <v-card v-for="(category, y) of previewGoodResponse" :key="y" outlined class="category">
        <h2>{{ category.title }}</h2>
        <v-divider class="ma-0" />
        <draggable v-model="category.choices" group="previewItem" class="draggable">
          <v-card v-for="(item, i) in category.choices" :key="i" outlined class="item card"
            v-text="item" @click=""
          />
        </draggable>
      </v-card>
    </div>

    <!-- else -->
    <draggable v-else v-model="question.goodResponse" group="category" class="draggable">
      <v-card v-for="(category, y) of question.goodResponse" :key="y" outlined class="category card"
        @mouseover="addCategoryToRightClick(y)" @mouseleave="delCategoryToRightClick" @click="" v-ripple="preview"
      >
        <v-text-field v-model="category.title" hide-details class="ma-0 h2" placeholder="Nom de la catégorie" />
        <v-divider class="ma-0" />
        <draggable v-model="category.choices" group="item" class="draggable">
          <v-card v-for="(item, i) in category.choices" :key="i" outlined class="item card"
            @mouseover="addItemToRightClick(i, y)" @mouseleave="delItemToRightClick"
            v-text="item" @click="" v-ripple="preview"
            @focus="enabledContentEditable"
            @blur="disabledContentEditable($event); asignValue($event, i, y)"
          />
        </draggable>
      </v-card>
    </draggable>
  </div>
</template>

<script>
import Correction from '@/assets/functions/corrections'
import testNote from '@/assets/functions/corrections/testNote'
import draggable from 'vuedraggable'

export default {
  props: ['question', 'preview', 'rightClickItems', 'profile', 'formResponse', 'slideIndex', 'disabled', 'showCorrection'],
  components: { draggable },
  data() {
    return {
      choices: [],
      previewChoice: [],
      previewGoodResponse: [],
      itemsCorrect: [],
      catCorrect: [],
      allCorrect: false
    }
  },
  watch: {
    preview() {
      this.switchPreviewValue()
    },
    showCorrection() {
      this.runCorrection()
    }
  },
  created() {
    if (this.profile && this.profile.role === 'User') {
      if (!this.formResponse.responses[this.slideIndex]) {
        this.choices = []
        for (const goodResponse of this.question.goodResponse) {
          this.choices = this.choices.concat(goodResponse.choices).sort((a, b) => 0.5 - Math.random())
        }
        this.formResponse.responses[this.slideIndex] = this.question.goodResponse.map(cat => {
          return {
            title: cat.title,
            choices: []
          }
        })
      } else {
        // get the choice not already place
        this.choices = []
        let alreadyPlace = []
        // /!\ not working if 2 choices have the same value
        for (const category of this.formResponse.responses[this.slideIndex]) {
          alreadyPlace = alreadyPlace.concat(category.choices)
        }
        for (const goodResponse of this.question.goodResponse) {
          this.choices = this.choices.concat(goodResponse.choices).sort((a, b) => 0.5 - Math.random()).filter(x=>!alreadyPlace.includes(x))
        }
      }
    } else {
      this.switchPreviewValue()
    }
  },
  mounted() {
    if (this.showCorrection) {
      this.runCorrection()
    }
  },
  methods: {
    addToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[10] = {
          text: 'Ajouter une catégorie',
          method: () => {
            this.question.goodResponse.push({
              title: '',
              choices: ['']
            })
            this.$forceUpdate()
          }
        }
      }
    },
    delToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[10] = null
      }
    },
    addCategoryToRightClick(y) {
      if (this.rightClickItems) {
        this.rightClickItems[11] = {
          text: 'Supprimer la catégorie',
          method: () => {
            this.question.goodResponse.splice(y,1)
            this.$forceUpdate()
          }
        }
        this.rightClickItems[12] = 'divider'
        this.rightClickItems[13] = {
          text: 'Ajouter une réponse',
          method: () => {
            this.question.goodResponse[y].choices.push('')
            this.$forceUpdate()
          }
        }
      }
    },
    delCategoryToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[11] = null
        this.rightClickItems[12] = null
        this.rightClickItems[13] = null
      }
    },
    addItemToRightClick(i, y) {
      if (this.rightClickItems) {
        this.rightClickItems[14] = {
          text: 'Supprimer la réponse',
          method: () => {
            this.question.goodResponse[y].choices.splice(i,1)
            this.$forceUpdate()
          }
        }
      }
    },
    delItemToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[14] = null
      }
    },

    asignValue(event, i, y) {
      this.question.goodResponse[y].choices[i] = event.target.innerText
      if (event.target.innerText.trim() === '')
        this.question.goodResponse[y].choices.splice(i, 1)
    },
    enabledContentEditable(event) {
      if (!this.preview)
        event.target.contentEditable = true
    },
    disabledContentEditable(event) {
      event.target.contentEditable = false
    },

    switchPreviewValue() {
      if (this.preview) {
        this.previewChoice = []
        for (const goodResponse of this.question.goodResponse) {
          this.previewChoice = this.previewChoice.concat(goodResponse.choices).sort((a, b) => 0.5 - Math.random())
        }
        this.previewGoodResponse = this.question.goodResponse.map(cat => {
          return {
            title: cat.title,
            choices: []
          }
        })
      }
    },

    runCorrection() {
      const isCorrect = Correction('classification', this.formResponse.responses[this.slideIndex], this.question.goodResponse)
      this.itemsCorrect = isCorrect.items
      this.catCorrect = isCorrect.cat
      this.allCorrect = this.itemsCorrect.every(x => x.every(y => y === true)) && this.catCorrect.every(x=>x===true)
      this.$forceUpdate()
    },

    testNote() {
      this.question.points = testNote(this.question.points)
    }
  }
}
</script>

<style scoped lang="scss">
.classification-container {
  padding: 16px 52px;
  height: 100%;
  display: grid;
  place-content: center;
}

.noteContainer {
  display: grid;
  grid-template-columns: auto 100px;
  .note {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    > span {
      margin: 0;
      padding-left: 6px;
      width: 60px;
    }
  }
}

.card {
  transition: 250ms;

  &.disabled {
    color: var(--v-textContrast-base)!important;
    border-color: var(--v-textContrast-base)!important;
  }
  &:not(.disabled) {
    &:not(:has(.card:hover)):hover {
      border-color: var(--v-primary-base);
    }
    &:focus, &:has(:not(.card):focus) {
      border-color: var(--v-primary-base);
    }
  }
  &::before {
    opacity: 0;
  }
}

.draggable {
  max-width: 750px;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding: 12px;

  > .category { cursor: grab;}

  .item {
    min-width: 32px;
    padding: 8px;
    &:not(:focus) { cursor: grab; }
    &:focus { cursor: text; }
  }
}

.category {
  display: flex;
  flex-direction: column;
  max-width: 350px;

  h2, .h2 {
    padding: 8px;
  }
}

.correction {
  pointer-events: none;
} .card-info {
  border-color: var(--v-info-base);
  color: var(--v-info-base)!important;
} .card-success {
  border-color: var(--v-success-base);
  color: var(--v-success-base)!important;
} .card-error {
  border-color: var(--v-error-base);
  color: var(--v-error-base)!important;
}
</style>