import envManager from '@/assets/functions/envManager'
import { ListenWhere, GetWhere } from '@/assets/functions/getData'
import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from 'firebase/firestore'

const db = getFirestore()
const collectionName = envManager.getPrefix() + 'slideForms'
const collectionRef = collection(db, collectionName)

/**
 * Convert the data received by Firebase into usable data for the application.
 * @param {string} id The id of the document
 * @param {object} data An Object containing all fields in the document.
 * @returns An object that can be used in the application.
 */
const dataToObject = (id, data) => {
  return new SlideForm(id, data.name, data.room, data.correctionType, data.editableResponses, data.endDate, data.pointsConvertion, data.slides, data.showCorrection)
}

class SlideForm {
  constructor(id, name, room, correctionType, editableResponses, endDate, pointsConvertion, slides, showCorrection = false) {
    this.id = id
    this.name = name
    this.room = room
    this.correctionType = correctionType
    this.editableResponses = editableResponses
    this.endDate = endDate
    this.pointsConvertion = pointsConvertion
    this.slides = slides
    this.showCorrection = showCorrection
  }
  
  // get
  static async getANewId() {
    const ref = doc(collectionRef)
    return ref.id
  }
  static async getById(id) {
    const documentRef = doc(db, collectionName, id)
    const response = await getDoc(documentRef)

    return response.data() ? dataToObject(id, response.data()) : null
  }
  static async getByRoom(room) {
    const documentsQuery = query(collectionRef, where('room', '==', room))
    return await GetWhere(documentsQuery, dataToObject)
  }
  
  // listen
  static async listenByRoom(room, callback) {
    const documentsQuery = query(collectionRef, where('room', '==', room))
    return ListenWhere(documentsQuery, dataToObject, callback)
  }

  async save() {
    const savedObject = {
      name: this.name,
      room: this.room,
      correctionType: this.correctionType,
      editableResponses: this.editableResponses,
      endDate: this.endDate,
      pointsConvertion: this.pointsConvertion,
      slides: this.slides,
      showCorrection: this.showCorrection
    }

    if (this.isNewForm === true) {
      delete this.isNewForm
      await setDoc(doc(db, collectionName, this.id), savedObject)
    } else {
      await updateDoc(doc(db, collectionName, this.id), savedObject)
    }
  }

  delete() {
    const documentRef = doc(db, collectionName, this.id)
    deleteDoc(documentRef)
  }
}

export default SlideForm