<template>
  <div v-if="!editable">
    <span v-for="(slide, i) of form.slides" :key="i">
      <v-badge v-if="(form.slides[i].layout === 'context' || (formResponse && formResponse.slidesValidate && formResponse.slidesValidate.includes(i)))"
        avatar overlap bordered
        :color="form.slides[i].layout === 'context' ? 'primary' : 'success'"
        offset-x="14"
        offset-y="14"
      >
        <template #badge>
          <v-icon size="16" style="margin-top: 2px;">
            {{ form.slides[i].layout === 'context' ? 'mdi-information-variant' : 'mdi-check' }}
          </v-icon>
        </template>
        <v-btn fab small @click="$emit('change', i)"
          :color="actualSlide === i ? 'primary' : ''">
          {{ i + 1 }}
        </v-btn>
      </v-badge>
      <v-btn v-else
        fab small @click="$emit('change', i)"
        :color="actualSlide === i ? 'primary' : ''">
        {{ i + 1 }}
      </v-btn>
    </span>
  </div>
  <draggable v-model="form.slides" @change="updateSlideSelected" v-else>
    <v-btn v-for="(slide, i) of form.slides" :key="i" fab small @click="$emit('change', i)"
      :color="actualSlide === i ? 'primary' : ''" @mouseover="addToRightClick(i)" @mouseleave="delToRightClick" @end="editData.isEdited=true; editData.isCriticEdited=true">
      {{ i + 1 }}
    </v-btn>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  props: ['form', 'actualSlide', 'rightClickItems', 'editable', 'formResponse', 'editData'],
  components: { draggable },
  methods: {
    addToRightClick(i) {
      if (this.rightClickItems) {
        this.rightClickItems[1] = {
          text: `Supprimer la slide n° ${i + 1}`,
          method: () => this.$emit('del', i)
        }
      }
    },
    delToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[1] = null
      }
    },
    updateSlideSelected(event) {
      if (event.moved.oldIndex === this.actualSlide) {
        this.$emit('change', event.moved.newIndex)
      }
    }
  }
}
</script>

<style scoped>
div {
  padding: 8px;
  display: flex;
  gap: 8px;
  overflow-x: auto;
  margin: auto;
  width: fit-content;
  max-width: 100%;
}
</style>