<template>
    <div class="mt-2">
        <v-row class="ml-1 mt-2 d-flex align-center" v-if="profile.role != 'User'">
            <v-select :items="sortChoices" v-model="sortSelected" label="Trier par :"></v-select>
        </v-row>
        <v-expansion-panels accordion>
            <v-expansion-panel v-for="mail, index in mails" v-if="mail.sender">
                <!-- sort by receiver -->
                <div v-if="sortSelected === 'Récepteur' && (index === 0 || mails[index-1].receiver.id != mail.receiver.id)" class="d-flex align-center" :class="index != 0 ? 'mt-12' : 'mt-3'">
                    <UserCard :profile="mail.receiver"></UserCard>
                    <v-divider class="ml-3"></v-divider>
                </div>

                <v-expansion-panel-header @click="deleteNotif(mail.id)">
                    <div class="d-flex flex-wrap align-center px-3">
                        <div class="d-flex flex-column">
                            <UserCard :profile="mail.receiver" :subject="mail.subject" v-if="profile.role === 'User'" :notif="checkNotif(mail.id)"></UserCard>
                            <div v-else>
                                <div class="d-flex align-center">
                                    <UserCard :profile="mail.sender" :compact="true"></UserCard>
                                    <v-icon class="mx-3">mdi-send-circle-outline</v-icon>
                                    <UserCard :profile="mail.receiver" :compact="true"></UserCard>
                                </div>
                                <div class="my-2" v-text="`sujet : ${mail.subject}`"></div>
                            </div>
                            <div class="d-flex flex-wrap">
                                <!-- attachment -->
                                <v-chip class="mx-1 my-2" color="primary" outlined style="cursor: pointer" v-for="attachment in mail.attachments" @click.prevent.stop="openFile(attachment)">
                                    <v-icon small left>mdi-file-outline</v-icon>
                                    <span v-text="attachment.name"></span>
                                </v-chip>
                            </div>
                        </div>

                        <v-spacer></v-spacer>
                        <b v-text="new Date(mail.date).toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris' }) + ' - ' + new Date(mail.date).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' }).slice(0,5)"></b>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <!-- message -->
                    <p class="mailTextForceFormating" v-html="mail.message" style="white-space: pre-wrap;"></p>
                    
                    <!-- responses -->
                    <v-card v-for="response in mail.responses" outlined class="pa-3 ml-12 my-3">
                        <div class="d-flex align-center flex-wrap">
                            <UserCard :profile="response.sender" :subject="mail.subject" :isResponse="true"></UserCard>
                            <v-spacer></v-spacer>
                            <b v-text="new Date(response.date).toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris' }) + ' - ' + new Date(response.date).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' }).slice(0,5)"></b>
                        </div>
                        <div class="d-flex flex-wrap">
                            <!-- attachment -->
                            <v-chip class="mx-1 mt-2" color="primary" outlined v-for="attachment in response.attachments" @click.prevent.stop="openFile(attachment)">
                                <v-icon small left>mdi-file-outline</v-icon>
                                <span v-text="attachment.name"></span>
                            </v-chip>
                        </div>

                        <v-divider class="my-3"></v-divider>
                        <!-- message -->
                        <p class="mailTextForceFormating" v-html="response.message" style="white-space: pre-wrap;"></p>
                    </v-card>

                    <!-- btn -->
                    <span class="d-flex mt-3" v-if="profile.role != 'User'">
                        <v-spacer></v-spacer>
                        <v-btn outlined small color="primary" class="px-8 caption" @click="responseData.modal=true; responseData.mail=mail; responseData.sender=mail.receiver;">
                            Répondre
                        </v-btn>
                    </span>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-col cols="12" class="pa-3 d-flex justify-end">
          <v-btn class="mr-5" color="success" fab @click.prevent.stop="exportAllMail()">
            <v-icon>mdi-download</v-icon>
          </v-btn>
            <v-btn color="primary" fab @click="$refs.sendMailModal.open()">
                <v-icon>mdi-send</v-icon>
            </v-btn>
        </v-col>
        <v-col cols="12" class="mt-5 pa-3 d-flex justify-end">
        </v-col>
        <SendMailModal ref="sendMailModal" :profile="profile"></SendMailModal>

        <v-dialog v-model="responseData.modal" max-width="900px" persistent>
            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="4" class="d-flex flex-column justify-center">
                            <h2>Émetteur :</h2>
                            <div v-if="profile.role != 'User'">
                                <v-btn outlined @click="$refs.dialogGetSender.open()" class="text-left pa-3" style="width:100%;height:unset!important;text-transform:unset">
                                    <UserCard v-if="responseData.sender" :profile="responseData.sender" :mailAndJob="true"></UserCard>
                                </v-btn>
                                <DialogNewConversation with="contacts" :blacklist="[]" @convSelected="(user) => this.responseData.sender = user" ref="dialogGetSender" :profile="profile"></DialogNewConversation>
                            </div>
                        </v-col>
                        <v-col cols="12" md="8">
                            <v-textarea v-model="responseData.message" outlined></v-textarea>
                            <!-- <VueEditor class="mb-2" v-model="responseData.message" :editor-toolbar="toolbar"></VueEditor> -->
                            <v-file-input chips multiple truncate-length="30" label="Pièces jointes" v-model="responseData.attachments"></v-file-input>
                            <v-row>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close">Annuler</v-btn>
                                <v-btn color="blue darken-1" text @click="save">Envoyer</v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="loader" max-width="600" persistent>
            <v-card>
              <v-card-text class="pt-5">
                <div><h1 class="pb-1 text-center">Exportation en cours</h1></div>
                <div><h2 class="pb-1 text-center">{{ loadingState + "%" }}</h2></div>
                <v-progress-linear :value="loadingState" color="primary" rounded height="20"></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
    </div>
</template>

<script>
import JSZip from "jszip"
import downloader from "@/assets/functions/downloader"

import logger from "@/assets/functions/logger"
import notifier from "@/assets/functions/notifier"
import { getStorage, ref, getDownloadURL,getBlob, uploadBytes } from "firebase/storage"
import { VueEditor } from "vue2-editor"
import Swal from 'sweetalert2/dist/sweetalert2.js'

import Mail from '@/classes/Mail'
import MailResponse from '@/classes/MailResponse'
import Contact from '@/classes/Contact'

import UserCard from '@/components/UserCard'
import SendMailModal from "@/components/Email/SendMailModal"
import DialogNewConversation from "@/components/Communication/DialogNewConversation"

export default {
    props: ['profile', 'notifications', 'contactsTable'],
    components: { SendMailModal, UserCard, DialogNewConversation, VueEditor },
    setup() {
        return {
            storage: getStorage()
        }
    },
    data: () => ({
		apiKey: "sc3e4lfj6m905te45946p91m7zwu3ynqm3l3fl62iknv1asv",
        mails: [],
        sortChoices: [
            'Date',
            'Émeteur',
            'Récepteur'
        ],
        sortSelected: 'Date',
        responseData: {
            modal: false,
            mail: null,
            sender: null,
            message: '',
            attachment: []
        },
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote'],

            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],

            [{ 'header': [1, 2, 3, false] }],

            [{ 'align': [] }],
        ],
        unsub: [],
        responseUnsub: [],
        loader: false,
        loadingState: 0
    }),
    watch: {
        sortSelected(newChoice, oldChoice) {
            switch (newChoice) {
                case 'Date':
                    this.mails.sort((a,b)=>{
                        return b.date - a.date
                    })
                    break
                case 'Émeteur':
                    this.mails.sort((a,b)=>{
                        if (a.sender.lastname > b.sender.lastname)
                            return 1
                        else if (b.sender.lastname > a.sender.lastname)
                            return -1
                        else if (a.sender.firstname > b.sender.firstname)
                            return 1
                        else if (b.sender.firstname > a.sender.firstname)
                            return -1
                        else
                            return b.date - a.date
                    })
                    break
                case 'Récepteur':
                    this.mails.sort((a,b)=>{
                        if (a.receiver.lastname > b.receiver.lastname)
                            return 1
                        else if (b.receiver.lastname > a.receiver.lastname)
                            return -1
                        else if (a.receiver.firstname > b.receiver.firstname)
                            return 1
                        else if (b.receiver.firstname > a.receiver.firstname)
                            return -1
                        else
                            return b.date - a.date
                    })
                    break
            }
        }
    },
    methods: {
        /* NOTIFICATIONS */
        checkNotif(mailId){
            if (this.notifications && this.notifications.directMailResponse) {
                let nbNotif = 0
                for (let notif of this.notifications.directMailResponse) {
                    if (notif.content === null) notif.delete()
                    if (mailId === notif.content) nbNotif++
                }
                return nbNotif
            }
            return 0
        },
        deleteNotif(mailId){
            if (this.notifications && this.notifications.directMailResponse) {
                for (let notif of this.notifications.directMailResponse) {
                    if (mailId === notif.content) {
                        notif.delete()
                    }
                }
            }
        },

        openFile(attachment) {
            window.open(attachment.url)
        },
        close() {
            this.responseData = {
                modal: false,
                mail: null,
                sender: null,
                message: '',
                attachment: []
            }
        },
        async save() {
            if (this.responseData.message.trim() === '') {
                Swal.fire({
                    title: "Erreur",
                    text: "Au moins un champs est vide ou incorrecte",
                    icon: "error",
                    confirmButtonText: "OK",
                })
            } else {
                // attachments
                const tmp_attachments = []
                if (this.responseData.attachments && this.responseData.attachments.length > 0) {
                    const date = new Date()
                    const uploadName = date.getFullYear() + "-" + date.getMonth() + "-" + date.getDay() + "_" + date.getHours() + "h" + date.getMinutes() + "m" + date.getSeconds() + "s_" + date.getTime()
                    let counter = 0
                    for (const file of this.responseData.attachments) {
                        const name = uploadName + "_" + counter.toString()
                        const fileRef = ref(this.storage, "mail/attachments/" + name + "." + file.name.split(".").pop())

                        await uploadBytes(fileRef, file)
                        tmp_attachments.push({
                            name: file.name,
                            ref: "mail/attachments/" + name + "." + file.name.split(".").pop(),
                        })
                        counter++
                    }
                }

                const new_response = new MailResponse(
                    null,
                    this.responseData.mail.id,
                    new Date().getTime(),
                    this.responseData.sender.id,
                    this.responseData.message,
                    tmp_attachments,
                    null
                )

                new_response.save().then(async () => {
                    logger.log(this.profile.id, "MAILRESPONSE ADD", this.profile.email + " a ajouté une réponse de mail")

                    let tmp_mail
                    for (const mail of this.mails) {
                        if (mail.id === new_response.mail) tmp_mail = mail
                    }
                    notifier.sendOne(tmp_mail.sender.id, tmp_mail.room, "directMailResponse", new_response.date, new_response.mail)

                    Swal.fire({
                        title: "Message envoyé",
                        text: "Le message a bien été envoyé",
                        icon: "success",
                        confirmButtonText: "Ok",
                    })
                    this.close()
                })
            }
        },

        async exportAllMail(){
          let zip = new JSZip()

          let counter = 0
          this.loadingState = 0
          this.loader = true

          for(let mail of this.mails){
            let name = mail.subject
            if(mail.sender == undefined) continue
            let path = mail.sender.lastname + " " + mail.sender.firstname + "/" + name + "/"
            
            let mailData = ""
            mailData += "Mail envoyé par : " + mail.sender.lastname + " " + mail.sender.firstname + "\n"
            mailData += "Sujet : " + mail.subject + "\n"
            mailData += "Date : " + new Date(mail.date).toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris' }) + " " + new Date(mail.date).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' }) + "\n"
            mailData += "Pieces jointes : " + mail.attachments.length + "\n"
            for(let attachment of mail.attachments){
              mailData += "   -PJ_"+attachment.name + "\n"
            }
            mailData += "Debut du message : \n"
            mailData += "------------------------------ \n" + "\n"
            mailData += mail.message + "\n" + "\n"
            mailData += "------------------------------ \n"
            mailData += "Fin du message \n"
            mailData = mailData.replace(/<[^>]+>/g, ' ').trim().replaceAll("  ", " ").replaceAll("Invalid Date Invalid Date", "Date invalide")

            let buffer = Buffer.from(mailData, 'utf-8')
            zip.file(path + "Mail.txt", buffer)

            for(let attachment of mail.attachments){            
              let fileRef = ref(this.storage, attachment.ref) 
              let fileBlob = await getBlob(fileRef)

              zip.file(path + "PJ_" + attachment.name, fileBlob)
            }
            counter++
            this.loadingState = Math.round(counter / (this.mails.length +2) *100)
          }
            
          let zipBlob = await zip.generateAsync({type:"blob"})
          counter++
          this.loadingState = Math.round(counter / (this.mails.length +2) *100)
          await downloader.downloadBlob(zipBlob, "Mails directs.zip")
          counter++
          this.loadingState = Math.round(counter / (this.mails.length +2) *100)
          setTimeout(() => {
            this.loader = false
          }, 300)
        }
    },
    created() {
        if (this.profile.role === 'User') {
            this.unsub.push(Mail.listenByRoomAndBySender(this.profile.currentRoom, this.profile.id, async mails => {
                let tmp_mails = []
                for (let mail of mails) {
                    // attachments
                    for (let attachment of mail.attachments) {
                        let fileRef = ref(this.storage, attachment.ref) 
                        getDownloadURL(fileRef)
                        .then((url) => {
                            attachment.url = url
                        })
                    }
                    // receiver
                    mail.receiver = this.contactsTable[mail.receiver]

                    // responses
                    this.unsub.push(MailResponse.listenByMail(mail.id, data => {
                        for (const response of data) {
                            response.sender = this.contactsTable[response.sender]
                        }
                        mail.responses = data
                        
                        mail.responses.sort((a,b)=>{
                            return a.date - b.date
                        })
                        
                        // push
                        if (!tmp_mails.map(x=>x.id).includes(mail.id)) tmp_mails.push(mail)
                        else tmp_mails.map(x => x.id === mail.id ? mail : x)

                        tmp_mails.sort((a,b)=>{
                            return b.date - a.date
                        })
                        this.mails = tmp_mails
                    }))
                }
            }))
        } else {
            Contact.getByRoom(this.profile.currentRoom).then(data => {
                for (let contact of data) {
                    this.unsub.push(Mail.listenByRoomAndByReceiver(this.profile.currentRoom, contact.id, async mailsData => {
                        this.responseUnsub.forEach(unsub => unsub())
                        for (let mail of mailsData) {
                            // attachments
                            for (let attachment of mail.attachments) {
                                let fileRef = ref(this.storage, attachment.ref) 
                                getDownloadURL(fileRef)
                                .then((url) => {
                                    attachment.url = url
                                })
                            }
                            // receiver
                            mail.receiver = this.contactsTable[mail.receiver]
                            // sender
                            mail.sender = this.contactsTable[mail.sender]

                            // responses
                            this.unsub.push(MailResponse.listenByMail(mail.id, data => {
                                for (const response of data) {
                                    response.sender = this.contactsTable[response.sender]
                                }
                                mail.responses = data
                                
                                mail.responses.sort((a,b)=>{
                                    return a.date - b.date
                                })
                                
                                // push
                                if (!this.mails.map(x=>x.id).includes(mail.id)) this.mails.push(mail)
                                else this.mails.map(x => x.id === mail.id ? mail : x)
                                
                                this.mails.sort((a,b)=>{
                                    return b.date - a.date
                                })
                            }))
                        }
                    }))
                }
            })
        }
    },
    destroyed() {
        this.unsub.forEach(unsub => unsub())
        this.responseUnsub.forEach(unsub => unsub())
    }
}
</script>