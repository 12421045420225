<template>
	<v-dialog v-model="displayed" max-width="900px" persistent>
		<v-card v-if="data.mail">
			<v-card-title>
				<span class="text-h5" v-text="title"></span>
			</v-card-title>
			<v-card-text>
        <v-textarea v-model="message" outlined></v-textarea>
				<!-- <VueEditor class="mb-2" v-if="new Array('file', 'text').includes(data.mail.responseType)" v-model="message" :editor-toolbar="toolbar"></VueEditor> -->
				<v-file-input chips multiple truncate-length="30" label="Pièces jointes" v-if="data.mail.responseType == 'file' " v-model="data.attachments"></v-file-input>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="blue darken-1" text @click="close"> Annuler </v-btn>
				<v-btn color="blue darken-1" text @click="save"> Envoyer </v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { VueEditor } from "vue2-editor"

	export default {
		props: ["data", "title"],
        components: {
            VueEditor
        },
		data() {
			return {
				displayed: false,
				apiKey: "sc3e4lfj6m905te45946p91m7zwu3ynqm3l3fl62iknv1asv",
				toolbar: [
					['bold', 'italic', 'underline', 'strike'],
					['blockquote'],

					[{ 'list': 'ordered'}, { 'list': 'bullet' }],
					[{ 'script': 'sub'}, { 'script': 'super' }],
					[{ 'indent': '-1'}, { 'indent': '+1' }],

					[{ 'header': [1, 2, 3, false] }],

					[{ 'align': [] }],
				],
        message: "",
			}
		},
		methods: {
			open() {
				this.displayed = true
        this.message = ""
        this.$forceUpdate()
			},
			close() {
				this.displayed = false
			},
			save() {
        this.data.message = this.message
				if ((this.data.message && this.data.attachments) && ((this.data.mail.responseType == 'text' && this.data.attachments.length <= 0) || (this.data.mail.responseType == 'file' && this.data.attachments.length > 0)) ) {
					this.$emit("save", this.data)
					this.close()
				} else {
					Swal.fire({
						title: "Erreur",
						text: "Les données entrées sont vides ou incorrectes",
						icon: "error",
						confirmButtonText: "OK",
					})
				}
			},
		},
	}
</script>
