<template>
  <div v-if="showCorrection">
    <v-btn outlined class="mb-0 mt-1 correction" :color="isCorrect ? 'success' : 'error'">
      {{ question.choices[formResponse.responses[slideIndex][questionIndex]] }}
    </v-btn>
    <v-btn v-if="!isCorrect" outlined class="ml-3 mb-0 mt-1 correction" color="info">
      {{ question.choices[question.goodResponse] }}
    </v-btn>
  </div>

  <div v-else-if="profile && profile.role === 'User'">
    <v-menu close-on-content-click close-on-click :disabled="disabled">
      <template #activator="menu">
        <v-btn v-on="{ ...menu.on }" outlined class="mb-0 mt-1" color="text" :color="disabled ? 'textContrast' : 'text'" :class="disabled ? 'disabled':''">
          {{ question.choices[formResponse.responses[slideIndex][questionIndex]] }}
        </v-btn>
      </template>
      <v-card outlined class="py-3">
        <v-list-item link v-for="(choice, index) in question.choices" :key="index"
          class="pointer" @click="formResponse.responses[slideIndex][questionIndex] = index; formResponse.lastUpdateDate = new Date().getTime()"
        >
          <v-list-item-title>{{ choice }}</v-list-item-title>
        </v-list-item>
      </v-card>
    </v-menu>
  </div>

  <div v-else @mouseover="addToRightClick" @mouseleave="delToRightClick">
    <RightClickMenu ref="rightClickMenu" :rightClickItems="rightClickItems" v-if="rightClickItems" />

    <p class="mb-0" v-if="!preview">Correction :</p>
    <v-menu v-if="$refs.rightClickMenu || !rightClickItems" :close-on-content-click="preview" :close-on-click="rightClickItems !== undefined ? !$refs.rightClickMenu.showMenu : ''">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" outlined class="my-3 mb-0 mt-1" color="text">
          {{ preview ? question.choices[previewValue] : question.choices[question.goodResponse] }}
        </v-btn>
      </template>
      <v-card outlined class="py-3">
        <v-radio-group v-model="question.goodResponse" hide-details class="ma-0 pa-0">
          <v-list-item link v-for="(choice, index) in question.choices" :key="index"
            @mouseover="addItemToRightClick(index)" @mouseleave="delItemToRightClick"
            @contextmenu="rightClickMethod"
            :class="preview ? 'pointer' : ''"
            @click="previewValue = index"
          >
            <v-list-item-title v-if="preview">{{ choice }}</v-list-item-title>
            <div class="d-flex align-start py-3" :class="preview ? 'd-none' : ''">
              <v-radio :value="index" class="ma-0 pa-0" :class="preview ? 'd-none' : ''" />
              <v-textarea v-model="question.choices[index]" rows="1" auto-grow hide-details class="mt-0 pt-0" placeholder="Réponse" :class="preview ? 'd-none' : ''" />
            </div>
          </v-list-item>
        </v-radio-group>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import Correction from '@/assets/functions/corrections'
import RightClickMenu from '@/components/SlideForm/RightClickMenu.vue'

export default {
  props: ['question', 'preview', 'rightClickItems', 'profile', 'formResponse', 'slideIndex', 'questionIndex', 'disabled', 'showCorrection'],
  components: { RightClickMenu },
  data() {
    return {
      previewValue: null,
      isCorrect: false
    }
  },
  watch: {
    preview() {
      this.previewValue = null
    },
    showCorrection() {
      this.runCorrection()
    }
  },
  mounted() {
    if (this.showCorrection) {
      this.runCorrection()
    }
    this.$forceUpdate()
  },
  methods: {
    rightClickMethod(e) {
      if (this.rightClickItems) {
        this.$refs.rightClickMenu.show(e)
      }
    },
    addToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[10] = "divider"
        this.rightClickItems[11] = {
          text: "Ajouter une réponse",
          method: () => { this.question.choices.push("") }
        }
      }
    },
    delToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[10] = null
        this.rightClickItems[11] = null
      }
    },
    addItemToRightClick(i) {
      if (this.rightClickItems) {
        this.rightClickItems[11] = {
          text: "Ajouter une réponse",
          method: () => { this.question.choices.push("") }
        }
        this.rightClickItems[12] = {
          text: 'Supprimer la réponse',
          method: () => { this.question.choices.splice(i, 1) }
        }
      }
    },
    delItemToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[11] = null
        this.rightClickItems[12] = null
      }
    },

    runCorrection() {
      this.isCorrect = Correction('list', this.formResponse.responses[this.slideIndex][this.questionIndex], this.question.goodResponse)
    }
  }
}
</script>

<style>
.disabled {
  cursor: default!important;
  border-color: var(--v-textContrast-base)!important;
}
.correction {
  pointer-events: none;
}
</style>