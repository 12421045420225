<template>
  <div>
    <p class="mb-0" v-if="!preview">Correction :</p>
    
    <div v-if="showCorrection">
      <div :class="isCorrect ? 'success--text' : 'error--text'">
        {{ checkIfEmpty() ? 'Non répondu.' : formResponse.responses[slideIndex][questionIndex] }}
        <p class="text-caption" v-if="profile && profile.role !== 'User'">Interprété comme "{{ interpretation }}"</p>
      </div>
      <div v-if="isCorrect === false" class="mt-2">
        Correction : <div class="info--text">{{ question.goodResponse }}</div>
      </div>
    </div>

    <v-text-field placeholder="Réponse" hide-details class="ma-0 pa-0" v-else-if="profile.role === 'User'" v-model="formResponse.responses[slideIndex][questionIndex]" :disabled="disabled" @keydown="formResponse.lastUpdateDate = new Date().getTime()" />
    <v-text-field placeholder="Réponse" hide-details class="ma-0 pa-0" v-else-if="preview" />
    <v-text-field placeholder="Réponse" hide-details class="ma-0 pa-0" v-else v-model="question.goodResponse" />
  </div>
</template>

<script>
import Correction from '@/assets/functions/corrections'

export default {
  props: ['question', 'preview', 'profile', 'formResponse', 'slideIndex', 'questionIndex', 'disabled', 'showCorrection'],
  data() {
    return {
      isCorrect: false,
      interpretation: null
    }
  },
  watch: {
    showCorrection() {
      this.runCorrection()
    }
  },
  mounted() {
    if (this.showCorrection) {
      this.runCorrection()
    }
  },
  methods: {
    runCorrection() {
      this.isCorrect = Correction('number', this.formResponse.responses[this.slideIndex][this.questionIndex], this.question.goodResponse)
      if (this.formResponse.responses[this.slideIndex][this.questionIndex]) {
        const whatIsInterpreted = parseFloat(this.formResponse.responses[this.slideIndex][this.questionIndex]
          .replace(/(?!^-)[^0-9.,]+/g,'')
          .replace(',', '.')
        )
        if (isNaN(whatIsInterpreted)) {
          this.interpretation = 'Non répondu'
        } else {
          this.interpretation = whatIsInterpreted
        }
      } else {
        this.interpretation = 'Non répondu'
      }
    },
    checkIfEmpty() {
      return [null, undefined].includes(this.formResponse.responses[this.slideIndex][this.questionIndex]) || this.formResponse.responses[this.slideIndex][this.questionIndex].trim() === ''
    }
  }
}
</script>