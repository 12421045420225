<template>
  <div>
    <p class="mb-0" v-if="!preview">Correction :</p>
    
    
    <div v-if="showCorrection">
      <div :class="isCorrect ? 'success--text' : 'error--text'">
        {{ checkIfEmpty() ? 'Non répondu.' : formResponse.responses[slideIndex][questionIndex] }}
      </div>
      <div v-if="isCorrect === false" class="mt-2">
        Correction : <div class="info--text">{{ question.goodResponse }}</div>
      </div>
    </div>

    <v-text-field placeholder="Réponse" hide-details class="ma-0 pa-0" v-else-if="profile && profile.role === 'User'" v-model="formResponse.responses[slideIndex][questionIndex]" :disabled="disabled" @keydown="formResponse.lastUpdateDate = new Date().getTime()" />
    <v-text-field placeholder="Réponse" hide-details class="ma-0 pa-0" v-else-if="preview" />
    <v-text-field placeholder="Réponse" hide-details class="ma-0 pa-0" v-else v-model="question.goodResponse" />
  </div>
</template>

<script>
import Correction from '@/assets/functions/corrections'

export default {
  props: ['question', 'preview', 'profile', 'formResponse', 'slideIndex', 'questionIndex', 'disabled', 'showCorrection'],
  data() {
    return {
      isCorrect: false
    }
  },
  watch: {
    showCorrection() {
      this.runCorrection()
    }
  },
  mounted() {
    if (this.showCorrection) {
      this.runCorrection()
    }
  },
  methods: {
    runCorrection() {
      this.isCorrect = Correction('expression', this.formResponse.responses[this.slideIndex][this.questionIndex], this.question.goodResponse)
    },
    checkIfEmpty() {
      return [null, undefined].includes(this.formResponse.responses[this.slideIndex][this.questionIndex]) || this.formResponse.responses[this.slideIndex][this.questionIndex].trim() === ''
    }
  }
}
</script>