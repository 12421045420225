<template>
    <span v-if="profile.role != 'User'">
        <v-row class="px-3 py-2">
            <draggable v-model="question.choices" @start="dragging=true" @end="dragging=false" draggable=".item" group="item" class="d-flex flex-wrap">
                <div v-for="choice, index in question.choices" class="d-flex flex-column align-center my-2 item">
                    <div class="d-flex align-center">
                        <div v-if="choice.answer" @blur="asignValue($event, index)" v-text="choice.text" contenteditable="true" class="ml-2 px-1 success--text" style="min-width:50px;outline:none;border-radius:5px;text-align:center;border:solid 1px;"></div>
                        <div v-else               @blur="asignValue($event, index)" v-text="choice.text" contenteditable="true" class="ml-2 px-1"               style="min-width:50px;outline:none;border-radius:5px;text-align:center;border-bottom:solid 1px;"></div>
                        <v-icon @click="question.choices.splice(index, 1)" small color="error" style="cursor:pointer;" class="ml-1">mdi-delete-outline</v-icon>
                    </div>
                    <div class="d-flex align-center mr-2">
                        <v-icon small style="cursor:pointer;">mdi-arrow-left-right</v-icon>
                    </div>
                </div>
            </draggable>
        </v-row>
        <v-row class="px-3 py-2">
            <v-col cols="12" sm="6" md="3" class="d-flex">
                <v-btn @click="question.choices.push({text: '', answer: false})" class="mr-2 pr-5" color="secondary" small outlined>
                    <v-icon small>mdi-plus</v-icon>
                    <div>Texte</div>
                </v-btn>
                <v-btn @click="question.choices.push({text: '', answer: true})" class="ml-2 pr-5" color="success" small outlined>
                    <v-icon small>mdi-plus</v-icon>
                    <div>Trou</div>
                </v-btn>
            </v-col>
        </v-row>
    </span>

    <span v-else>
        <h4 v-text="question.text" class="mb-2"></h4>
        <span class="d-flex flex-wrap">
            <div v-for="part, i in question.choices" class="d-flex flex-wrap">
                <div v-if="!part.answer" v-text="part.text" class="mx-1"></div>
                <div v-else @blur="question.response[i] = $event.target.innerText" v-text="question.response[i] ? question.response[i] : ''" contenteditable="true" class="mx-1 px-1 info--text" style="min-width:50px;outline:none;border:solid 1px;border-radius:5px;text-align:center" @keyup="$emit('edited')"></div>
            </div>
        </span>
    </span>
</template>
<script>
import draggable from 'vuedraggable'

export default {
    props: ['profile', 'question'],
    components: {
        draggable
    },
    data: () => ({
        response: {
            valide: false,
            errorMessage: ''
        }
    }),
    methods: {
        asignValue(event, index) {
            this.question.choices[index].text = event.target.innerText
        },
        testIsValid() {
            this.question.correctAnswer = []
            let nbAnswer = 0
            this.question.choices.forEach((choice, i) => {
                if (choice.answer) {
                    this.question.correctAnswer[i] = choice.text
                    nbAnswer++
                }
            })

            // titre
            if (!this.question.text || this.question.text.trim() == '') {
                this.response.errorMessage = 'Veuillez renseigner un titre à toutes les questions.'
                return this.response
            }
            // au moins 1 choix
            if (nbAnswer == 0) {
                this.response.errorMessage = 'Veuillez renseigner au moins un trou à toutes les questions de type Texte à trous.'
                return this.response
            }
            // choix
            for (let choice of this.question.choices) {
                if (!choice.text || choice.text.trim() == '') {
                    this.response.errorMessage = 'Veuillez renseigner une correction à toutes les parties des questions de type Texte à trous.'
                    return this.response
                }
            }
            // validation
            this.response.valide = true
            return this.response
        }
    }
}
</script>