<template>
    <span v-if="profile.role != 'User'">
        <div>Correction :</div>
        <v-text-field v-model="question.correctAnswer" type="number" placeholder="Réponse" clearable class="mt-0 pt-0" />
    </span>

    <span v-else>
        <h4 v-text="question.text" class="mb-5"></h4>
        <v-text-field v-model="question.response" type="number" label="Réponse" clearable class="mt-0 pt-0" style="margin-bottom:-15px" @keyup="$emit('edited')" />
    </span>
</template>

<script>
export default {
    props: ["profile", "question"],
    data: () => ({
        response: {
            valide: false,
            errorMessage: ''
        }
    }),
    methods: {
        testIsValid(){
            // titre
            if(!this.question.text || this.question.text.trim() == ''){
                this.response.errorMessage = 'Veuillez renseigner un titre à toutes les questions.'
                return this.response
            }
            // correction
            if(!this.question.correctAnswer && this.question.correctAnswer != 0){
                this.response.errorMessage = 'Veuillez renseigner une correction à toutes les questions.'
                return this.response
            }
            // validation
            this.response.valide = true
            return this.response
        }
    }
}
</script>