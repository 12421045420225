<template>
  <div v-if="showCorrection" class="correction-container">
    <v-radio-group hide-details class="mt-0" v-model="formResponse.responses[slideIndex][questionIndex]" readonly>
      <v-radio class="correction" v-for="(choice, index) in question.choices" :key="index" :value="index" :label="choice" :color="isCorrect ? 'success' : 'error'" />
    </v-radio-group>
    <div v-if="!isCorrect">
      <v-radio-group hide-details class="mt-0" v-model="question.goodResponse" readonly>
        <v-radio class="correction" v-for="(choice, index) in question.choices" :key="index" :value="index" :label="choice" color="info" />
      </v-radio-group>
    </div>
  </div>

  <v-radio-group hide-details class="mt-0" v-else-if="profile && profile.role === 'User'" v-model="formResponse.responses[slideIndex][questionIndex]" :disabled="disabled">
    <v-radio v-for="(choice, index) in question.choices" :key="index" :value="index" :label="choice" @click="formResponse.lastUpdateDate = new Date().getTime()" />
  </v-radio-group>

  <div v-else @mouseover="addToRightClick" @mouseleave="delToRightClick">
    <v-radio-group hide-details class="mt-0" v-if="preview">
      <v-radio v-for="(choice, index) in question.choices" :key="index" :value="choice" :label="choice" />
    </v-radio-group>
    <v-radio-group hide-details class="mt-0" v-else v-model="question.goodResponse" label="Correction :">
      <div class="field" v-for="(choice, index) of question.choices" :key="index"
        @mouseover="addItemToRightClick(index)" @mouseleave="delItemToRightClick"
      >
        <v-radio :value="index" class="ma-0" />
        <v-text-field v-model="question.choices[index]" hide-details class="ma-0 pa-0" placeholder="Réponse" />
      </div>
    </v-radio-group>
  </div>
</template>

<script>
import Correction from '@/assets/functions/corrections'

export default {
  props: ['question', 'preview', 'rightClickItems', 'profile', 'formResponse', 'slideIndex', 'questionIndex', 'disabled', 'showCorrection'],
  data() {
    return {
      isCorrect: false
    }
  },
  watch: {
    showCorrection() {
      this.runCorrection()
    }
  },
  mounted() {
    if (this.showCorrection) {
      this.runCorrection()
    }
  },
  methods: {
    addToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[10] = 'divider'
        this.rightClickItems[11] = {
          text: 'Ajouter une réponse',
          method: () => {
            this.question.choices.push('')
            this.$forceUpdate()
          }
        }
      }
    },
    delToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[10] = null
        this.rightClickItems[11] = null
      }
    },
    addItemToRightClick(i) {
      if (this.rightClickItems) {
        this.rightClickItems[12] = {
          text: 'Supprimer la réponse',
          method: () => {
            this.question.choices.splice(i, 1)
            this.$forceUpdate()
          }
        }
      }
    },
    delItemToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[12] = null
      }
    },

    runCorrection() {
      this.isCorrect = Correction('uniqueChoice', this.formResponse.responses[this.slideIndex][this.questionIndex], this.question.goodResponse)
    }
  }
}
</script>

<style scoped lang="scss">
.field {
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.correction-container {
  display: flex;
  align-items: flex-end;
  gap: 4em;
}
.correction {
  pointer-events: none;
}
</style>