export default (userResponse, goodResponse) => {
  const isCorrect = []

  for (const index in goodResponse) {
    if (!goodResponse[index].isBlank) {
      isCorrect[index] = null
    } else if (goodResponse[index].value.trim() === userResponse[index].trim()) {
      isCorrect[index] = true
    } else {
      isCorrect[index] = false
    }
  }

  return isCorrect
}