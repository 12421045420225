<template>
  <div v-if="showCorrection" class="correction-container">
    <div class="correction" v-if="formResponse.responses[slideIndex] && formResponse.responses[slideIndex][questionIndex]">
      <div class="field" v-for="(choice, index) in question.choices" :key="index">
        <v-checkbox hide-details class="ma-0 pa-0"  :label="choice" v-model="formResponse.responses[slideIndex][questionIndex][index]" readonly :color="isCorrect[index] ? 'success' : 'error'" />
      </div>
    </div>
    <div class="correction" v-else>
      <div class="field" v-for="(choice, index) in question.choices" :key="index">
        <v-checkbox hide-details class="ma-0 pa-0"  :label="choice" readonly />
      </div>
    </div>
    <div v-if="!allCorrect" class="correction">
      <div class="field" v-for="(choice, index) in question.choices" :key="index">
        <v-checkbox hide-details class="ma-0 pa-0"  :label="choice" v-model="question.goodResponse[index]" readonly color="info" />
      </div>
    </div>
  </div>

  <div v-else-if="profile && profile.role === 'User'">
    <div class="field" v-for="(choice, index) in question.choices" :key="index">
      <v-checkbox hide-details class="ma-0 pa-0"  :label="choice" v-model="formResponse.responses[slideIndex][questionIndex][index]" :disabled="disabled" @click="formResponse.lastUpdateDate = new Date().getTime()" />
    </div>
  </div>

  <div v-else @mouseover="addToRightClick" @mouseleave="delToRightClick">
    <p v-if="!preview" class="mt-3 mb-1">Correction :</p>
    <div class="field" v-for="(choice, index) in question.choices" :key="index"
      @mouseover="addItemToRightClick(index)" @mouseleave="delItemToRightClick"
    >
      <v-checkbox hide-details class="ma-0 pa-0" v-if="preview" :label="choice" />
      <v-checkbox hide-details class="ma-0 pa-0" v-else v-model="question.goodResponse[index]" />
      <v-text-field v-model="question.choices[index]" hide-details class="ma-0 pa-0" placeholder="Réponse" v-if="!preview" />
    </div>
  </div>
</template>

<script>
import Correction from '@/assets/functions/corrections'

export default {
  props: ['question', 'preview', 'rightClickItems', 'profile', 'formResponse', 'slideIndex', 'questionIndex', 'disabled', 'showCorrection'],
  data() {
    return {
      isCorrect: [],
      allCorrect: false
    }
  },
  watch: {
    showCorrection() {
      this.runCorrection()
    }
  },
  created() {
    if (this.profile && this.profile.role === 'User') {
      if (this.formResponse.responses[this.slideIndex][this.questionIndex] === undefined) this.formResponse.responses[this.slideIndex][this.questionIndex] = {}
      for (const index in this.question.goodResponse) {
        if (this.formResponse.responses[this.slideIndex][this.questionIndex][index] === undefined) {
          this.formResponse.responses[this.slideIndex][this.questionIndex][index] = false
        }
      }
    }
  },
  mounted() {
    if (this.showCorrection) {
      this.runCorrection()
    }
  },
  methods: {
    addToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[10] = 'divider'
        this.rightClickItems[11] = {
          text: 'Ajouter une réponse',
          method: () => {
            this.question.choices.push('')
            this.question.goodResponse.push(false)
            this.$forceUpdate()
          }
        }
      }
    },
    delToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[10] = null
        this.rightClickItems[11] = null
      }
    },
    addItemToRightClick(i) {
      if (this.rightClickItems) {
        this.rightClickItems[12] = {
          text: 'Supprimer la réponse',
          method: () => {
            this.question.choices.splice(i, 1)
            this.question.goodResponse.splice(i, 1)
            this.$forceUpdate()
          }
        }
      }
    },
    delItemToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[12] = null
      }
    },

    runCorrection() {
      if (this.formResponse.responses[this.slideIndex] && this.formResponse.responses[this.slideIndex][this.questionIndex]) {
        this.isCorrect = Correction('multipleChoice', this.formResponse.responses[this.slideIndex][this.questionIndex], this.question.goodResponse)
        this.allCorrect = this.isCorrect.every(x => x !== false)
      }
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped lang="scss">
.field {
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.correction-container {
  display: flex;
  align-items: flex-end;
  gap: 4em;
}
.correction {
  pointer-events: none;
}
</style>