<template>
    <span v-if="profile.role === 'User'">
        <h4 v-text="question.text" class="mb-5"></h4>
        <v-textarea v-model="question.response" label="Réponse" class="pt-0" style="margin-bottom:-15px" @keyup="$emit('edited')"></v-textarea>
    </span>
</template>

<script>
export default {
    props: ["profile", "question"],
    data: () => ({
        response: {
            valide: false,
            errorMessage: ''
        }
    }),
    methods: {
        testIsValid(){
            // titre
            if(!this.question.text || this.question.text.trim() == ''){
                this.response.errorMessage = 'Veuillez renseigner un titre à toutes les questions.'
                return this.response
            }
            // validation
            this.response.valide = true
            return this.response
        }
    }
}
</script>