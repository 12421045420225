<template>
  <v-rating hover color="warning" background-color="grey lighten-2" v-if="showCorrection" v-model="formResponse.responses[slideIndex][questionIndex]" readonly />
  <v-rating hover color="warning" background-color="grey lighten-2" v-else-if="profile && profile.role === 'User'" v-model="formResponse.responses[slideIndex][questionIndex]" :readonly="disabled" @click="formResponse.lastUpdateDate = new Date().getTime()" />
  <v-rating hover color="warning" background-color="grey lighten-2" v-else-if="preview" />
</template>

<script>
export default {
  props: ['question', 'preview', 'profile', 'formResponse', 'slideIndex', 'questionIndex', 'disabled', 'showCorrection']
}
</script>