import { getDocs, onSnapshot } from 'firebase/firestore'

/**
 * General function that can be used to get any data from firebase.
 * @param {Query} query Query used to select data.
 * @param {Function} dataToObject Function used to convert a document into a usable object.
 * @returns An Array of object create by dataToObject function.
 */
const GetWhere = async (query, dataToObject) => {
  const dataList = []

  const response = await getDocs(query)
  response.forEach(doc => {
    dataList.push(dataToObject(doc.id, doc.data()))
  })

  return dataList
}

/**
 * General function that can be used to create a listener on any firebase data.
 * @param {Query} query Query used to select data.
 * @param {Function} dataToObject Function used to convert a document into a usable object.
 * @param {Function} callback Callback function.
 * @returns A listener in a callback function.
 */
const ListenWhere = (query, dataToObject, callback, keepOnlyOne) => {
  return onSnapshot(query, snapshot => {
    const dataList = []

    snapshot.forEach(document => {
      dataList.push(
        dataToObject(document.id, document.data())
      )
    })

    if (callback && keepOnlyOne)
      callback(keepOnlyOne(dataList))
    else if (callback)
      callback(dataList)
  })
}

export { ListenWhere, GetWhere }