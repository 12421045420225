<template>
  <div>
    <div v-if="showCorrection">
      <div :class="isCorrect ? 'success--text' : 'error--text'">
        {{ checkIfEmpty() ? 'Non répondu.' : formResponse.responses[slideIndex][questionIndex] === true ? 'Vrai' : 'Faux' }}
      </div>
      <div v-if="isCorrect === false" class="mt-2">
        Correction : <div class="info--text">{{ question.goodResponse === true ? 'Vrai' : 'Faux' }}</div>
      </div>
    </div>

    <v-radio-group hide-details class="mt-0 pt-0" v-else-if="profile && profile.role === 'User'" v-model="formResponse.responses[slideIndex][questionIndex]" :disabled="disabled" @click="formResponse.lastUpdateDate = new Date().getTime()">
      <v-radio label="Vrai" :value="true" />
      <v-radio label="Faux" :value="false" />
    </v-radio-group>
    <v-radio-group hide-details class="mt-0 pt-0" v-else-if="preview">
      <v-radio label="Vrai" :value="true" />
      <v-radio label="Faux" :value="false" />
    </v-radio-group>
    <v-radio-group hide-details class="mt-0 pt-0" v-else v-model="question.goodResponse" label="Correction :">
      <v-radio label="Vrai" :value="true" class="ml-1" />
      <v-radio label="Faux" :value="false" class="ml-1" />
    </v-radio-group>
  </div>
</template>

<script>
import Correction from '@/assets/functions/corrections'

export default {
  props: ['question', 'preview', 'profile', 'formResponse', 'slideIndex', 'questionIndex', 'disabled', 'showCorrection'],
  data() {
    return {
      isCorrect: false
    }
  },
  watch: {
    showCorrection() {
      this.runCorrection()
    }
  },
  mounted() {
    if (this.showCorrection) {
      this.runCorrection()
    }
  },
  methods: {
    runCorrection() {
      this.isCorrect = Correction('boolean', this.formResponse.responses[this.slideIndex][this.questionIndex], this.question.goodResponse)
    },
    checkIfEmpty() {
      return [null, undefined].includes(this.formResponse.responses[this.slideIndex][this.questionIndex])
    }
  }
}
</script>