<template>
	<div v-if="['User', 'Corrector', 'Animator', 'Admin', 'SuperAdmin'].includes(profile.role)">
		<v-card class="my-3">
			<v-tabs v-model="tab" left show-arrows>
				<v-tooltip bottom v-if="['User', 'Corrector', 'Animator', 'Admin', 'SuperAdmin'].includes(profile.role)">
					<template v-slot:activator="{ on, attrs }">
						<v-tab v-bind="attrs" v-on="on">
							<v-badge bordered overlap color="notif" v-if="checkNotif('mail')" :content="checkNotif('mail')">
								<v-icon>mdi-email-open-outline</v-icon>
							</v-badge>
							<v-icon v-else>mdi-email-open-outline</v-icon>
						</v-tab>
					</template>
					<span>Voir les mails de la room</span>
				</v-tooltip>
				<v-tooltip bottom v-if="new Array('Corrector', 'Animator', 'Admin', 'SuperAdmin').includes(profile.role)">
					<template v-slot:activator="{ on, attrs }">
						<v-tab v-bind="attrs" v-on="on">
							<v-icon>mdi-email-arrow-left-outline</v-icon>
						</v-tab>
					</template>
					<span>Voir les reponses</span>
				</v-tooltip>
				<v-tooltip bottom v-if="['User', 'Corrector', 'Animator', 'Admin', 'SuperAdmin'].includes(profile.role)">
					<template v-slot:activator="{ on, attrs }">
						<v-tab v-bind="attrs" v-on="on">
							<v-badge bordered overlap color="notif" v-if="checkNotif('directMailResponse')" :content="checkNotif('directMailResponse')">
								<v-icon>mdi-email-fast-outline</v-icon>
							</v-badge>
							<v-icon v-else>mdi-email-fast-outline</v-icon>
						</v-tab>
					</template>
					<span v-if="profile.role === 'User'">Voir les mails envoyés</span>
					<span v-else>Voir les mails directs</span>
				</v-tooltip>
				<v-tooltip bottom v-if="new Array('User').includes(profile.role)">
					<template v-slot:activator="{ on, attrs }">
						<v-tab v-bind="attrs" v-on="on">
							<v-icon>mdi-phone-refresh-outline</v-icon>
						</v-tab>
					</template>
					<span>Voir les réponses aux messages audio</span>
				</v-tooltip>
				<v-tooltip bottom v-if="new Array('Corrector', 'Animator', 'Admin', 'SuperAdmin').includes(profile.role)">
					<template v-slot:activator="{ on, attrs }">
						<v-tab v-bind="attrs" v-on="on">
							<v-icon>mdi-google-analytics</v-icon>
						</v-tab>
					</template>
					<span>Voir la progression</span>
				</v-tooltip>
			</v-tabs>

			<v-card-text>
				<v-tabs-items v-model="tab">
					<v-tab-item v-if="['User', 'Corrector', 'Animator', 'Admin', 'SuperAdmin'].includes(profile.role)">
						<!-- Voir les mails de la room -->
						<MailListTab :profile="profile" :notifications="notifications" :currentRoom="currentRoom" :contactsTable="contactsTable"></MailListTab>
					</v-tab-item>
					<v-tab-item v-if="new Array('Corrector', 'Animator', 'Admin', 'SuperAdmin').includes(profile.role)">
						<!-- Voir les reponses -->
						<ResponseListTab :profile="profile" :notifications="notifications" :currentRoom="currentRoom" :contactsTable="contactsTable"></ResponseListTab>
					</v-tab-item>
					<v-tab-item v-if="['User', 'Corrector', 'Animator', 'Admin', 'SuperAdmin'].includes(profile.role)">
						<!-- Voir les mails envoyés -->
						<DirectMail :profile="profile" :notifications="notifications" :contactsTable="contactsTable"></DirectMail>
					</v-tab-item>
					<v-tab-item v-if="new Array('User').includes(profile.role)">
						<!-- Voir les réponses aux messages audio -->
						<PhoneResponse :profile="profile"></PhoneResponse>
					</v-tab-item>
					<v-tab-item v-if="new Array('Corrector','Animator', 'Admin', 'SuperAdmin').includes(profile.role)">
						<!-- Voir la progression -->
						<MailProgressionTab :profile="profile" :user="user" :notifications="notifications" :currentRoom="currentRoom"></MailProgressionTab>
					</v-tab-item>
				</v-tabs-items>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
// components
import MailListTab from "@/components/Email/MailListTab.vue"
import SendMailTab from "@/components/Email/SendMailModal.vue"
import ResponseListTab from "@/components/Email/ResponseListTab.vue"
import MailProgressionTab from "@/components/Email/MailProgressionTab.vue"
import DirectMail from "@/components/Email/DirectMail.vue"
import PhoneResponse from "@/components/Email/PhoneResponse.vue"

// classes
import Contact from "@/classes/Contact"
import Profile from "@/classes/Profile"
import Group from "@/classes/Group"

export default {
	components: {
		MailListTab,
		SendMailTab,
		ResponseListTab,
		MailProgressionTab,
		DirectMail,
		PhoneResponse
	},
	name: "Email",
	props: ["user", "profile", "notifications", "config", "currentRoom"],
	data() {
		return {
			tab: 0,
			contactsTable: {},
			unsub: []
		}
	},
    methods: {
        /* NOTIFICATIONS */
        checkNotif(type){
			let nbNotif = 0
			if (this.notifications) {
				if (type === 'directMailResponse' && this.notifications.directMailResponse) {
					for (const notif of this.notifications.directMailResponse) {
						if (notif.content === null) notif.delete()
						else nbNotif++
					}
				} else if (type === 'mail' && this.notifications.mail) {
					for (const notif of this.notifications.mail) {
						if (notif.content === null) notif.delete()
						else nbNotif++
					}
				}
			}
			return nbNotif
        }
    },
	created() {
        this.unsub.push(Contact.listenByRoom(this.profile.currentRoom, data => {
            for (const contact of data)
                this.contactsTable[contact.id] = contact
        }))
        this.unsub.push(Profile.listenByRoom(this.profile.currentRoom, data => {
            for (const profile of data)
                this.contactsTable[profile.id] = profile
        }))
        this.unsub.push(Group.listenByRoom(this.profile.currentRoom, data => {
            for (const group of data)
                this.contactsTable[group.id] = group
        }))
	},
    destroyed() {
        this.unsub.forEach((unsub) => unsub())
    }
}
</script>