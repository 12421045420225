<template>
    <span v-if="profile.role != 'User'">
        <draggable v-model="question.choices" @start="dragging=true" @end="dragging=false" draggable=".item" group="item" class="d-flex flex-column">
            <div v-for="(choice, i) in question.choices" class="d-flex item" style="cursor:pointer">
                <h2 v-text="i+1" class="pr-3 pt-1"></h2>
                <v-text-field v-model="question.choices[i]" type="text" :label="'Élément n°'+(i+1)" outlined dense />
                <v-icon class="ml-2 pb-7">mdi-arrow-all</v-icon>
                <v-btn color="error" icon @click="question.choices.splice(i, 1)">
                    <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
            </div>
            <v-btn color="primary" class="mb-2" icon x-large @click="question.choices.push('')">
                <v-icon large>mdi-plus</v-icon>
            </v-btn>
        </draggable>
    </span>

    <span v-else>
        <h4 v-text="question.text" class="mb-2"></h4>
        <draggable v-model="question.response" @start="dragging=true" @end="dragging=false; $emit('edited')" draggable=".item" group="item" class="d-flex flex-column">
            <div v-for="(choice, i) in question.response" class="d-flex align-center item my-1" style="cursor:pointer">
                <h2 v-text="i+1 + '.'" class="pr-3"></h2>
                <h3 v-text="choice" class="py-1 px-2" style="border:solid 1px;border-radius:10px"></h3>
            </div>
        </draggable>
    </span>
</template>
<script>
import draggable from 'vuedraggable'

export default {
    props: ["profile", "question"],
    components: {
        draggable,
    },
    data: () => ({
        response: {
            valide: false,
            errorMessage: ''
        }
    }),
    created() {
        if(this.profile.role === 'User'){
            if (!this.question.response) this.question.response = this.question.choices.sort((a, b) => 0.5 - Math.random())
            this.shuffleChoices()
        }
    },
    methods: {
        shuffleChoices() {
            while (this.question.response.join('||') === this.question.correctAnswer.join('||')) {
                this.question.response = this.question.choices.sort((a, b) => 0.5 - Math.random())
            }
        },
        testIsValid(){
            // titre
            if(!this.question.text || this.question.text.trim() == ''){
                this.response.errorMessage = 'Veuillez renseigner un titre à toutes les questions.'
                return this.response
            }
            // au moins 2 choix
            if(this.question.choices.length < 2){
                this.response.errorMessage = 'Une des questions ne comporte pas assez de choix.'
                return this.response
            }
            // choix non vide
            for(let choice of this.question.choices){
                if(!choice || choice.trim() == ''){
                    this.response.errorMessage = 'Une des questions comporte un choix vide.'
                    return this.response
                }
            }
            // validation
            this.response.valide = true
            return this.response
        }
    }
}
</script>