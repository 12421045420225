<template>
  <div>
    <div v-if="preview" class="d-flex justify-space-between">
      <p class="mb-0">{{ question.min }}</p>
      <p class="mb-0">{{ question.max }}</p>
    </div>
    <v-row v-else>
      <v-col cols="12" sm="4">
        <v-text-field hide-details label="Minimum" v-model="question.min" @change="$forceUpdate()" />
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field hide-details label="Pas" v-model="question.step" @change="$forceUpdate()" />
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field hide-details label="Maximum" v-model="question.max" @change="$forceUpdate()" />
      </v-col>
    </v-row>

    <div v-if="showCorrection">
      <v-slider hide-details class="mt-2 slider" :min="question.min" :max="question.max" :step="question.step" ticks thumb-label v-model="formResponse.responses[slideIndex][questionIndex]" readonly :color="isCorrect ? 'success' : 'error'" />
      <v-slider hide-details class="mt-2 slider" :min="question.min" :max="question.max" :step="question.step" ticks thumb-label v-model="question.goodResponse" readonly v-if="!isCorrect" color="info" />
    </div>

    <v-slider hide-details class="mt-2 slider" @click.prevent.stop="formResponse.lastUpdateDate = new Date().getTime()" :min="question.min" :max="question.max" :step="question.step" ticks thumb-label v-else-if="profile && profile.role === 'User'" v-model="formResponse.responses[slideIndex][questionIndex]" :disabled="disabled" />
    <v-slider hide-details class="mt-2 slider" @click.prevent.stop="" :min="question.min" :max="question.max" :step="question.step" ticks thumb-label v-else-if="preview" />
    <v-slider hide-details class="mt-2 slider" @click.prevent.stop="" :min="question.min" :max="question.max" :step="question.step" ticks thumb-label v-else v-model="question.goodResponse" />
  </div>
</template>

<script>
import Correction from '@/assets/functions/corrections'

export default {
  props: ['question', 'preview', 'profile', 'formResponse', 'slideIndex', 'questionIndex', 'disabled', 'showCorrection'],
  data() {
    return {
      isCorrect: false
    }
  },
  watch: {
    showCorrection() {
      this.runCorrection()
    }
  },
  mounted() {
    if (this.showCorrection) {
      this.runCorrection()
    }
  },
  methods: {
    runCorrection() {
      this.isCorrect = Correction('numericSlider', this.formResponse.responses[this.slideIndex][this.questionIndex], this.question.goodResponse)
    }
  }
}
</script>

<style scoped>
.slider {
  width: 500px;
  max-width: 100%;
}
</style>