<template>
    <div class="d-flex mt-2">
        <div class="mx-2" v-for="attachment in attachments">
            <v-chip outlined @click.prevent.stop="currentFile = attachment">
                <v-icon small left>{{ getIcon(attachment.name) }}</v-icon>
                <div v-text="attachment.name"></div>
            </v-chip>
            <v-btn fab x-small elevation="0" @click.prevent.stop="download(attachment)">
                <v-icon>mdi-tray-arrow-down</v-icon>
            </v-btn>
        </div>

        <FileViewer v-if="currentFile" :file="currentFile" @close="currentFile = null" ref="fileViewer"/>
    </div>
</template>

<script>
// firebase
import { getStorage, ref } from "firebase/storage"

// components
import FileViewer from "@/components/FileViewer.vue"

// functions
import downloader from "@/assets/functions/downloader"

// config
import { downloadabledExt, textExt, audioExt, videoExt, pictureExt } from "@/assets/config/ExtWhiteList"

export default {
    props: ['attachments'],
    components: {
        FileViewer
    },
    data: () => ({
        currentFile: null
    }),
    methods: {
        download(attachment) {
            const fileRef = ref(getStorage(), attachment.ref)
            downloader.downloadfromStorage(fileRef, attachment.name)
        },
        getIcon(name) {
            const splitedName = name.toLowerCase().split('.')
            const ext = splitedName[splitedName.length-1]
            
            if (downloadabledExt.includes(ext)) {
                return 'mdi-file-outline'
            } else if (textExt.includes(ext)) {
                return 'mdi-file-document-outline'
            } else if (audioExt.includes(ext)) {
                return 'mdi-volume-high'
            } else if (videoExt.includes(ext)) {
                return 'mdi-movie-open-outline'
            } else if (pictureExt.includes(ext)) {
                return 'mdi-image-outline'
            }
        }
    }
}
</script>