<template>
  <!-- correction -->
  <div v-if="showCorrection" class="linking-container preview correction">
    <svg ref="svg" class="disabled">
      <line v-for="(line, index) of lines" :x1="line.x1" :y1="line.y1" :x2="line.x2" :y2="line.y2" :class="line.isCorrection ? 'info' : isCorrect[index] ? 'success' : 'error'" />
    </svg>
    <h4 class="mb-1">{{ question.text }}</h4>
    <div class="content">
      <div v-for="(side, i) in question.choices" :key="i">
        <div class="field" v-for="(choice, y) in side" :key="y" >
          <p class="mb-0">{{ choice }}</p>
          <v-radio tabindex="-1" ref="radios" :class="i" :style="`--i: ${i}; --y: ${y};`" readonly />
        </div>
      </div>
    </div>
    <span v-if="allCorrect === false">
      Correction :
    </span>
    <div class="content" v-if="allCorrect === false">
      <div v-for="(side, i) in question.choices" :key="i">
        <div class="field" v-for="(choice, y) in side" :key="y" >
          <p class="mb-0">{{ choice }}</p>
          <v-radio tabindex="-1" ref="correctionRradios" :class="i" :style="`--i: ${i}; --y: ${y};`" readonly />
        </div>
      </div>
    </div>
  </div>

  <!-- user / previw -->
  <div class="linking-container preview" v-else-if="(profile && profile.role === 'User') || preview">
    <svg ref="svg" :class="disabled ? 'disabled' : ''">
      <line v-for="line of lines" :x1="line.x1" :y1="line.y1" :x2="line.x2" :y2="line.y2" />
      <line v-if="tmp_line" :x1="tmp_line.x1" :y1="tmp_line.y1" :x2="tmp_line.x2" :y2="tmp_line.y2" class="tmp-line" />
    </svg>
    <h4 class="mb-1">{{ question.text }}</h4>
    <div class="content">
      <div v-for="(side, i) in question.choices" :key="i">
        <div class="field" v-for="(choice, y) in side" :key="y" >
          <p class="mb-0">{{ choice }}</p>
          <v-radio tabindex="-1" ref="radios" :class="i" @mousedown="startLinking(i,y, $event)" @mouseup="endLinking(i,y)" @touchstart="startLinking(i,y, $event)" @touchend="endLinking(null, null, $event)"
            :style="`--i: ${i}; --y: ${y};`" :disabled="disabled"
          />
        </div>
      </div>
    </div>
    <v-btn class="clear-btn" outlined @click="clearLines" v-if="!disabled">Tout effacer</v-btn>
  </div>

  <!-- admin -->
  <div class="linking-container" v-else
    @mouseover="addToRightClick" @mouseleave="delToRightClick"
  >
    <svg ref="svg">
      <line v-for="(line, index) of lines" :x1="line.x1" :y1="line.y1" :x2="line.x2" :y2="line.y2"
        @mouseover="addLineToRightClick(index)" @mouseleave="delLineToRightClick"
      />
      <line v-if="tmp_line" :x1="tmp_line.x1" :y1="tmp_line.y1" :x2="tmp_line.x2" :y2="tmp_line.y2" class="tmp-line" />
    </svg>
    <div class="noteContainer">
      <v-text-field hide-details class="ma-0 pa-0" placeholder="Énoncé" v-model="question.text" />
      <div class="note">
        /<span><v-text-field dense type="number" hide-details class="ma-0" @blur="testNote" v-model="question.points" /></span>
      </div>
    </div>
    <div class="content">
      <div v-for="(side, i) in question.choices" :key="i">
        <div class="field" v-for="(choice, y) in side" :key="y"
          @mouseover="addItemToRightClick(i, y)" @mouseleave="delItemToRightClick"
        >
          <v-text-field v-model="side[y]" placeholder="Réponse" hide-details class="ma-0 pa-0" />
          <v-radio tabindex="-1" ref="radios" :class="i" @mousedown="startLinking(i,y, $event)" @mouseup="endLinking(i,y)" @touchstart="startLinking(i,y, $event)" @touchend="endLinking(null, null, $event)"
            :style="`--i: ${i}; --y: ${y};`"
          />
        </div>
      </div>
    </div>
    <v-btn class="clear-btn" outlined @click="clearLines">Tout effacer</v-btn>
  </div>
</template>

<script>
import Correction from '@/assets/functions/corrections'
import testNote from '@/assets/functions/corrections/testNote'

export default {
  props: ['profile', 'question', 'preview', 'rightClickItems', 'formResponse', 'slideIndex', 'disabled', 'showCorrection'],
  data() {
    return {
      leftSelected: null,
      rightSelected: null,
      lines: [],
      correctionLines: [],
      tmp_line: null,
      svg: null,
      response: [],

      isCorrect: [],
      allCorrect: null
    }
  },
  watch: {
    // the positions of the buttons are updated so the position of the lines must be updated too
    preview() { this.drawLines() },
    disabled() { this.drawLines() },
    response: {
      handler(newVal) {
        this.formResponse.responses[this.slideIndex] = Object.assign({}, newVal)
      },
      deep: true
    },
    showCorrection() {
      this.runCorrection()
      this.drawLines()
    }
  },
  created() {
    // left need to stay in left side of the DOM
    this.question.choices = {
      left: this.question.choices.left,
      right: this.question.choices.right
    }

    if ((this.profile && this.profile.role === 'User') || this.showCorrection) {
      if (this.formResponse.responses[this.slideIndex]) {
        this.response = this.formResponse.responses[this.slideIndex]
      }
    }
  },
  mounted() {
    document.addEventListener('mousemove', this.editCoordinates, true)
    document.addEventListener('touchmove', this.editCoordinates, true)
    window.addEventListener('resize', this.drawLines, true)
    window.addEventListener('scroll', this.drawLines, true)
    document.addEventListener('mouseup', this.cancelDraw, true)

    this.drawLines()
    setTimeout(() => {
      this.drawLines()
    }, 300)

    if (this.showCorrection) {
      this.runCorrection()
    }
  },
  methods: {
    // right click menu
    addToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[10] = {
          text: 'Ajouter à gauche',
          method: () => {
            this.question.choices.left.push('')
            this.drawLines()
            this.$forceUpdate()
          }
        }
        this.rightClickItems[11] = {
          text: 'Ajouter à droite',
          method: () => {
            this.question.choices.right.push('')
            this.drawLines()
            this.$forceUpdate()
          }
        }
      }
    },
    delToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[10] = null
        this.rightClickItems[11] = null
      }
    },
    addItemToRightClick(i, y) {
      if (this.rightClickItems) {
        this.rightClickItems[12] = {
          text: 'Supprimé la réponse',
          method: () => {
            this.question.choices[i].splice(y,1)
            this.delLinkOfPoint(i, y)
            this.$forceUpdate()
          }
        }
        this.rightClickItems[13] = 'divider'
        this.rightClickItems[14] = {
          text: 'Supprimé les liens',
          method: () => {
            this.delLinkOfPoint(i, y)
          }
        }
      }
    },
    delItemToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[12] = null
        this.rightClickItems[13] = null
        this.rightClickItems[14] = null
      }
    },
    addLineToRightClick(index) {
      if (this.rightClickItems) {
        this.rightClickItems[12] = 'divider'
        this.rightClickItems[13] = {
          text: 'Supprimé le lien',
          method: () => {
            this.question.goodResponse.splice(index,1)
            this.drawLines()
          }
        }
      }
    },
    delLineToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[12] = null
        this.rightClickItems[13] = null
      }
    },


    // linking
    startLinking(i,y, event) {
      event.preventDefault()
      event.stopPropagation()
      this.startDraw(event)

      this.leftSelected = null
      this.rightSelected = null

      if (i === 'left') this.leftSelected = y
      else if (i === 'right') this.rightSelected = y
    },
    endLinking(side, index, event) {
      let i
      let y
      if (event) {
        const changedTouch = event.changedTouches[0]
        const el = document.elementFromPoint(changedTouch.clientX, changedTouch.clientY)
        i = getComputedStyle(el).getPropertyValue('--i')
        y = getComputedStyle(el).getPropertyValue('--y')
      } else {
        i = side
        y = index
      }
      
      if (this.leftSelected === null && i === 'left') this.leftSelected = y
      else if (this.rightSelected === null && i === 'right') this.rightSelected = y

      if (this.leftSelected !== null && this.rightSelected !== null) {
        const new_link = this.leftSelected + '|' + this.rightSelected
        if (this.profile.role === 'User') {
          if (!this.response.includes(new_link)) this.response.push(new_link)
          this.response.sort()
          this.formResponse.lastUpdateDate = new Date().getTime()
        } else {
          if (!this.question.goodResponse.includes(new_link)) this.question.goodResponse.push(new_link)
          this.question.goodResponse.sort()
        }

        this.endDraw()
      } else {
        this.tmp_line = null
      }

      this.leftSelected = null
      this.rightSelected = null
    },
    delLinkOfPoint(i, y) {
      const str = (i === 'left') ? `${y}|` : `|${y}`
      if (this.profile.role === 'User') this.response = this.response.filter(link => !link.includes(str))
      else this.question.goodResponse = this.question.goodResponse.filter(link => !link.includes(str))
      this.drawLines()
    },


    // drawing
    startDraw(event) {
      if (!this.svg) return

      let target = event.target.getBoundingClientRect()
      let x = target.left + (target.width / 2) - this.svg.left
      let y = target.top + (target.height / 2) - this.svg.top

      this.tmp_line = {
        x1: x,
        y1: y,
        x2: x,
        y2: y,
      }
    },
    endDraw() {
      this.lines.push(this.tmp_line)
      this.tmp_line = null
      this.drawLines()
    },
    editCoordinates(event) {
      if (!this.svg) return

      let x
      let y
      // tacile
      if (event.constructor.name === 'TouchEvent') {
        const changedTouch = event.changedTouches[0]
        const el = document.elementFromPoint(changedTouch.clientX, changedTouch.clientY)
        if (el && el.classList.contains('v-input--selection-controls__ripple')) {
          const target = el.getBoundingClientRect()
          x = target.left + (target.width / 2) - this.svg.left
          y = target.top + (target.height / 2) - this.svg.top
        } else {
          x = event.changedTouches[0].clientX - this.svg.left
          y = event.changedTouches[0].clientY - this.svg.top
        }
      }

      // mouse
      else if (event.target.classList.contains('v-input--selection-controls__ripple')) {
        const target = event.target.getBoundingClientRect()
        x = target.left + (target.width / 2) - this.svg.left
        y = target.top + (target.height / 2) - this.svg.top
      } else {
        x = event.x - this.svg.left
        y = event.y - this.svg.top
      }

      if (this.tmp_line !== null) {
        this.tmp_line.x2 = x
        this.tmp_line.y2 = y
      }
    },
    cancelDraw(event) {
      if (!event.target.classList.contains('v-input--selection-controls__ripple'))
        this.tmp_line = null
    },


    // updates all lines
    clearLines() {
      this.lines = []
      if (this.profile.role === 'User') this.response = []
      else this.question.goodResponse = []
    },
    drawLines() {
      this.svg = this.$refs.svg
      if (!this.svg) return

      this.svg = this.svg.getBoundingClientRect()
      setTimeout(() => {
        this.lines = []

        // sort all radio
        const leftRadios = []
        const rightRadios = []
        for (const radio of this.$refs.radios) {
          if (radio.$el && radio.$el.classList.contains('left')) leftRadios.push(radio.$el)
          else if (radio.$el && radio.$el.classList.contains('right')) rightRadios.push(radio.$el)
        }
        
        // create lines
        const array = this.profile.role === 'User' || this.showCorrection ? Object.values(this.response) : this.question.goodResponse
        for (const string of array) {
          const link = string.split('|')
          const left = leftRadios[link[0]].getBoundingClientRect()
          const right = rightRadios[link[1]].getBoundingClientRect()
          this.lines.push({
            x1: left.left + (left.width / 2) - this.svg.left - 4,
            y1: left.top + (left.height / 2) - this.svg.top,
            x2: right.left + (right.width / 2) - this.svg.left - 4,
            y2: right.top + (right.height / 2) - this.svg.top
          })
        }
      },0)

      if (this.showCorrection && this.allCorrect === false) {
        setTimeout(() => {
          this.correctionLines = []

          // sort all radio
          const leftRadios = []
          const rightRadios = []
          for (const radio of this.$refs.correctionRradios) {
            if (radio.$el && radio.$el.classList.contains('left')) leftRadios.push(radio.$el)
            else if (radio.$el && radio.$el.classList.contains('right')) rightRadios.push(radio.$el)
          }
          
          // create lines
          const array = this.question.goodResponse
          for (const string of array) {
            const link = string.split('|')
            const left = leftRadios[link[0]].getBoundingClientRect()
            const right = rightRadios[link[1]].getBoundingClientRect()
            this.lines.push({
              x1: left.left + (left.width / 2) - this.svg.left - 4,
              y1: left.top + (left.height / 2) - this.svg.top,
              x2: right.left + (right.width / 2) - this.svg.left - 4,
              y2: right.top + (right.height / 2) - this.svg.top,
              isCorrection: true
            })
          }
        },0)
      }
    },


    runCorrection() {
      if (this.formResponse.responses[this.slideIndex]) {
        this.isCorrect = Correction('linkingLists', this.formResponse.responses[this.slideIndex], this.question.goodResponse)
        this.allCorrect = (this.isCorrect.every(x=>x===true) && this.question.goodResponse.length === Object.values(this.formResponse.responses[this.slideIndex]).length)
      } else {
        this.isCorrect = false
        this.allCorrect = false
      }
    },

    testNote() {
      this.question.points = testNote(this.question.points)
    }
  },
  destroyed() {
    document.removeEventListener('mousemove', this.editCoordinates, true)
    document.removeEventListener('touchmove', this.editCoordinates, true)
    window.removeEventListener('resize', this.drawLines, true)
    window.removeEventListener('scroll', this.drawLines, true)
    document.removeEventListener('mouseup', this.cancelDraw, true)
  }
}
</script>

<style scoped lang="scss">
.noteContainer {
  min-width: 50%;
  width: 450px;
  max-width: 100%;
  display: grid;
  grid-template-columns: auto 100px;
  .note {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    > span {
      margin: 0;
      padding-left: 6px;
      width: 60px;
    }
  }
}


.linking-container {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10%;
  padding: 16px 64px;

  > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    > line {
      stroke: var(--v-text-base);
      stroke-width: 4px;
      stroke-linecap: round;
      pointer-events: all;
      transition: 100ms;
      &.tmp-line,
      &:not(.disabled > *):hover {
        stroke: var(--v-primary-base);
        cursor: pointer;
      }
      &.success {
        stroke: var(--v-success-base);
      } &.error {
        stroke: var(--v-error-base);
      } &.info {
        stroke: var(--v-info-base);
      }
    }
  }

  .content {
    width: 100%;
    display: flex;
    justify-content: space-between;

    > div {
      width: 35%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 16px;

      > .field {
        display: flex;
        align-items: center;
      }

      &:first-child > .field {
        gap: 8px;
        justify-content: flex-end;
      }
      &:last-child > .field {
        flex-direction: row-reverse;
      }
    }
  }

  &.preview > .content > div:last-child {
    align-items: flex-start;
  }

  .correction {
    pointer-events: none;
  }
}
</style>