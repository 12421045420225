<template>
    <span v-if="profile.role != 'User'">
        <span class="d-flex flex-row justify-start align-center">
            <p class="mr-3">Nombre de choix :</p>
            <v-text-field type="number" v-model="question.choicesAmount" @input="updateQuestionChoices(question)" dense />
        </span>
        <span>
            <p>Choix :</p>
            <v-text-field v-for="choice, i in question.choices" v-model="choice.text" type="text" :label="'Réponse n°' + (i+1)" outlined dense />
        </span>
        <span>
            <div>Correction :</div>
            <v-select :items="question.choices" v-model="question.correctAnswer" class="pt-0 mt-0"></v-select>
        </span>
    </span>

    <span v-else>
        <h4 v-text="question.text" class="mb-5"></h4>
        <v-select :items="question.choices" v-model="question.response" label="Réponse" class="pt-0 mt-0" style="margin-bottom:-15px" @click="$emit('edited')"></v-select>
    </span>
</template>

<script>
export default {
    props: ['profile', 'question'],
    data: () => ({
        response: {
            valide: false,
            errorMessage: ''
        }
    }),
    methods: {
        updateQuestionChoices(question){
            question.choices = []
            for (let i = 0; i < question.choicesAmount; i++) {
                question.choices.push({
                    text: null,
                })
            }
        },
        testIsValid(){
            // si le v-select n'a jamais été changer
            if(this.question.correctAnswer == null) this.question.correctAnswer = this.question.choices[0].text
            
            // titre
            if(!this.question.text || this.question.text.trim() == ''){
                this.response.errorMessage = 'Veuillez renseigner un titre à toutes les questions.'
                return this.response
            }
            // au moins 2 choix
            if(this.question.choices.length < 2){
                this.response.errorMessage = 'Une des questions ne comporte pas assez de choix.'
                return this.response
            }
            // correction
            if(!this.question.correctAnswer){
                this.response.errorMessage = 'Veuillez renseigner une correction à toutes les questions.'
                return this.response
            }
            // validation
            this.response.valide = true
            return this.response
        }
    }
}
</script>