<template>
  <!-- correction -->
  <div v-if="showCorrection">
    <!-- response green or red -->
    <div class="d-flex flex-wrap align-center">
      <div v-for="(part, i) in question.goodResponse" v-bind:key="i" v-if="!part.isBlank" class="mr-1">
        {{ part.value }}
      </div>
      <v-card outlined v-else class="card blank" :class="isCorrect[i] ? 'success--text' : 'error--text'" :style="marginNeeded(i)">
        {{ formResponse.responses[slideIndex] && formResponse.responses[slideIndex][questionIndex] ? formResponse.responses[slideIndex][questionIndex][i] : '' }}
      </v-card>
    </div>
    <!-- correction -->
    <div v-if="!allCorrect" class="mt-2">
      Correction : 
      <div class="d-flex flex-wrap align-center">
        <div v-for="(part, i) in question.goodResponse" v-bind:key="i" v-if="!part.isBlank" class="mr-1">
          {{ part.value }}
        </div>
        <v-card outlined v-else class="card blank info--text" :style="marginNeeded(i)">
          {{ part.value }}
        </v-card>
      </div>
    </div>
  </div>

  <!-- user -->
  <div v-else-if="profile && profile.role === 'User'" class="d-flex flex-wrap align-center">
    <div v-for="(part, i) in question.goodResponse" v-bind:key="i" v-if="!part.isBlank" class="mr-1">
      {{ part.value }}
    </div>
    <v-card outlined v-else class="card blank preview" :contenteditable="!disabled" :class="disabled ? 'disabled' : ''"
      :style="marginNeeded(i)"
      @click.prevent.stop @blur="asignValue($event, i)"
      v-text="formResponse.responses[slideIndex][questionIndex][i]"
    />
  </div>

  <!-- admin -->
  <div @mouseover="addToRightClick" @mouseleave="delToRightClick" v-else>
    <draggable class="draggable" v-if="!preview" v-model="question.goodResponse">
      <v-card outlined v-for="(part, i) in question.goodResponse" v-bind:key="i" class="card" :class="part.isBlank ? 'blank' : ''"
        @mouseover="addItemToRightClick(i)" @mouseleave="delItemToRightClick"
        @mouseup="enabledContentEditable" @click=""
        @blur="disabledContentEditable($event); asignValue($event, i)"
        v-text="part.value"
      />
    </draggable>
    <div v-else class="d-flex flex-wrap align-center">
      <div v-for="(part, i) in question.goodResponse" v-bind:key="i" v-if="!part.isBlank" class="mr-1">
        {{ part.value }}
      </div>
      <v-card outlined v-else class="card blank" contenteditable="true" :style="marginNeeded(i)"
        @click.prevent.stop=""
      />
    </div>
  </div>
</template>

<script>
import Correction from '@/assets/functions/corrections'
import draggable from 'vuedraggable'

export default {
  props: ['question', 'preview', 'rightClickItems', 'profile', 'formResponse', 'slideIndex', 'questionIndex', 'disabled', 'showCorrection'],
  components: { draggable },
  data() {
    return {
      isCorrect: [],
      allCorrect: false
    }
  },
  watch: {
    showCorrection() {
      this.runCorrection()
    }
  },
  created() {
    if (this.profile && this.profile.role === 'User') {
      if (!this.formResponse.responses[this.slideIndex][this.questionIndex]) {
        this.formResponse.responses[this.slideIndex][this.questionIndex] = {}
        for (const index in this.question.goodResponse) {
          if (!this.question.goodResponse[index].isBlank) {
            this.formResponse.responses[this.slideIndex][this.questionIndex][index] = null
          } else {
            this.formResponse.responses[this.slideIndex][this.questionIndex][index] = ''
          }
        }
      }
    }
  },
  mounted() {
    if (this.showCorrection) {
      this.runCorrection()
    }
  },
  methods: {
    addToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[10] = 'divider'
        this.rightClickItems[11] = {
          text: 'Ajouter un texte',
          method: this.addText
        }
        this.rightClickItems[12] = {
          text: 'Ajouter un trou',
          method: this.addBlank
        }
      }
    },
    delToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[10] = null
        this.rightClickItems[11] = null
        this.rightClickItems[12] = null
      }
    },
    addItemToRightClick(i) {
      if (this.rightClickItems) {
        this.rightClickItems[7] = 'divider'
        this.rightClickItems[8] = {
          text: 'Supprimer l\'élément',
          method: () => {
            this.question.goodResponse.splice(i, 1)
            this.$forceUpdate()
          }
        }
      }
    },
    delItemToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[7] = null
        this.rightClickItems[8] = null
      }
    },

    addText() {
      this.question.goodResponse.push({
        isBlank: false,
        value: ''
      })
      this.$forceUpdate()
    },
    addBlank() {
      this.question.goodResponse.push({
        isBlank: true,
        value: ''
      })
      this.$forceUpdate()
    },

    asignValue(event, i) {
      if (this.profile && this.profile.role === 'User') {
        this.formResponse.responses[this.slideIndex][this.questionIndex][i] = event.target.innerText
        this.formResponse.lastUpdateDate = new Date().getTime()
      } else {
        this.question.goodResponse[i].value = event.target.innerText
      }
      this.$forceUpdate()
    },
    enabledContentEditable(event) {
      event.target.contentEditable = true
    },
    disabledContentEditable(event) {
      event.target.contentEditable = false
    },

    marginNeeded(i) {
      if (!this.question.goodResponse[i+1] || this.question.goodResponse[i+1].isBlank || !['.', ',', ';'].includes(this.question.goodResponse[i+1].value.split('')[0])) {
        return 'margin-right: 6px'
      }
    },

    
    runCorrection() {
      if (this.formResponse.responses[this.slideIndex] && this.formResponse.responses[this.slideIndex][this.questionIndex]) {
        this.isCorrect = Correction('fillTheBlank', this.formResponse.responses[this.slideIndex][this.questionIndex], this.question.goodResponse)
        this.allCorrect = this.isCorrect.every(x => x !== false)
      } else {
        this.isCorrect = false
        this.allCorrect = false
      }
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped lang="scss">
.draggable {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
.card {
  min-width: 32px;
  display: flex;
  padding: 0 12px;
  transition: 250ms;

  &:not(:is(&:has(.card), .preview)) {
    cursor: grab;
    min-height: 1.5em;
  }
  &:focus, &.preview {
    cursor: text;
  }

  &:not(.blank):hover:not(&:has(:hover)) {
    border-color: var(--v-primary-base);
  }

  &:not(.blank):focus:not(&:has(:focus)) {
    border-color: var(--v-primary-base);
  }

  &.blank {
    border-color: var(--v-success-base);
  }

  &::before {
    opacity: 0;
  }
}
.preview .blank {
  padding: 2px 4px;
  cursor: text;
}

.disabled {
  cursor: default!important;
  border-color: var(--v-textContrast-base)!important;
  color: var(--v-textContrast-base)!important;
}

.success--text {
  cursor: default!important;
  border-color: var(--v-success-base)!important;
}
.error--text {
  cursor: default!important;
  border-color: var(--v-error-base)!important;
}
.info--text {
  cursor: default!important;
  border-color: var(--v-info-base)!important;
}
</style>