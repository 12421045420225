import fillTheBlank from '@/assets/functions/corrections/fillTheBlank'
import multipleChoice from '@/assets/functions/corrections/multipleChoice'
import dependent from '@/assets/functions/corrections/dependent'
import classification from '@/assets/functions/corrections/classification'
import linkingLists from '@/assets/functions/corrections/linkingLists'

export default (type, userResponse, goodResponse, slideQuestions, slideUserResponse) => {
  switch (type) {
    case 'number':
      if (userResponse) {
        const cleanResponse = userResponse.replace(/(?!^-)[^0-9.,]+/g,'').replace(',', '.')
        return parseFloat(cleanResponse) === parseFloat(goodResponse)
      }
      return false

    case 'expression':
      return userResponse && userResponse.trim() === goodResponse.trim()

    case 'boolean':
      return userResponse === goodResponse

    case 'fillTheBlank':
      return fillTheBlank(userResponse, goodResponse)

    case 'uniqueChoice':
      return userResponse === goodResponse

    case 'multipleChoice':
      return multipleChoice(userResponse, goodResponse)

    case 'list':
      return userResponse === goodResponse

    case 'slider':
      return userResponse === goodResponse

    case 'numericSlider':
      return userResponse === goodResponse
    
    case 'dependent':
      return dependent(userResponse, goodResponse, slideQuestions, slideUserResponse)

    case 'selectImage':
      return parseFloat(userResponse) === parseFloat(goodResponse)
    
    case 'classification':
      return classification(userResponse, goodResponse)

    case 'orderTheList':
      return JSON.stringify(Object.values(userResponse)) === JSON.stringify(goodResponse)

    case 'linkingLists':
      return linkingLists(userResponse, goodResponse)

    default:
      console.log('type missing in @/assets/functions/corrections -> ' + type)
      return false
  }
}