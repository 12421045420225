export default (userResponse, goodResponse) => {
  const isCorrect = {
    items: [],
    cat: goodResponse.map(()=>false)
  }

  if (![null, undefined].includes(userResponse)) {
    for (const catIndex in userResponse) {
      isCorrect.items[catIndex] = []
      for (const resIndex in userResponse[catIndex].choices) {
        isCorrect.items[catIndex][resIndex] = goodResponse[catIndex].choices.includes(userResponse[catIndex].choices[resIndex]) ? true : false // check if each response is in the same cat of the corrction
      }

      if (
        (goodResponse[catIndex].choices.length === userResponse[catIndex].choices.length) && // check if each cat have same length for user and correction
        (isCorrect.items[catIndex].every(x=>x===true)) // check if each res of the cat is correct
      ) {
        isCorrect.cat[catIndex] = true
      } else {
        isCorrect.cat[catIndex] = false
      }
    }
  }
  return isCorrect
}