<template>
  <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y class="pa-0">
    <v-card outlined>
      <div v-for="(item, index) in items.filter(x=>x)" :key="index">
        <v-divider v-if="item === 'divider'" class="my-1" />
        <v-list-item @click="item.method()" link v-else-if="item" :disabled="item.disabled">
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
        <v-divider v-if="index === 1 && items.filter(x=>x).length > 2" class="my-1" />
      </div>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: ['rightClickItems'],
  data() {
    return {
      items: [],
      showMenu: false,
      x: 0,
      y: 0
    }
  },
  methods: {
    show(e) {
      e.preventDefault()
      this.items = []
      for (const item of this.rightClickItems)
        this.items.push(item)
      this.showMenu = false
      this.x = e.clientX
      this.y = e.clientY
      this.$nextTick(() => {
        this.showMenu = true
      })
    }
  }
}
</script>