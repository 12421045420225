<template>
    <v-expansion-panels class="mt-3">
        <v-expansion-panel v-for="response in phoneResponses">
            <v-expansion-panel-header>
                <div class="d-flex align-center">
                    <div class="d-flex flex-column align-center">
                        <UserCard :profile="response.message.sender" :subject="response.message.subject"></UserCard>
                        <b class="mt-2 caption" v-text="new Date(response.message.date).toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris' }) + ' - ' + new Date(response.message.date).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' })"></b>
                    </div>

                    <v-spacer></v-spacer>
                    <b class="mr-3" v-text="new Date(response.date).toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris' }) + ' - ' + new Date(response.date).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' })"></b>
                </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <!-- message -->
                <p class="mb-0" v-html="response.text" style="white-space: pre-wrap;"></p>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import { getStorage, ref, getDownloadURL } from "firebase/storage"

// classes
import Contact from "@/classes/Contact"
import Phone from "@/classes/Phone"
import PhoneResponse from "@/classes/PhoneResponse"

// components
import UserCard from "@/components/UserCard"

export default {
    props: ["profile"],
    components: {
        UserCard
    },
    setup() {
        return {
            storage: getStorage()
        }
    },
    data: () => ({
        phoneResponses: [],
        unsub: []
    }),
    methods: {
        
    },
    created() {
        this.unsub.push(PhoneResponse.listenByRoomAndBySender(this.profile.currentRoom, this.profile.id, async phoneResponses => {
            let tmp_phoneResponses = []
            for (let phoneResponse of phoneResponses) {
                phoneResponse.message = await Phone.getById(phoneResponse.message)
                phoneResponse.message.sender = await Contact.getById(phoneResponse.message.sender)
                for (let attachment of phoneResponse.attachments) {
                    let fileRef = ref(this.storage, attachment.ref) 
                    getDownloadURL(fileRef)
                    .then((url) => {
                        attachment.url = url
                    })
                }
                phoneResponse.type = 'phone'
                tmp_phoneResponses.push(phoneResponse)
            }
            tmp_phoneResponses.sort((a,b)=>{
                return b.date - a.date
            })
            this.phoneResponses = tmp_phoneResponses
        }))
    },
    destroyed() {
        this.unsub.forEach(unsub => unsub());
    }
}
</script>