<template>
  <v-dialog v-model="isOpen" :persistent="profile.role === 'User'" max-width="1300px">
    <v-card v-if="form">
      <v-card-title>
        {{ nextAndPreviousUser ? userName + ' - ' : '' }}{{ form.name }}
        <v-spacer />
        <v-btn icon large>
          <v-icon large @click="onCloseClick">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider />

      <span v-if="formResponse">
        <v-carousel v-model="selectedSlide" touchless height="700px" hide-delimiters :show-arrows="form.slides.length > 1">
          <v-carousel-item v-for="slide, index of form.slides" :key="index">
            <ContextSlide v-if="slide.layout === 'context'"
              @change="i => selectedSlide = i"
              :slide="slide" :preview="true"
            />
            <QuestionSlide v-else-if="slide.layout === 'question'"
              :slide="slide" :slideIndex="index" :preview="true" :profile="profile" :formResponse="formResponse" :form="form" @save="save" @change="i => selectedSlide = i" :selectedSlide="selectedSlide" :showCorrection="checkIfShowCorrection(index)"
            />
          </v-carousel-item>
        </v-carousel>

        <v-divider />

        <div class="bottom-menu" :class="timeLeft ? 'timer' : nextAndPreviousUser ? 'admin' : ''">
          <span v-if="timeLeft">
            <div class="d-flex align-center justify-center px-3">
              <div style="min-width:30px" class="d-flex justify-center"><h3 v-text="(((timeLeft.getDate()-1)*24)+timeLeft.getHours()-1 < 10 ? '0' : '') + (((timeLeft.getDate()-1)*24)+timeLeft.getHours()-1)"></h3></div>
              <h3 class="mx-1">:</h3>
              <div style="min-width:30px" class="d-flex justify-center"><h3 v-text="(timeLeft.getMinutes() < 10 ? '0' : '') + (timeLeft.getMinutes())"></h3></div>
              <h3 class="mx-1">:</h3>
              <div style="min-width:30px" class="d-flex justify-center"><h3 v-text="(timeLeft.getSeconds() < 10 ? '0' : '') + (timeLeft.getSeconds())"></h3></div>
            </div>
            <v-divider vertical />
          </span>

          <v-tooltip right  v-if="nextAndPreviousUser">
            <template #activator="tooltip">
              <v-btn outlined class="mx-3 px-0" v-on="{ ...tooltip.on }" @click="$emit('previous')">
                <v-icon>mdi-chevron-left</v-icon>
                <v-icon>mdi-account-outline</v-icon>
              </v-btn>
            </template>
            <span>Utilisateur précédent</span>
          </v-tooltip>

          <CarouselSlideChanger :form="form" :actualSlide="selectedSlide" :editable="false" :formResponse="profile.role === 'User' ? formResponse : null"
            @change="i => selectedSlide = i"
          />

          <v-tooltip left v-if="nextAndPreviousUser">
            <template #activator="tooltip">
              <v-btn outlined class="mx-3 px-0" v-on="{ ...tooltip.on }" @click="$emit('next')">
                <v-icon>mdi-account-outline</v-icon>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </template>
            <span>Utilisateur suivant</span>
          </v-tooltip>

          <span v-if="profile.role === 'User'">
            <v-divider vertical />
            <span class="px-3">
              <v-btn icon large color="primary" @click="onCloseClick" v-if="form.correctionType !== 'realtime' && ((form.endDate === null && formResponse.isSave === true) || (form.endDate !== null && new Date().getTime() > form.endDate))">
                <v-icon large>mdi-check</v-icon>
              </v-btn>
              <v-btn icon large color="warning" @click="onSaveClick" v-else-if="form.correctionType === 'standard' && form.endDate === null">
                <v-icon large>mdi-checkbox-marked-circle-outline</v-icon>
              </v-btn>
              <v-btn icon large color="primary" @click="onSaveClick" v-else>
                <v-icon large>mdi-content-save-outline</v-icon>
              </v-btn>
            </span>
          </span>
        </div>
      </span>
    </v-card>
  </v-dialog>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'

import FormResponse from '@/classes/FormResponse'

import ContextSlide from '@/components/SlideForm/ContextSlide.vue'
import QuestionSlide from '@/components/SlideForm/QuestionSlide.vue'
import CarouselSlideChanger from '@/components/SlideForm/CarouselSlideChanger.vue'

export default {
  props: ['form', 'profile', 'mail', 'response', 'nextAndPreviousUser', 'userName'],
  components: { ContextSlide, QuestionSlide, CarouselSlideChanger },
  data() {
    return {
      isOpen: false,
      selectedSlide: 0,
      formResponse: null,
      actualDate: new Date().getTime(),
      timeLeft: null,
      timerDisplay: true,
      oldLastUpdateDate: null,

      timeoutBeforeSave: null,
      subs: []
    }
  },
  methods: {
    open() {
      this.isOpen = true
      this.selectedSlide = 0
      // admin check response :
      if (this.response)
        this.formResponse = this.response
      // user
      else {
        this.subs.push(FormResponse.listenBySenderAndByForm(this.profile.id, this.form.id, response => {
          if (response === null) {
            this.formResponse = new FormResponse(null, this.profile.currentRoom, this.form.id, this.profile.id, {}, new Date().getTime(), null)
            this.oldLastUpdateDate = new Date().getTime()
          } else {
            this.formResponse = response
            this.oldLastUpdateDate = response.lastUpdateDate
          }
        }))
      }

      if (this.form.editableResponses && this.profile.role === 'User') {
        // set an interval to keep actualdate for DOM update
        let interval = setInterval(() => {
          this.actualDate = new Date().getTime()
          if (this.form.endDate > this.actualDate) {
            this.timeLeft = new Date(this.form.endDate - this.actualDate)
          } else {
            this.timeLeft = null
            clearInterval(interval)
          }
        },250)

        // set a timeout to autamatically save when no more time
        if (this.form.endDate > new Date().getTime()) {
          this.timeoutBeforeSave = setTimeout(
            this.save,
            new Date(this.form.endDate - this.actualDate).getTime(),
            true
          )
        }
      }
    },
    onCloseClick() {
      if (this.profile.role !== 'User') 
        this.close()
      else if (this.oldLastUpdateDate === this.formResponse.lastUpdateDate)
        this.close()
      else if (this.form.correctionType !== 'realtime' && ((this.form.endDate === null && this.formResponse.isSave === true) || (this.form.endDate !== null && new Date().getTime() > (this.form.endDate + 5000))))
        this.close()
      else {
        Swal.fire({
          text: `Voulez-vous sauvegarder vos réponses ? ${this.form.correctionType === 'standard' && this.form.endDate === null ? 'Une fois sauvegarder, vous ne pourrez plus modifier vos réponses au formulaire' : ''}`,
          icon: this.form.correctionType === 'standard' && this.form.endDate === null ? 'warning' : 'question',
          showCancelButton: true,
          confirmButtonText: 'Oui',
          confirmButtonColor: this.form.correctionType === 'standard' && this.form.endDate === null ? 'var(--v-swalWarning-base)' : '',
          cancelButtonText: 'Non'
        }).then(result => {
          if (result.isConfirmed) this.save()
          else if (result.dismiss === 'cancel') {
            this.close()
          }
        })
      }
    },
    onSaveClick() {
      Swal.fire({
        text: `Voulez-vous sauvegarder vos réponses ? ${this.form.correctionType === 'standard' && this.form.endDate === null ? 'Une fois sauvegarder, vous ne pourrez plus modifier vos réponses au formulaire' : ''}`,
        icon: this.form.correctionType === 'standard' && this.form.endDate === null ? 'warning' : 'question',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        confirmButtonColor: this.form.correctionType === 'standard' && this.form.endDate === null ? 'var(--v-swalWarning-base)' : '',
        cancelButtonText: 'Annuler'
      }).then(result => {
        if (result.isConfirmed) {
          this.save()
        }
      })
    },
    async save(time) {
      if (this.form.correctionType === 'standard' && this.form.endDate === null) {
        this.formResponse.isSave = true
      }

      await this.formResponse.save()
      if (this.mail && this.form.correctionType === 'standard' && this.form.editableResponses === false) {
        this.mail.slideFormResponse.isCompleted = true
      }

      this.close()
      Swal.fire({
        title: 'Réponses enregistrées',
        icon: 'success',
        text: time ? 'Temps écoulé' : '',
        confirmButtonText: 'Ok'
      })
    },
    close() {
      this.isOpen = false
      this.formResponse = null
      clearTimeout(this.timeoutBeforeSave)
      
      for (const unsub of this.subs) {
        if (typeof unsub === 'function') unsub()
      }
    },

    checkIfShowCorrection(index) {
      if (this.profile && this.profile.role === 'User') {
        if (this.form.correctionType === 'realtime') {
          if (this.formResponse && this.formResponse.slidesValidate && this.formResponse.slidesValidate.includes(index)) {
            return true
          }
        } else {
          if (this.form.endDate === null && this.form.showCorrection === true && this.formResponse.isSave === true) {
            return true
          } else if (this.form.endDate !== null && new Date().getTime() > this.form.endDate && this.form.showCorrection === true) {
            return true
          }
        }
        return false
      } else {
        return true
      }
    }
  }
}
</script>

<style scoped>
.bottom-menu {
  display: grid;
  align-items: center;
}
.bottom-menu:not(.timer) {
  grid-template-columns: 1fr auto;
}
.bottom-menu.timer,
.bottom-menu.admin {
  grid-template-columns: auto 1fr auto;
}
</style>