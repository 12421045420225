<template>
    <div>
        <v-expansion-panels class="mt-3">
            <h3 class="my-5">Reponses aux mails :</h3>
            <v-expansion-panel v-for="mail in mails" v-if="!new Array('none').includes(mail.responseType)">
                <v-expansion-panel-header>
                    <span class="d-flex align-center my-3">
                        <UserCard :profile="mail.sender" :subject="mail.subject"></UserCard>
                        <v-spacer></v-spacer>
                        <v-btn color="success" icon outlined class="mr-5" @click.prevent.stop="exportMailResponses(mail)">
                          <v-icon>mdi-download</v-icon>
                        </v-btn>
                        <span v-text="mail.responses.length + '/' + mail.totalUserAmount"></span>
                    </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row>
                        <v-col cols="12" v-for="response in mail.responses" :key="response.id">
                            <v-card outlined class="pl-8 pa-2">
                                <span class="d-flex align-center">
                                    <UserCard :profile="response.sender"></UserCard>
                                    <v-spacer></v-spacer>
                                    <span class="text-right d-flex flex-column justify-content-between">
                                        <b class="ma-0" v-text="new Date(response.date).toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris' }) + ' ' + new Date(response.date).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' })"></b>
                                    </span>
                                    <v-btn color="error" icon @click="deleteResponse(mail, response)" v-if="profile.role != 'Corrector'">
                                        <v-icon>mdi-delete-outline</v-icon>
                                    </v-btn>
                                </span>
                                <span>
                                    <v-sheet elevation="1" class="pa-3 my-5">
                                        <div class="mb-10">
                                            <p class="pa-0 ma-0"><b>RE : </b><i v-text="mail.subject"></i></p>
                                            <!-- attachment -->
                                            <Attachment :attachments="response.attachments" />
                                        </div>
                                        <span v-if="mail.responseType == 'form'">
                                            <p v-for="question in response.form.questions" :key="question.id">
                                                <b v-text="question.text + ' : '"></b><br>
                                                <span :class="question.correct?'success--text':question.correct == null ?'secondary--text':'error--text'">
                                                    <!-- orderTheList-->
                                                    <span v-if="question.type == 'orderTheList'" class="d-flex flex-column ml-3 mt-2">
                                                        <table>
                                                            <tr v-for="(element, i) in question.response" :key="i">
                                                                <td>
                                                                    <b v-text="element" class="mr-3"></b>
                                                                </td>
                                                                <td>
                                                                    <b v-if="!question.correct" v-text="question.correctAnswer[i]" :class="'info--text'"></b>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </span>
                                                    <!-- fillTheBlank-->
                                                    <span v-else-if="question.type == 'fillTheBlank'" class="d-flex flex-wrap">
                                                        <div v-for="(part, i) in question.choices" :key="i" class="d-flex flex-wrap my-1">
                                                            <div v-if="!part.answer" v-text="part.text" class="mx-1"></div>
                                                            <div v-else class="d-flex justify-center mx-1 px-1" style="min-width:50px;outline:none;border:solid 1px;border-radius:5px">
                                                                <div v-text="question.response[i]"></div>
                                                                <div v-if="!question.correct && question.response[i] != question.correctAnswer[i]" v-text="' (' + question.correctAnswer[i] + ')'" class="info--text"></div>
                                                            </div>
                                                        </div>
                                                    </span>
                                                    <!-- classification -->
                                                    <span v-else-if="question.type == 'classification'" class="d-flex pl-3">
                                                        <div :class="question.correct ? 'success--text' : 'error--text'">
                                                            <div v-for="category in question.response" class="d-flex flex-column">
                                                                <b v-text="category.title"></b>
                                                                <p style="text-indent:25px">
                                                                    <span v-for="(res, i) in category.list" :key="i" v-text="i == 0 ? res : ' - ' + res"></span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <v-divider vertical class="mx-3" v-if="!question.correct"></v-divider>
                                                        <div class="info--text" v-if="!question.correct">
                                                            <div v-for="category in question.correctAnswer" class="d-flex flex-column">
                                                                <b v-text="category.title"></b>
                                                                <p style="text-indent:25px">
                                                                    <span v-for="(res, i) in category.list" v-text="i == 0 ? res : ' - ' + res"></span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </span>

                                                    <span class="pl-3" v-else-if="question.type == 'bool'" v-text="question.response == null ? 'Non répondu' : question.response ? 'Vrai' : 'Faux'"></span>
                                                    <span class="pl-3" v-else-if="question.type == 'number'" v-text="question.response != null ? question.response : 'Non répondu'"></span>
                                                    <span class="pl-3" v-else-if="question.type == 'dependency'" v-text="question.response != null ? question.response : 'Non répondu'"></span>
                                                    <span class="pl-3" v-else-if="question.type == 'text'" v-text="(question.response != null && question.response.trim() != '') ? question.response : 'Non répondu'"></span>
                                                    <span class="pl-3" v-else-if="question.type == 'singleChoice'" v-text="question.response != null ? question.response : 'Non répondu'"></span>
                                                    <span class="pl-3" v-else-if="question.type == 'multipleChoice'" v-text="question.response ? parseMultipleResponses(question.response) : 'Non répondu'"></span>
                                                    <span class="pl-3" v-else-if="question.type == 'list'" v-text="question.response != null ? question.response : 'Non répondu'"></span>
                                                    <textarea class="pl-3" v-else-if="question.type == 'free'" disabled style="width:100%" rows="5" :class="question.response != null ? '' : 'error--text'" v-text="question.response != null ? question.response : 'Non répondu'"></textarea>
                                                    <!-- permet d'afficher la correction -->
                                                    <span class="pl-3 info--text" v-if="!question.correct && question.type == 'bool'" v-text="question.correctAnswer ? '(Vrai)' : '(Faux)'"></span>
                                                    <span class="pl-3 info--text" v-else-if="!question.correct && question.type == 'number'" v-text="'(' + question.correctAnswer + ')'"></span>

                                                    <span class="pl-3" v-else-if="question.type == 'dependency'">
                                                        <!-- if bad response compare to think | bad response compare to real -->
                                                        <span v-if="question.response != getEvalValue(response.form.questions, question, 'response')">
                                                            <span v-if="question.response != getEvalValue(response.form.questions, question, 'correctAnswer')">
                                                                <!-- tout faux --><span class="info--text" v-text="'(' + getEvalValue(response.form.questions, question, 'correctAnswer') + ')'"></span>
                                                            </span>
                                                            <span v-else>
                                                                <!-- reponse vraie mais pas logique -->
                                                                <span class="error--text" v-if="question.response != getEvalValue(response.form.questions, question, 'response') && question.response == getEvalValue(response.form.questions, question, 'correctAnswer')">
                                                                    <v-icon small color="error">mdi-alert-circle-outline</v-icon>
                                                                    Bonne réponse mais raisonnement incohérant.
                                                                </span>
                                                            </span>
                                                        </span>
                                                        <span v-else>
                                                            <span v-if="question.response != getEvalValue(response.form.questions, question, 'correctAnswer')">
                                                                <!-- reponse fausse mais logique -->
                                                                <span class="warning--text" v-if="question.response == getEvalValue(response.form.questions, question, 'response') && question.response != getEvalValue(response.form.questions, question, 'correctAnswer')">
                                                                    Mauvaise réponse mais raisonnement cohérant.
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>

                                                    <span class="pl-3 info--text" v-else-if="!question.correct && question.type == 'text'" v-text="'(' + question.correctAnswer + ')'"></span>
                                                    <span class="pl-3 info--text" v-else-if="!question.correct && question.type == 'singleChoice'" v-text="'(' + question.correctAnswer + ')'"></span>
                                                    <span class="pl-3 info--text" v-else-if="!question.correct && question.type == 'multipleChoice'" v-text="'(' + parseMultipleResponses(question.correctAnswer) + ')'"></span>
                                                    <span class="pl-3 info--text" v-else-if="!question.correct && question.type == 'list'" v-text="'(' + question.correctAnswer + ')'"></span>
                                                </span>
                                            </p>
                                        </span>
                                        <p v-else v-html="response.message" style="white-space: pre-wrap;"></p>

                                        <v-divider></v-divider>
                                        <div class="mt-8 mb-3">
                                            <b class="pa-0 ma-0" v-text="response.sender.lastname.toUpperCase() + ' ' + response.sender.firstname"></b>
                                        </div>
                                    </v-sheet>
                                </span>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <h3 class="my-5">Reponses aux messages vocaux :</h3>
            <v-expansion-panel v-for="message in phoneMessages" v-if="!new Array('none').includes(message.responseType)">
                <v-expansion-panel-header>
                    <span class="d-flex align-center my-3">
                        <UserCard :profile="message.sender" :subject="message.subject"></UserCard>
                        <v-spacer></v-spacer>
                        <span v-text="message.responses.length + '/' + message.totalUserAmount"></span>
                    </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row>
                        <v-col cols="12" v-for="response in message.responses">
                            <v-card outlined class="pl-8 pa-2">
                                <span class="d-flex align-center">
                                    <UserCard :profile="response.sender"></UserCard>
                                    <v-spacer></v-spacer>
                                    <span class="text-right d-flex flex-column justify-content-between">
                                        <b class="ma-0" v-text="new Date(response.date).toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris' }) + ' ' + new Date(response.date).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' })"></b>
                                    </span>
                                    <v-btn color="error" icon @click="deletePhoneResponse(message, response)">
                                        <v-icon>mdi-delete-outline</v-icon>
                                    </v-btn>
                                </span>
                                <span>
                                    <v-sheet elevation="1" class="pa-3 my-5">
                                        <div class="mb-10">
                                            <p class="pa-0 ma-0"><b>RE : </b><i v-text="message.subject"></i></p>
                                            <!-- attachment -->
                                            <Attachment :attachments="response.attachments" />
                                        </div>
                                        <p v-html="response.text" style="white-space: pre-wrap;"></p>

                                        <v-divider></v-divider>
                                        <div class="mt-8 mb-3">
                                            <b class="pa-0 ma-0" v-text="response.sender.lastname.toUpperCase() + ' ' + response.sender.firstname"></b>
                                        </div>
                                    </v-sheet>
                                </span>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
          <v-dialog v-model="loader" max-width="600" persistent>
            <v-card>
              <v-card-text class="pt-5">
                <div><h1 class="pb-1 text-center">Exportation en cours</h1></div>
                <div><h2 class="pb-1 text-center">{{ loadingState + "%" }}</h2></div>
                <v-progress-linear :value="loadingState" color="primary" rounded height="20"></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
        

        <v-col cols="12" class="mt-5 pa-3 d-flex justify-end">
          <v-btn color="success" fab @click.prevent.stop="exportAllResponses()">
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </v-col>
        <v-divider></v-divider>
    </div>
</template>

<script>
import JSZip from "jszip"
import downloader from "@/assets/functions/downloader"

import logger from "@/assets/functions/logger"
import { getStorage, ref, getDownloadURL,getBlob,getMetadata, deleteObject } from "firebase/storage"

import Swal from 'sweetalert2/dist/sweetalert2.js'

import Contact from "@/classes/Contact"
import Mail from "@/classes/Mail"
import MailResponse from "@/classes/MailResponse"
import Phone from "@/classes/Phone"
import PhoneResponse from "@/classes/PhoneResponse"

import UserCard from "@/components/UserCard"
import Attachment from "@/components/Attachment"
import { get } from 'lodash'

export default {
    props: ['profile', 'notifications', 'currentRoom', 'contactsTable'],
    components: {
        UserCard,
        Attachment
    },
    setup() {
        return {
            storage: getStorage()
        }
    },
    data() {
        return {
            unsub: [],
            mails: [],
            phoneMessages: [],
            totalUserAmount: null,
            loadingState: 0,
            loader: false,
        }
    },
    async created() {
        this.totalUserAmount = 0
        for (let index in this.contactsTable){
            if(this.contactsTable[index].uid != undefined && this.contactsTable[index].role != undefined && this.contactsTable[index].role == 'User'){
                this.totalUserAmount++
            }
        }
        // mail send by one contact
        Contact.getByRoom(this.profile.currentRoom).then(data => {
            let tmp_mails = []
            for (const contact of data) {
                this.unsub.push(Mail.listenByRoomAndBySender(this.profile.currentRoom, contact.id, async data => {
                    for (const mail of data) {
                        if(mail.responseType != 'none') {
                            // sender
                            mail.sender = this.contactsTable[mail.sender]
                            // receiver
                            if (mail.receiver != 'all') mail.receiver = this.contactsTable[mail.receiver]
                            if (mail.receiver.uid) mail.totalUserAmount = 1
                            else if (mail.receiver.members) mail.totalUserAmount = mail.receiver.members.length
                            else mail.totalUserAmount = this.totalUserAmount
                            // attachments
                            for(const attachment of mail.attachments) {
                                const fileRef = ref(this.storage, attachment.ref) 
                                getDownloadURL(fileRef)
                                .then((url) => {
                                    attachment.url = url
                                })
                            }
                            // responses
                            const mailResponses = await MailResponse.getByMail(mail.id)
                            for (const mailResponse of mailResponses) {
                                mailResponse.sender = this.contactsTable[mailResponse.sender]
                                for (const attachment of mailResponse.attachments) {
                                    const fileRef = ref(this.storage, attachment.ref) 
                                    getDownloadURL(fileRef)
                                    .then(url => {
                                        attachment.url = url
                                    })
                                }
                            }
                            mail.responses = mailResponses
                            if(mail.responseType == "form"){
                            }
                            tmp_mails.push(mail)
                        }
                    }
                    tmp_mails.sort((a,b)=>{
                        return b.date - a.date
                    })
                }))
            }
            this.mails = tmp_mails
        })

        this.unsub.push(Phone.listenByRoom(this.profile.currentRoom, async data => {
            const tmp_phones = []
            for (const phone of data) {
                if(phone.responseType != 'none') {
                    // sender
                    phone.sender = this.contactsTable[phone.sender]
                    // receiver
                    phone.totalUserAmount = this.totalUserAmount
                    /**
                     * if (phone.receiver != 'all') phone.receiver = this.contactsTable[phone.receiver]
                     * if (phone.receiver.uid) phone.totalUserAmount = 1
                     * else if (phone.receiver.members) phone.totalUserAmount = phone.receiver.members.length
                     * else phone.totalUserAmount = this.totalUserAmount
                     */
                    // responses
                    phone.responses = []
                    const phoneResponses = await PhoneResponse.getByPhone(phone.id)
                    for (const phoneResponse of phoneResponses) {
                        phoneResponse.sender = this.contactsTable[phoneResponse.sender]
                        for (const attachment of phoneResponse.attachments) {
                            const fileRef = ref(this.storage, attachment.ref) 
                            getDownloadURL(fileRef)
                            .then(url => {
                                attachment.url = url
                            })
                        }
                        phone.responses.push(phoneResponse)
                    }
                    tmp_phones.push(phone)
                }
            }
            tmp_phones.sort((a,b)=>{
                return b.date - a.date
            })
            this.phoneMessages = tmp_phones
        }))

    },
    methods: {
        parseMultipleResponses(response){
            if(response && response.length > 0){
                let tmp_response = ""
                for(let i = 0; i < response.length; i++){
                    tmp_response += response[i]
                    if(i < response.length - 2) tmp_response += ", "
                    else if(i < response.length - 1) tmp_response += " et "
                }
                return tmp_response
            }else{
                return ""
            }
        },
        openFile(attachment) {
            window.open(attachment.url)
        },
        deleteResponse(mail, response) {
            Swal.fire({
                title: "Etes-vous sûr ?",
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Oui",
                cancelButtonText: "Annuler",
            }).then((result) => {
                if(result.isConfirmed){
                    for(let attachment of response.attachments) {
                        let fileRef = ref(this.storage, attachment.ref)
                        deleteObject(fileRef)
                    }
                    mail.responses = mail.responses.filter(mailResponse => mailResponse.id != response.id)
                    response.delete()
                    logger.log(this.profile.id, "RESPONSE DELETED", this.profile.email + " a supprimé la reponse " + response.id)
                }
            })
        },
        deletePhoneResponse(message, response) {
            Swal.fire({
                title: "Etes-vous sûr ?",
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Oui",
                cancelButtonText: "Annuler",
            }).then((result) => {
                if(result.isConfirmed){
                    for(let attachment of response.attachments) {
                        let fileRef = ref(this.storage, attachment.ref)
                        deleteObject(fileRef)
                    }
                    message.responses = message.responses.filter(response => response.id != response.id)
                    logger.log(this.profile.id, "RESPONSE DELETED", this.profile.email + " a supprimé la reponse vocal" + response.id)
                    response.delete()
                }
            })
        },
        getEvalValue(questions, question, returnType){
            let varList = new Set(this.getVarList(question))
            let result = question.correctAnswer

            for(let questionIndex of varList){
                let varName = 'Q' + questionIndex

                switch(returnType){
                    case 'correctAnswer':
                        result = result.replaceAll(varName, (parseInt(questions[questionIndex-1].correctAnswer)))
                        break
                    case 'response':
                        result = result.replaceAll(varName, (parseInt(questions[questionIndex-1].response)))
                        break
                    default: break
                }
            }
            return eval(result)
        },
        /**
         * return every variable (not index) of dependency question
         * @param {*} question
         * @return list of question number (without the 'Q')
         */
        getVarList(question){
            let varList = []
            const charList = question.correctAnswer.split('')
            let isAQuestion = false
            let i = 0
            for(let char of charList){
                if(char == 'Q'){
                    isAQuestion = true
                }else if(isAQuestion == true && char == char.match(/[0-9]/g)){
                    if(charList[i-1] == 'Q') varList.push(char)
                    else varList[varList.length-1] += char
                }else{
                    isAQuestion = false
                }
                i++
            }
            return varList
        },

        async exportMailResponses(mail){
          let zip = new JSZip()

          let name = mail.subject
          
          let counter = 0
          this.loadingState = 0
          this.loader = true

          let maxCounter = mail.responses.length + mail.attachments.length + 2
          
          let mailData = ""
          mailData += "Mail envoyé par : " + mail.sender.lastname + " " + mail.sender.firstname + "\n"
          mailData += "Sujet : " + mail.subject + "\n"
          mailData += "Date : " + new Date(mail.date).toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris' }) + " " + new Date(mail.date).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' }) + "\n"
          mailData += "Pieces jointes : " + mail.attachments.length + "\n"
          for(let attachment of mail.attachments){
            mailData += "   -PJ-"+attachment.name + "\n"
          }
          mailData += "Debut du message : \n"
          mailData += "------------------------------ \n" + "\n"
          mailData += mail.message + "\n" + "\n"
          mailData += "------------------------------ \n"
          mailData += "Fin du message \n"

          mailData = mailData.replace(/<[^>]+>/g, ' ').trim().replaceAll("  ", " ").replaceAll("Invalid Date Invalid Date", "Date invalide")

          let buffer = Buffer.from(mailData, 'utf-8')
          zip.file("Mail.txt", buffer)

          for(let attachment of mail.attachments){            
            let fileRef = ref(this.storage, attachment.ref) 
            let fileBlob = await getBlob(fileRef)

            zip.file("PJ-" + attachment.name, fileBlob)

            counter ++
            this.loadingState = Math.round(counter / maxCounter *100)
          }

          for(let response of mail.responses){
            let path = "Reponses/"+response.sender.lastname + " " + response.sender.firstname+"/"

            let responseData = ""
            responseData += "Réponse de : " + response.sender.lastname + " " + response.sender.firstname + "\n"
            responseData += "Date : " + new Date(response.date).toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris' }) + " " + new Date(response.date).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' }) + "\n" + "\n"
            responseData += "Pieces jointes : " + response.attachments.length + "\n"
            for(let attachment of response.attachments){
              responseData += "   -PJ-"+attachment.name + "\n"
            }
            responseData += "Debut du message : \n"
            responseData += "------------------------------ \n" + "\n"
            responseData += response.message + "\n" + "\n"
            responseData += "------------------------------ \n"
            responseData += "Fin du message \n"

            responseData = responseData.replace(/<[^>]+>/g, ' ').trim().replaceAll("  ", " ").replaceAll("Invalid Date Invalid Date", "Date invalide")

            let buffer = Buffer.from(responseData, 'utf-8')
            zip.file(path + "Mail.txt", buffer)


            for(let attachment of response.attachments){              
              let fileRef = ref(this.storage, attachment.ref) 
              let fileBlob = await getBlob(fileRef)

              zip.file(path + "PJ-" + attachment.name, fileBlob)
            }
            counter ++
            this.loadingState = Math.round(counter / maxCounter * 100)
          }

          let zipBlob = await zip.generateAsync({type:"blob"})
          counter++
          this.loadingState = Math.round(counter / maxCounter * 100)
          await downloader.downloadBlob(zipBlob, name+".zip")
          counter++
          this.loadingState = Math.round(counter / maxCounter * 100)
          setTimeout(() => {
            this.loader = false
          }, 300)
        },

        async exportAllResponses(){
          let zip = new JSZip()

          let counter = 0
          this.loadingState = 0
          this.loader = true
          for(let mail of this.mails){
            let name = mail.subject
            let mailDataPath = "Enoncés/"
            
            let mailData = ""
            mailData += "Mail envoyé par : " + mail.sender.lastname + " " + mail.sender.firstname + "\n"
            mailData += "Sujet : " + mail.subject + "\n"
            mailData += "Date : " + new Date(mail.date).toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris' }) + " " + new Date(mail.date).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' }) + "\n"
            mailData += "Pieces jointes : " + mail.attachments.length + "\n"
            for(let attachment of mail.attachments){
              mailData += "   -PJ-"+attachment.name + "\n"
            }
            mailData += "Debut du message : \n"
            mailData += "------------------------------ \n" + "\n"
            mailData += mail.message + "\n" + "\n"
            mailData += "------------------------------ \n"
            mailData += "Fin du message \n"

            mailData = mailData.replace(/<[^>]+>/g, ' ').trim().replaceAll("  ", " ").replaceAll("Invalid Date Invalid Date", "Date invalide")

            let buffer = Buffer.from(mailData, 'utf-8')
            zip.file(mailDataPath + name +"/" + "Mail.txt", buffer)

            for(let attachment of mail.attachments){            
              let fileRef = ref(this.storage, attachment.ref) 
              let fileBlob = await getBlob(fileRef)
              zip.file(mailDataPath + name +"/" + "PJ-" + attachment.name, fileBlob)
            }

            for(let response of mail.responses){
              let path = "Reponses/"+response.sender.lastname + " " + response.sender.firstname+"/"+name+"/"
              let compactedPath = "Reponses compactés/"+response.sender.lastname + " " + response.sender.firstname+"_"+name+"_"

              let responseData = ""
              responseData += "Réponse de : " + response.sender.lastname + " " + response.sender.firstname + "\n"
              responseData += "Date : " + new Date(response.date).toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris' }) + " " + new Date(response.date).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' }) + "\n" + "\n"
              responseData += "Pieces jointes : " + response.attachments.length + "\n"
              for(let attachment of response.attachments){
                responseData += "   -PJ-"+attachment.name + "\n"
              }
              responseData += "Debut du message : \n"
              responseData += "------------------------------ \n" + "\n"
              responseData += response.message + "\n" + "\n"
              responseData += "------------------------------ \n"
              responseData += "Fin du message \n"

              responseData = responseData.replace(/<[^>]+>/g, ' ').trim().replaceAll("  ", " ").replaceAll("Invalid Date Invalid Date", "Date invalide")

              let buffer = Buffer.from(responseData, 'utf-8')
              zip.file(path + "Mail.txt", buffer)
              zip.file(compactedPath + "Mail.txt", buffer)
            
              for(let attachment of response.attachments){              
                let fileRef = ref(this.storage, attachment.ref) 
                let fileBlob = null
                fileBlob = await getBlob(fileRef)

                zip.file(path + "PJ-" + attachment.name, fileBlob)
                zip.file(compactedPath + "PJ-" + attachment.name, fileBlob)
              }
            }
            counter++
            this.loadingState = Math.round(counter / (this.mails.length +2) *100)
          }
          let zipBlob = await zip.generateAsync({type:"blob"})
          counter++
          this.loadingState = Math.round(counter / (this.mails.length +2) *100)
          await downloader.downloadBlob(zipBlob, "Mails.zip")
          counter++
          this.loadingState = Math.round(counter / (this.mails.length +2) *100)
          setTimeout(() => {
            this.loader = false
          }, 300)
        }
    },
    destroyed() {
        this.unsub.forEach(unsub => unsub())
    },
}
</script>
