import envManager from '@/assets/functions/envManager'
import { ListenWhere, GetWhere } from '@/assets/functions/getData'
import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from 'firebase/firestore'

const db = getFirestore()
const collectionName = envManager.getPrefix() + 'formResponse'
const collectionRef = collection(db, collectionName)

/**
 * Convert the data received by Firebase into usable data for the application.
 * @param {string} id The id of the document
 * @param {object} data An Object containing all fields in the document.
 * @returns An object that can be used in the application.
 */
const dataToObject = (id, data) => {
  return new FormResponse(id, data.room, data.form, data.sender, data.responses, data.lastUpdateDate, data.slidesValidate, data.isSave)
}
const keepOnlyLatest = responses => {
  if (responses.length === 0) return null

  let kept = null
  let latest = 0

  responses.forEach(object => {
    let lastUpdateDate = object.lastUpdateDate

    // if new is after old
    if (lastUpdateDate > latest) {
      // delete previous kept
      if (kept !== null) {
        kept.delete()
      }

      // keep new object
      kept = object
      latest = object.lastUpdateDate
    } else {
      object.delete()
    }
  })

  return kept
}

class FormResponse {
  constructor(id, room, form, sender, responses, lastUpdateDate, slidesValidate = [], isSave = null) {
    this.id = id
    this.room = room
    this.form = form
    this.sender = sender
    this.responses = responses
    this.lastUpdateDate = lastUpdateDate
    this.slidesValidate = slidesValidate
    this.isSave = isSave
  }
  
  // get
  static async getBySender(sender) {
    const documentsQuery = query(collectionRef, where('sender', '==', sender))
    return await GetWhere(documentsQuery, dataToObject)
  }
  static async getByForm(form) {
    const documentsQuery = query(collectionRef, where('form', '==', form))
    return await GetWhere(documentsQuery, dataToObject)
  }
  static async getBySenderAndByForm(sender, form) {
    const documentsQuery = query(collectionRef, where('sender', '==', sender), where('form', '==', form))
    return keepOnlyLatest(await GetWhere(documentsQuery, dataToObject))
  }
  
  // listen
  static async listenByRoom(room, callback) {
    const documentsQuery = query(collectionRef, where('room', '==', room))
    return await ListenWhere(documentsQuery, dataToObject, callback)
  }
  static async listenBySender(sender, callback) {
    const documentsQuery = query(collectionRef, where('sender', '==', sender))
    return await ListenWhere(documentsQuery, dataToObject, callback)
  }
  static async listenBySenderAndByForm(sender, form, callback) {
    const documentsQuery = query(collectionRef, where('sender', '==', sender), where('form', '==', form))
    return await ListenWhere(documentsQuery, dataToObject, callback, keepOnlyLatest)
  }

  async save() {
    const savedObject = {
      room: this.room,
      form: this.form,
      sender: this.sender,
      responses: this.responses,
      lastUpdateDate: this.lastUpdateDate,
      slidesValidate: this.slidesValidate,
      isSave: this.isSave
    }

    if (this.id === null) {
      this.id = (await addDoc(collectionRef, savedObject)).id
    } else {
      await updateDoc(doc(db, collectionName, this.id), savedObject)
    }
  }

  async delete() {
    const documentRef = doc(db, collectionName, this.id)
    await deleteDoc(documentRef)
  }
}

export default FormResponse