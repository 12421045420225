<template>
    <v-dialog v-model="displayed" max-width="1000px">
        <v-card>
            <v-card-title>
                Nouvelle conversation
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" :md="realPeople ? '6' : '12'">
                        <v-text-field label="Rechercher" @input="filterLists" v-model="filterTxt" />
                    </v-col>
                    <v-col cols="12" md="6" v-if="realPeople">
                        <v-select :items="roles" v-model="roleSelected" label="Rôle"></v-select>
                    </v-col>
                </v-row>
                
                <div style="overflow:auto;max-height:60vh">
                    <!-- multiple selection -->
                    <v-list v-if="multipleSelection">
                        <h3>Sélection actuelle :</h3>
                        <v-list-item v-for="user, index in displaySelection" :key="index" @click="removeFromSelection(index)">
                            <v-list-item-content>
                                <UserCard :profile="user"></UserCard>
                            </v-list-item-content>
                        </v-list-item>
                        
                        <v-col cols="12" class="d-flex justify-end">
                            <v-btn color="primary" fab @click="close('end')" outlined>
                                <v-icon>mdi-check</v-icon>
                            </v-btn>
                        </v-col>
                    </v-list>
                    
                    <!-- groups or all -->
                    <v-list v-if="all || groups">
                        <h3>Groupe{{ all && groups ? 's' : '' }} :</h3>
                        <v-list-item @click="close('all')">
                            <v-list-item-content>
                                <div class="d-flex align-center">
                                    <UserAvatar :group="true"></UserAvatar>
                                    <div class="ml-3">Tous les utilisateurs présents dans la room.</div>
                                </div>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-for="group of displayLists.groups" :key="group.id" @click="close(group)">
                            <v-list-item-content>
                                <div class="d-flex align-center">
                                <UserAvatar :group="true"></UserAvatar>
                                    <div class="ml-3" v-text="group.name"></div>
                                </div>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>

                    <!-- SuperAdmins -->
                    <v-list v-if="displayLists.superAdmins.length > 0 && new Array('Tous', 'Super Administrateur').includes(roleSelected)">
                        <h3>Super Administrateurs :</h3>
                        <v-list-item v-for="user of displayLists.superAdmins" :key="user.id" @click="close(user)">
                            <v-list-item-content>
                                <UserCard :profile="user"></UserCard>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>

                    <!-- admins -->
                    <v-list v-if="displayLists.admins.length > 0 && new Array('Tous', 'Administrateur').includes(roleSelected)">
                        <h3>Administrateurs :</h3>
                        <v-list-item v-for="user in displayLists.admins" :key="user.id" @click="close(user)">
                            <v-list-item-content>
                                <UserCard :profile="user"></UserCard>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>

                    <!-- correctors -->
                    <v-list v-if="displayLists.correctors.length > 0 && new Array('Tous', 'Correcteur').includes(roleSelected)">
                        <h3>Correcteurs :</h3>
                        <v-list-item v-for="user of displayLists.correctors" :key="user.id" @click="close(user)">
                            <v-list-item-content>
                                <UserCard :profile="user"></UserCard>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>

                    <!-- users -->
                    <v-list v-if="displayLists.users.length > 0 && new Array('Tous', 'Utilisateur').includes(roleSelected)">
                        <h3>Utilisateurs :</h3>
                        <v-list-item v-for="user of displayLists.users" :key="user.id" @click="close(user)">
                            <v-list-item-content>
                                <UserCard :profile="user"></UserCard>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>

                    <!-- contacts -->
                    <v-list v-if="displayLists.contacts.length > 0">
                        <h3>Contacts :</h3>
                        <v-list-item v-for="contact of displayLists.contacts" :key="contact.id" @click="close(contact)" v-if="!displaySelection.map(x => x.id).includes(contact.id)">
                            <v-list-item-content>
                                <UserCard :profile="contact" :mailAndJob="true"></UserCard>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>

                    <h3 v-if="displayLists.users.length === 0 && displayLists.correctors.length === 0 && displayLists.admins.length === 0 && displayLists.superAdmins.length === 0 && displayLists.contacts.length === 0">Aucun utilisateur n'a été trouvé</h3>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
// classes
import Profile from "@/classes/Profile"
import Room from "@/classes/Room"
import Contact from "@/classes/Contact"
import Group from "@/classes/Group"

// components
import UserAvatar from "@/components/UserAvatar"
import UserCard from "@/components/UserCard"

export default {
    props: ['profile', 'blacklist', 'selected', 'with'],
    components: { UserAvatar, UserCard },
    data: () => ({
        displayed: false,

        // what you want in the modal
        multipleSelection: null,
        realPeople: null, // = people you have access, according to your role
        users: null,
        contacts: null,
        all: null,
        groups: null,

        // filtre
        roles: ['Tous'],
        roleSelected: 'Tous',
        filterTxt: '',

        // data
        selection: [],
        dataLists: {
            superAdmins: [],
            admins: [],
            animators: [], // display with admins
            correctors: [],
            users: [],
            contacts: [],
            groups: []
        },
        displaySelection: [],
        displayLists: {
            superAdmins: [],
            admins: [],
            correctors: [],
            users: [],
            contacts: [],
            groups: []
        },

        unsub: []
    }),
    methods: {
        open() {
            this.displayed = true

            if (this.multipleSelection && this.selected) {
                for (let key in this.dataLists) {
                    this.dataLists[key].forEach(user => {
                        if (this.selected.includes(user.id)) this.selection.push(user)
                    })
                }
            }

            this.filterLists()
        },
        async close(user) {
            if (this.multipleSelection && user != 'end') {
                this.selection.push(user)
                this.filterLists()
            } else {
                if (user === 'end') this.$emit("convSelected", this.selection)
                else this.$emit("convSelected", user)

                this.displayed = false
                this.selection = []
            }
        },
        removeFromSelection(index) {
            this.selection.splice(index, 1)
            this.filterLists()
        },
        /**
         * filters all the data lists and creates displayed lists |
         * then filter all displayed lists with search bar value
         */
        filterLists() {
            this.blacklist.push(this.profile.id)
            
            // creation of filtered and displayed lists
            this.displaySelection = []
            for (let key in this.dataLists) {
                // init
                this.displayLists[key] = []

                // includes all exept blacklisted and selected
                this.dataLists[key].forEach(user => {
                    // selected
                    if (this.selection.map(x => x.id).includes(user.id))
                        this.displaySelection.push(user)

                    // blacklisted
                    else if (!this.blacklist.includes(user.id)) {
                        if (key === 'animators')
                            this.displayLists.admins.push(user)
                        else
                            this.displayLists[key].push(user)
                    }
                })
            }

            // search bar filter
            for (let key in this.displayLists) {
                this.displayLists[key] = this.displayLists[key].filter(item => {
                    return ((item.firstname + ' ' + item.lastname).toLowerCase().includes(this.filterTxt.toLowerCase()) || (item.lastname + ' ' + item.firstname).toLowerCase().includes(this.filterTxt.toLowerCase()))
                })
            }
            this.displaySelection = this.displaySelection.filter(item => {
                return ((item.firstname + ' ' + item.lastname).toLowerCase().includes(this.filterTxt.toLowerCase()) || (item.lastname + ' ' + item.firstname).toLowerCase().includes(this.filterTxt.toLowerCase()))
            })

            // v-select init
            if (this.realPeople && this.filterTxt === '') {
                this.roles = ['Tous']
                if (this.displayLists.superAdmins.length > 0) this.roles.push('Super Administrateur')
                if (this.displayLists.admins.length > 0) this.roles.push('Administrateur')
                if (this.displayLists.correctors > 0) this.roles.push('Correcteur')
                if (this.displayLists.users.length > 0) this.roles.push('Utilisateur')
            }

            this.sortDisplayedLists()
        },
        /**
         * sorts all lists in displayedList by last name then by first name
         */
        sortDisplayedLists() {
            for (let key in this.displayLists) this.sortOneList(this.displayLists[key])
            this.sortOneList(this.displaySelection)
        },
        sortOneList(lsit) {
            lsit.sort((a, b) => {
                if (a.lastname > b.lastname)
                    return 1
                else if (b.lastname > a.lastname)
                    return -1
                else if (a.firstname > b.firstname)
                    return 1
                else if (b.firstname > a.firstname)
                    return -1
                else
                    return 0
            })
        }
    },
    async created() {
        if (this.with) {
            this.multipleSelection = this.with.includes('multipleSelection')
            this.realPeople = this.with.includes('realPeople')
            this.contacts = this.with.includes('contacts')
            this.all = this.with.includes('all')
            this.groups = this.with.includes('groups')
            this.users = this.with.includes('users')
        }

        /**
         * SuperAdmin -> tous
         *      Admin -> par licence = licence de la room active
         *   Animator -> par room = room active
         *  Corrector -> par room = room active
         *       User -> par room = room active (uniquement si profile.role != User) 
         */
        if (this.realPeople && this.profile.role === "SuperAdmin") {
            // need 1 more request when superAdmin to get the licence
            let room = await Room.getById(this.profile.currentRoom)
            this.unsub.push(Profile.listenByLicenceAndByRole(room.licence, "Admin", tmp_users => {
                this.dataLists.admins = tmp_users
            }))
        }
        if (this.realPeople && new Array("User", "Corrector", "Animator", "Admin", "SuperAdmin").includes(this.profile.role)) {
            this.unsub.push(Profile.listenByRole("SuperAdmin", tmp_users => {
              if(!this.profile.dev){
                tmp_users = tmp_users.filter(user => user.email != "morgan.guillemin@loesuspedagogia.fr")
              }
              this.dataLists.superAdmins = tmp_users
            }))
            if (this.profile.role != 'SuperAdmin') {
                this.unsub.push(Profile.listenByLicenceAndByRole(this.profile.licence, "Admin", tmp_users => {
                    this.dataLists.admins = tmp_users
                }))
            }
            this.unsub.push(Profile.listenByRoomAndByRole(this.profile.currentRoom, "Animator", tmp_users => {
                this.dataLists.animators = tmp_users
            }))
            this.unsub.push(Profile.listenByRoomAndByRole(this.profile.currentRoom, "Corrector", tmp_users => {
                this.dataLists.correctors = tmp_users
            }))
        }
        if (this.users || (this.realPeople && new Array("User", "Corrector", "Animator", "Admin", "SuperAdmin").includes(this.profile.role))) {
            this.unsub.push(Profile.listenByRoomAndByRole(this.profile.currentRoom, "User", tmp_users => {
                this.dataLists.users = tmp_users
            }))
        }

        if (this.groups) {
            this.unsub.push(Group.listenByRoom(this.profile.currentRoom, tmp_groups => {
                this.dataLists.groups = tmp_groups
            }))
        }

        if (this.contacts) {
            this.unsub.push(Contact.listenByRoom(this.profile.currentRoom, (tmp_contacts) => {
                this.dataLists.contacts = tmp_contacts
            }))
        }
    },
    destroyed() {
        this.unsub.forEach(unsub => unsub())
    }
}
</script>