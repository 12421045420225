<template>
  <div @mouseover="addToRightClick" @mouseleave="delToRightClick">
    <div class="d-flex justify-space-between gap-l">
      <div v-for="(choice, index) in question.choices" :key="index"
        @mouseover="addItemToRightClick(index)" @mouseleave="delItemToRightClick"
      >
        <p class="mb-0" v-if="preview">{{ choice }}</p>
        <v-text-field v-else hide-details class="ma-0 pa-0" v-model="question.choices[index]" />
      </div>
    </div>
    <div v-if="showCorrection">
      <v-slider hide-details class="mt-2 slider" :max="question.choices.length - 1" ticks v-model="formResponse.responses[slideIndex][questionIndex]" readonly :color="isCorrect ? 'success' : 'error'" />
      <v-slider hide-details class="mt-2 slider" :max="question.choices.length - 1" ticks v-if="!isCorrect" v-model="question.goodResponse" readonly color="info" />
    </div>

    <v-slider hide-details class="mt-2 slider" :max="question.choices.length - 1" ticks v-else-if="profile && profile.role === 'User'" v-model="formResponse.responses[slideIndex][questionIndex]" :disabled="disabled" @click="formResponse.lastUpdateDate = new Date().getTime()" />
    <v-slider hide-details class="mt-2 slider" :max="question.choices.length - 1" ticks v-else-if="question.choices !== undefined && preview" />
    <v-slider hide-details class="mt-2 slider" :max="question.choices.length - 1" ticks v-else-if="question.choices !== undefined" v-model="question.goodResponse" />
  </div>
</template>

<script>
import Correction from '@/assets/functions/corrections'

export default {
  props: ['question', 'preview', 'rightClickItems', 'profile', 'formResponse', 'slideIndex', 'questionIndex', 'disabled', 'showCorrection'],
  data() {
    return {
      isCorrect: false
    }
  },
  watch: {
    showCorrection() {
      this.runCorrection()
    }
  },
  mounted() {
    if (this.showCorrection) {
      this.runCorrection()
    }
  },
  methods: {
    addToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[10] = 'divider'
        this.rightClickItems[11] = {
          text: 'Ajouter une réponse',
          method: () => {
            this.question.choices.push('')
            this.$forceUpdate()
          }
        }
      }
    },
    delToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[10] = null
        this.rightClickItems[11] = null
      }
    },
    addItemToRightClick(i) {
      if (this.rightClickItems) {
        this.rightClickItems[12] = {
          text: 'Supprimer la réponse',
          method: () => {
            this.question.choices.splice(i,1)
            this.$forceUpdate()
          }
        }
      }
    },
    delItemToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[12] = null
      }
    },

    runCorrection() {
      this.isCorrect = Correction('slider', this.formResponse.responses[this.slideIndex][this.questionIndex], this.question.goodResponse)
    }
  }
}
</script>

<style scoped>
.slider {
  width: 500px;
  max-width: 100%;
}
</style>