<template>
    <span v-if="profile.role != 'User'">
        <span class="d-flex flex-row justify-start align-center">
            <p class="mr-3">Nombre de choix :</p>
            <v-text-field type="number" v-model="question.choicesAmount" @input="updateQuestionChoices(question)" dense />
        </span>
        <span>
            <p>Choix :</p>
            <v-text-field v-for="choice, i in question.choices" v-model="choice.text" type="text" :label="'Réponse n°' + (i+1)" outlined dense />
        </span>
        <span>
            <div>Correction :</div>
            <v-row class="pt-0 mt-2">
                <v-checkbox v-for="choice in question.choices" v-model="question.correctAnswer" :label="choice.text" :value="choice.text" :multiple="true" class="mx-2 pt-0 mt-0"></v-checkbox>
            </v-row>
        </span>
    </span>

    <span v-else>
        <h4 v-text="question.text"></h4>
        <div class="d-flex flex-wrap">
            <v-checkbox v-for="choice in question.choices" v-model="question.response" :label="choice.text" :value="choice.text" :multiple="true" class="mx-2 pt-0 mt-2" style="margin-bottom:-15px" @click="$emit('edited')"></v-checkbox>
        </div>
    </span>
</template>

<script>
export default {
    props: ["profile", "question"],
    data: () => ({
        response: {
            valide: false,
            errorMessage: ''
        }
    }),
    methods: {
        updateQuestionChoices(question){
            question.choices = []
            for (let i = 0; i < question.choicesAmount; i++) {
                question.choices.push({
                    text: null,
                })
            }
        },
        testIsValid(){
            // titre
            if(!this.question.text || this.question.text.trim() == ''){
                this.response.errorMessage = 'Veuillez renseigner un titre à toutes les questions.'
                return this.response
            }
            // au moins 2 choix
            if(this.question.choices.length < 2){
                this.response.errorMessage = 'Une des questions ne comporte pas assez de choix.'
                return this.response
            }
            // choix
            for(let choice of this.question.choices){
                if(!choice.text || choice.text.trim() == ''){
                    this.response.errorMessage = 'Une des questions comporte un choix vide.'
                    return this.response
                }
            }
            // validation
            this.response.valide = true
            return this.response
        }
    }
}
</script>