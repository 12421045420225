<template>
    <span v-if="profile.role != 'User'">
        <div>Correction :</div>
        <v-text-field v-model="question.correctAnswer" type="text" placeholder="ex : (Q1 + Q2) * 10" @keyup="autoValidation" clearable class="mt-0 pt-0" />
        <!--
            @keydown="question.correctAnswer = question.correctAnswer.toUpperCase(); question.correctAnswer = question.correctAnswer.match(regex).join('')"
            :rules="[v => !!v || 'Champ requis', v => regex.test(v) || 'Caractère non valide']"
        -->
        <div class="mb-3 d-flex" style="margin-top:-15px;transform:translateZ(1px)">
            <b v-text="'= ' + result"></b>
            <v-spacer></v-spacer>
            <v-btn text small @click="autoValidation">calculer</v-btn> <!-- ex : Q2 = Q1 + x, if Q1 is modified, Q2 isn't update -->
        </div>
    </span>

    <span v-else>
        <h4 v-text="question.text" class="mb-5"></h4>
        <v-text-field step="any" v-model="question.response" type="number" label="Réponse" clearable class="mt-0 pt-0" style="margin-bottom:-15px" @keyup="$emit('edited')" />
    </span>
</template>

<script>
export default {
    props: ["profile", "question", "index", "questions"],
    data: () => ({
        response: {
            valide: false,
            errorMessage: ''
        },
        regex: /[0-9Q+*/()\- ]/g,
        varList: [],
        result: ''
    }),
    created(){
        if(this.profile.role != 'User') this.autoValidation()
    },
    methods: {
        testIsValid(){
            // titre
            if(!this.question.text || this.question.text.trim() == ''){
                this.response.errorMessage = 'Veuillez renseigner un titre à toutes les questions.'
                return this.response
            }
            // correction
            if(!this.question.correctAnswer || this.question.correctAnswer.trim() == ''){
                this.response.errorMessage = 'Veuillez renseigner une correction à toutes les questions.'
                return this.response
            }
            this.question.correctAnswer = this.question.correctAnswer.toUpperCase()
            // test regex
            if(this.question.correctAnswer != this.question.correctAnswer.match(this.regex).join('')){
                this.response.errorMessage = 'Une réponse dépendante est invalide.'
                return this.response
            }
            // get variable
            this.getVarList()
            for(let questionIndex of this.varList){
                const questionI = +questionIndex
                // question n'est pas elle-même
                if(questionI-1 == this.index){
                    this.response.errorMessage = "Une réponse dépendante ne peut pas dépendre d'elle-même."
                    return this.response
                }
                // question existante
                if(questionI > this.questions.length){
                    this.response.errorMessage = "Une réponse dépendante ne peut pas dépendre d'une question inexistante."
                    return this.response
                }
                // question à réponse numérique)
                if(this.questions[questionI-1].correctAnswer != (+this.questions[questionI-1].correctAnswer) && !this.questions[questionI-1].type === 'dependency'){
                    this.response.errorMessage = "Une réponse dépendante ne peut dépendre que de question(s) à réponse numérique."
                    return this.response
                }
            }
            // Clacul valide
            try{
                this.result = eval(this.getResult())
            }catch{
                this.response.errorMessage = "Une réponse dépendante ne peut pas être calculée."
                return this.response
            }
            // validation
            this.response.valide = true
            return this.response
        },
        autoValidation(){
            if(!this.question.correctAnswer || this.question.correctAnswer.trim() == ''){
                this.result = ''
                return
            }
            this.question.correctAnswer = this.question.correctAnswer.toUpperCase()
            // test regex
            if(!this.question.correctAnswer.match(this.regex) || this.question.correctAnswer != this.question.correctAnswer.match(this.regex).join('')){
                this.result = "Un caractère n'est pas valide."
                return
            }
            // get variable
            this.getVarList()
            for(let questionIndex of this.varList){
                const questionI = +questionIndex
                // question n'est pas elle-même
                if(questionI-1 == this.index){
                    this.result = "Une réponse dépendante ne peut pas dépendre d'elle-même."
                    return
                }
                // question existante
                if(questionI > this.questions.length){
                    this.result = "Une variable n'existe pas."
                    return
                }
                // question à réponse numérique)
                if(this.questions[questionI-1].correctAnswer != (+this.questions[questionI-1].correctAnswer) && !this.questions[questionI-1].type === 'dependency'){
                    this.result = "Une variable n'est pas numérique."
                    return
                }
            }
            // Clacul valide
            try {
                this.result = Math.round(eval(this.getResult()) * 10000) / 10000
            } catch {
                this.result = 'Incalculable.'
            }
        },
        getVarList() {
            this.varList = []
            const charList = this.question.correctAnswer.split('')
            let isAQuestion = false
            let i = 0
            for(let char of charList){
                if(char == 'Q'){
                    let next = charList[i+1]
                    // 'Q' toujours suivi d'un nombre
                    if(!next || next != next.match(/[0-9]/g)){
                        this.result = "Une variable n'est pas valide."
                        return
                    }
                    isAQuestion = true
                }else if(isAQuestion == true && char == char.match(/[0-9]/g)){
                    if(charList[i-1] == 'Q') this.varList.push(char)
                    else this.varList[this.varList.length-1] += char
                }else{
                    isAQuestion = false
                }
                i++
            }
        },
        getResult() {
            // console.log(this.question.correctAnswer)
            this.result = this.loopGetResult(this.question.correctAnswer)
            // console.log('')
            return this.result
        },
        loopGetResult(result) {
            for (const questionIndex of Object.keys(this.questions).reverse()) { // can't use this.varList
                const varName = 'Q' + questionIndex
                if (result.includes(varName)) {
                    const question = this.questions[parseInt(questionIndex-1)]

                    if (question.type === 'dependency') {
                        result = result.replaceAll(varName, '(' + parseFloat(question.correctAnswer.replaceAll(',', '.') || 0) + ')')
                        // console.log(result)
                        return this.loopGetResult(result)
                    }
                    result = result.replaceAll(varName, parseFloat(question.correctAnswer.replaceAll(',', '.') || 0))
                    // console.log(result)
                    return this.loopGetResult(result)
                }
            }
            return result
        }
    }
}
</script>