<template>
  <div class="images-container" v-if="(profile && profile.role === 'User') || preview" :class="disabled || showCorrection ? 'pointer-e-none' : ''">
    <span>
      <h4 class="mt-2">{{ question.text }}</h4>
      <div v-if="formResponse.responses[slideIndex] === undefined" class="noResponse">Non répondu.</div>
    </span>
    <div class="items">
      <div class="item card" :class="disabled ? 'disabled' : (formResponse && formResponse.responses[slideIndex] !== index) || (preview && previewValue !== index) ? 'pointer' : ''"
        v-for="(choice, index) in question.choices.filter(x=>x!==null)" v-bind:key="index"
        @click="onChoiceClick(index)"
      >
        <img :src="choice.ref" :style="needFilter(index) ? 'filter: contrast(0.5)' : ''">

        <v-icon class="icon-check correction" color="info" v-if="showCorrection && !isCorrect && index === question.goodResponse">mdi-check</v-icon>
        <v-icon class="icon-check error--border" color="error" v-if="showCorrection && !isCorrect && formResponse.responses[slideIndex] === index">mdi-close</v-icon>

        <v-icon class="icon-check" color="success" v-else-if="formResponse && formResponse.responses[slideIndex] === index">mdi-check</v-icon>
        <v-icon class="icon-check" color="success" v-else-if="!formResponse && previewValue === index">mdi-check</v-icon>
      </div>
    </div>
  </div>

  <div class="images-container" v-else
    @mouseover="addToRightClick" @mouseleave="delToRightClick"
  >
    <span>
      <v-text-field hide-details class="input pa-0 ma-0" placeholder="Énoncé" v-model="question.text" />
      <div class="note">
        /<span><v-text-field dense type="number" hide-details class="ma-0" @blur="testNote" v-model="question.points" /></span>
      </div>
    </span>
    <div class="items">
      <div class="item card"
        v-for="(choice, index) in question.choices" v-bind:key="index"
        @mouseover="addItemToRightClick(index)" @mouseleave="delItemToRightClick"
        @click="onChoiceClick(index)"
      >
        <v-btn outlined style="height: 100%!important" v-if="choice === null">
          <v-icon large color="primary">mdi-upload-outline</v-icon>
        </v-btn>

        <img :src="choice.ref" v-else :class="question.goodResponse === index ? '' : 'pointer'">

        <v-icon class="icon-check" color="success" v-if="question.goodResponse === index">mdi-check</v-icon>
      </div>
    </div>

    <input type="file" :accept="acceptedExt" class="d-none" ref="fileInput" @change="addMedia">
  </div>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import Correction from '@/assets/functions/corrections'
import testNote from '@/assets/functions/corrections/testNote'
import { pictureExt } from '@/assets/config/ExtWhiteList'

export default {
  props: ['form', 'question', 'preview', 'rightClickItems', 'profile', 'formResponse', 'slideIndex', 'disabled', 'showCorrection'],
  data() {
    return {
      uploadIndex: null,
      previewValue: null,
      isCorrect: false,
      acceptedExt: ''
    }
  },
  watch: {
    showCorrection() {
      this.runCorrection()
    }
  },
  mounted() {
    this.acceptedExt = audioExt.map(x=>`.${x}`).join(', ')
    if (this.showCorrection) {
      this.runCorrection()
    }
  },
  methods: {
    addToRightClick() {
      if (this.rightClickItems) {
        if (this.question.choices.length < 8) {
          this.rightClickItems[3] = {
            text: 'Ajouter une réponse',
            method: () => {
              this.question.choices.push(null)
              if (this.question.choices.length >= 8) {
                this.rightClickItems[3] = null
              }
              this.$forceUpdate()
            }
          }
        }
      }
    },
    delToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[3] = null
      }
    },
    addItemToRightClick(i) {
      if (this.rightClickItems) {
        this.rightClickItems[10] = {
          text: 'Modifier la réponse',
          method: () => {
            this.uploadIndex = i
            this.$refs.fileInput.click()
          }
        }
        this.rightClickItems[11] = {
          text: 'Supprimé la réponse',
          method: () => {
            if (this.question.choices[i] !== null && this.question.choices[i].path !== undefined) {
              if (this.form.deletedFiles === undefined) this.form.deletedFiles = []
              this.form.deletedFiles.push(this.question.choices[i].path)
            }

            this.question.choices.splice(i,1)
            if (this.question.goodResponse === i) this.question.goodResponse = null
            this.$forceUpdate()
          }
        }
      }
    },
    delItemToRightClick() {
      if (this.rightClickItems) {
        this.rightClickItems[10] = null
        this.rightClickItems[11] = null
      }
    },
    addMedia(event) {
      // get file
      const file = event.target.files[0]
      if (!file) return

      // check ext
      const ext = file.name.toLowerCase().split('.').pop()
      if (!pictureExt.includes(ext)) {
        Swal.fire({
          title: 'Erreur',
          text: 'Type de fichier invalide.',
          icon: 'error',
          confirmButtonText: 'Ok',
        })
        return
      }

      // create object
      this.question.choices[this.uploadIndex] = {
        name: file.name,
        ref: URL.createObjectURL(file),
        file: file,
        isUpdated: true
      }
      this.uploadIndex = null
      this.$forceUpdate()
    },
    onChoiceClick(i) {
      if (!this.disabled) {
        if (this.profile && this.profile.role === 'User') {
          this.formResponse.responses[this.slideIndex] = i
          this.formResponse.lastUpdateDate = new Date().getTime()
        } else if (this.preview) {
            this.previewValue = i
        } else {
          if (this.question.choices[i] === null) {
            this.uploadIndex = i
            this.$refs.fileInput.click()
          } else {
            this.question.goodResponse = i
          }
        }
        this.$forceUpdate()
      }
    },

    runCorrection() {
      this.isCorrect = Correction('selectImage', this.formResponse.responses[this.slideIndex], this.question.goodResponse)
    },
    needFilter(index) {
      if (this.showCorrection) {
        return index !== this.question.goodResponse && index !== this.formResponse.responses[this.slideIndex]
      } else if (this.disabled) {
        return index !== this.formResponse.responses[this.slideIndex]
      }
      return false
    },

    testNote() {
      this.question.points = testNote(this.question.points)
    }
  }
}
</script>

<style scoped lang="scss">
.images-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  place-items: center;

  span:has(>.input) {
    width: 100%;
    margin-left: 32px;
    padding-right: 132px;
    display: grid;
    grid-template-columns: auto 100px;
    gap: 32px;
  }
  .input {
    flex: unset;
    width: 100%;
    padding: 0 48px;
  }
  .note {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    > span {
      margin: 0;
      padding-left: 6px;
      width: 60px;
    }
  }
}

.noResponse {
  color: var(--v-error-base);
}



.items {
  width: 100%;
  height: calc(100% - 48px);
  display: grid;
  padding: 0 64px;
  gap: 16px;
  overflow-y: auto;
  
  @for $i from 1 through 8 {
    &:has(:nth-child(#{$i})):not(:has(:nth-child(#{$i+1}))) {
      $rows: if($i < 4, 1, 2);
      grid-template-rows: repeat($rows, 1fr);
      @if $i == 1 or $i == 2 or $i == 3 {
        grid-template-columns: repeat($i, 1fr);
      } @else if $i == 4 or $i == 6 or $i == 8 {
        grid-template-columns: repeat($i / 2, 1fr);
      } @else if $i == 5 {
        grid-template-columns: repeat(6, 1fr);
        > :nth-child(1) { grid-column: 1 / 3; }
        > :nth-child(2) { grid-column: 3 / 5; }
        > :nth-child(3) { grid-column: 5 / 7; }
        > :nth-child(4) { grid-column: 1 / 4; }
        > :nth-child(5) { grid-column: 4 / 7; }
      } @else if $i == 7 {
        grid-template-columns: repeat(12, 1fr);
        > :nth-child(1) { grid-column: 1 / 4; }
        > :nth-child(2) { grid-column: 4 / 7; }
        > :nth-child(3) { grid-column: 7 / 10; }
        > :nth-child(4) { grid-column: 10 / 13; }
        > :nth-child(5) { grid-column: 1 / 5; }
        > :nth-child(6) { grid-column: 5 / 9; }
        > :nth-child(7) { grid-column: 9 / 13; }
      }
    }
  }
}
.item {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  overflow: hidden;

  &:not(.disabled) {
    &:hover, &:focus, &:has(:focus) {
      outline: solid 2px var(--v-info-base);
      outline-offset: -1px;
      > button {
        border: none;
      }
    }
  }

  > button {
    width: 100%;
  }

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.icon-check {
  position: absolute;
  bottom: 8px;
  right: 8px;
  border-radius: 50%;
  aspect-ratio: 1/1;
  padding: 4px;
  pointer-events: none;
  background-color: #0006;

  &:not(.correction) {
    border: solid 2px var(--v-success-base);
  }
  &.correction {
    border: solid 2px var(--v-info-base);
  }
  &.error--border {
    border: solid 2px var(--v-error-base);
  }
}

.pointer-e-none {
  pointer-events: none;
}
</style>