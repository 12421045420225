<template>
  <div v-if="showCorrection" :class="checkIfEmpty() ? 'error--text' : ''">
    {{ checkIfEmpty() ? 'Non répondu.' : formResponse.responses[slideIndex][questionIndex] }}
  </div>
  <v-textarea rows="1" auto-grow hide-details class="ma-0 pa-0" placeholder="Réponse" v-else-if="profile && profile.role === 'User'" v-model="formResponse.responses[slideIndex][questionIndex]" :disabled="disabled" @keydown="formResponse.lastUpdateDate = new Date().getTime()" />
  <v-textarea rows="1" auto-grow hide-details class="ma-0 pa-0" placeholder="Réponse" v-else-if="preview" />
</template>

<script>
export default {
  props: ['preview', 'profile', 'formResponse', 'slideIndex', 'questionIndex', 'disabled', 'showCorrection'],
  methods: {
    checkIfEmpty() {
      return [null, undefined].includes(this.formResponse.responses[this.slideIndex][this.questionIndex]) || this.formResponse.responses[this.slideIndex][this.questionIndex].trim() === ''
    }
  }
}
</script>