<template>
	<v-row class="my-3">
        <v-col cols="12" md="6" order="1" order-md="2">
            <h3 class="mb-5">Progression par utilisateur</h3>
            <v-select class="mb-5" :items="profiles" item-value="id" v-model="currentProfile" label="Utilisateur" v-if="new Array('Animator', 'Admin', 'SuperAdmin').includes(profile.role)">
                <template slot="item" slot-scope="data">
                    <UserCard :profile="data.item"></UserCard>
                </template>
                <template slot="selection" slot-scope="data">
                    <UserCard :profile="data.item"></UserCard>
                </template>
            </v-select>
            <vue-apex-charts type="donut" :options="CompletionChart.chartOptions" :series="tasksCompletionRate" />
        </v-col>
        <v-col cols="12" md="6" order="2" order-md="1">
            <h3 class="mb-5">Progression par mail</h3>
            <v-col cols="12" v-for="mail in mails" v-if="mail.responseType != 'none'" class="py-2">
                <v-card outlined class="pa-3 my-0">
                    <span class="d-flex flex-column">
                        <span class="d-flex align-center my-3">
                            <UserCard :profile="mail.sender" :subject="mail.subject"></UserCard>
                        </span>
                        <span class="d-flex align-center">
                            <v-progress-linear :value="mail.responses.length / mail.totalUserAmount * 100"></v-progress-linear>
                            <strong class="light--text pl-2" v-text="mail.responses.length / mail.totalUserAmount * 100 + '%' "></strong>
                            <strong class="light--text pl-2" v-text=" '('+ mail.responses.length + '/' + mail.totalUserAmount+')'"></strong>
                        </span>
                    </span>

                </v-card>
            </v-col>
            <h3 class="my-5">Progression par message vocal</h3>
            
            <v-col cols="12" v-for="message in phoneMessages" v-if="message.responseType != 'none'" class="py-2">
                <v-card outlined class="pa-3 my-0">
                    <span class="d-flex flex-column">
                        <span class="d-flex align-center my-3">
                            <UserCard :profile="message.sender" :subject="message.subject"></UserCard>
                        </span>
                        <span class="d-flex align-center">
                            <v-progress-linear :value="message.responses.length / message.totalUserAmount * 100"></v-progress-linear>
                            <strong class="light--text pl-2" v-text="message.responses.length / message.totalUserAmount * 100 + '%' "></strong>
                            <strong class="light--text pl-2" v-text=" '('+ message.responses.length + '/' + message.totalUserAmount+')'"></strong>
                        </span>
                    </span>

                </v-card>
            </v-col>
        </v-col>
		
	</v-row>
</template>

<style scoped>
    * >>> .v-list-item,* >>> .v-select__selections{
        height:5rem !important;
    }
</style>

<script>
import VueApexCharts from "vue-apexcharts"
import themeConfig from "@themeConfig"

const colors = themeConfig.themes.light

import Profile from "@/classes/Profile"
import Contact from "@/classes/Contact"
import Group from "@/classes/Group"

import Mail from "@/classes/Mail"
import MailResponse from "@/classes/MailResponse"
import Phone from "@/classes/Phone"
import PhoneResponse from "@/classes/PhoneResponse"

import UserCard from "@/components/UserCard.vue"

export default {
    name: "MailProgressionTab",
    props: ["user", "profile", "notifications", "config", "currentRoom"],
    components: {
        VueApexCharts,
        UserCard
    },
    data() {
        return {
            unsub: [],
            contactsTable : [],
            currentProfile: null,
            profiles : [],

            mails: [],
            phoneMessages : [],

            CompletionChart: {
                series: [0, 0,100],
                chartOptions: {
                    labels: ["Finis", "En attente", "A venir"],
                    colors: [colors.primary, colors.light, colors.info],
                    legend: {
                        show: false,
                    },
                    dataLabels: {
                        enabled: true,
                        formatter(val) {
                            return `${parseInt(val)}%`
                        },
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    show: true,
                                    name: {
                                        fontSize: "2rem",
                                    },
                                    value: {
                                        fontSize: "1rem",
                                        formatter(val) {
                                            return `${parseInt(val)}%`
                                        },
                                    },
                                    total: {
                                        show: false,
                                    },
                                },
                            },
                        },
                    },
                },
            },
        }
    },
    created() {
        // contactsTable
        this.unsub.push(Profile.listenByRoomAndByRole(this.profile.currentRoom, 'User', data => {
            for (const profile of data) {
                this.profiles.push(profile)
                this.contactsTable[profile.id] = profile
            }

            this.currentProfile = this.profiles[0].id
        }))
        this.unsub.push(Contact.listenByRoom(this.profile.currentRoom, data => {
            for (const contact of data)
                this.contactsTable[contact.id] = contact
        }))
        this.unsub.push(Group.listenByRoom(this.profile.currentRoom, data => {
            for (const group of data)
                this.contactsTable[group.id] = group
        }))

        // mail send by one contact
        Contact.getByRoom(this.profile.currentRoom).then(data => {
            for (const contact of data) {
                this.unsub.push(Mail.listenByRoomAndBySender(this.profile.currentRoom, contact.id, async data => {
                    const tmp_mails = []
                    for (const mail of data) {
                        if(mail.responseType != 'none') {
                            // sender
                            mail.sender = this.contactsTable[mail.sender]
                            // receiver
                            if (mail.receiver != 'all') mail.receiver = this.contactsTable[mail.receiver]
                            if (mail.receiver.uid) mail.totalUserAmount = 1
                            else if (mail.receiver.members) mail.totalUserAmount = mail.receiver.members.length
                            else mail.totalUserAmount = this.profiles.length
                            // responses
                            const mailResponses = await MailResponse.getByMail(mail.id)
                            for (const mailResponse of mailResponses) {
                                mailResponse.sender = this.contactsTable[mailResponse.sender]
                            }
                            mail.responses = mailResponses
                            tmp_mails.push(mail)
                        }
                    }
                    tmp_mails.sort((a,b)=>{
                        return b.date - a.date
                    })
                    this.mails = tmp_mails
                }))
            }
        })

        // phone
        this.unsub.push(Phone.listenByRoom(this.profile.currentRoom, async data => {
            const tmp_phones = []
            for (const phone of data) {
                if (phone.responseType != 'none') {
                    // sender
                    phone.sender = this.contactsTable[phone.sender]
                    // receiver
                    phone.totalUserAmount = this.profiles.length
                    // responses
                    const phoneResponses = await PhoneResponse.getByPhone(phone.id)
                    for (const phoneResponse of phoneResponses) {
                        phoneResponse.sender = this.contactsTable[phoneResponse.sender]
                    }
                    phone.responses = phoneResponses
                    tmp_phones.push(phone)
                }
            }
            tmp_phones.sort((a,b)=>{
                return b.date - a.date
            })
            this.phoneMessages = tmp_phones
        }))
    },
    computed: {
        tasksCompletionRate(){
            let totalTasks = 0
            let completedTasks = 0
            let invisibleTasks = 0

            for(let mail of this.mails){
                if(mail.responseType != "none"){
                    totalTasks++
                    if(new Date(mail.date) > new Date()){
                        invisibleTasks++
                    }else if(mail.responses.length > 0){
                        for(let response of mail.responses){
                            if(response.sender.id == this.currentProfile){
                                completedTasks++
                                break
                            }
                        }
                    } 
                }
            }
            for(let message of this.phoneMessages){
                if(message.responseType != "none"){
                    totalTasks++
                    if(new Date(message.date) > new Date()){
                        invisibleTasks++
                    }else if(message.responses.length > 0){
                        for(let response of message.responses){
                            if(response.sender.id == this.currentProfile){
                                completedTasks++
                                break
                            }
                        }
                    }
                }
            }
            
            let tmp_series = []
            
            if(totalTasks > 0){
                let completionRate = Math.round(completedTasks / totalTasks * 10000) / 100
                let invisibleRate = Math.round(invisibleTasks / totalTasks * 10000) / 100
                tmp_series = [completionRate, 100 - completionRate - invisibleRate, invisibleRate]
            }else{
                tmp_series = [0, 100, 0]
            }

            return tmp_series
        },
    },
    destroyed() {
        this.unsub.forEach(unsub => unsub())
    },
}
</script>
